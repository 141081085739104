import { useEffect, useState } from "react";
import CouponContent from "./CouponContent/CouponContent";
import { Coupon } from "../../../collector";
import Coupons from "./Coupons";
import BulkRangesView from "../BulkRangesView/BulkRangesView";

interface CouponsViewProps {
    environment: string;
}

export default function CouponsView(props: CouponsViewProps) {

    const [selectedCoupon, setSelectedCoupon] = useState<Coupon | null>(null);
    const [bodyElement, setBodyElement] = useState<JSX.Element>(<></>);

    useEffect(() => {
        createBodyElement();
    }, [JSON.stringify(selectedCoupon), selectedCoupon]);
    useEffect(() => {
        setSelectedCoupon(null);
        createBodyElement();
    }, [props.environment]);

    const createBodyElement = () => {
        let content: JSX.Element = <></>;
        if (selectedCoupon) {
            content = (
                <CouponContent
                    coupon={selectedCoupon}
                    environment={props.environment}
                    goBack={() => setSelectedCoupon(null)}
                />
            );
        }
        else {
            content = (
                <Coupons
                    environment={props.environment}
                    selectCoupon={(coupon) => setSelectedCoupon(coupon)}
                />
            );
        }
        setBodyElement(content);
    }

    return (
        <>
            {bodyElement}
        </>
    );
}

