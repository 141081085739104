import { useState, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { ErrorContext } from "../../../../context/ErrorContext";
import { AuthContext } from "../../../../context/AuthContext";
import { getAPIroot } from "../../../../collector";


interface NewAdmin {
    username: string;
    password: string;
    passwordConf: string;
    name: string;
}

interface CreateAdminProps {
    createdAdmin: () => void;
}



export default function CreateAdminModal(props: CreateAdminProps) {
    const [show, setShow] = useState<boolean>(false);
    const [username, setUsername] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [passwordConfirm, setPasswordConfirm] = useState<string>('');
    const [passwordsMatch, setPasswordsMatch] = useState<boolean>(true);
    const { showErrorModal } = useContext(ErrorContext);
    const { token } = useContext(AuthContext);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setUsername('');
        setName('');
        setPassword('');
        setPasswordConfirm('');
        setPasswordsMatch(true);
        setShow(true);
    };

    const createAdmin = async () => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer' + token(),
                },
                body: JSON.stringify({
                    'username': username,
                    'password': password,
                    'name': name,
                    'createdDate': Date.now(),
                    'lastLogin': Date.now()
                })
            }
            await fetch(getAPIroot() + 'create_admin', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw Error('Failed to create Admin');
                    }
                    return res.json();
                })
                .then(() => {
                    props.createdAdmin()
                    handleClose();
                });
        } catch (e: any) {
            showErrorModal(e.message ?? "Failed to create admin user - Server is unavailable at the moment");
        }
    }

    return (
        <>
            <Button variant='outline-dark' onClick={() => handleShow()}>
                Create Admin
            </Button>
            {show &&
                <Modal
                    show={show}
                    onHide={() => handleClose()}
                    backdrop="static"
                    keyboard={false}
                >
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        if (password === passwordConfirm) {
                            createAdmin();
                        }
                        else {
                            setPassword('');
                            setPasswordConfirm('');
                            setPasswordsMatch(false);
                        }
                    }}>
                        <Modal.Header closeButton>
                            <Modal.Title>Create Admin</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="dialog-data-field">
                                <span>Username:</span>
                                <input type="text" placeholder="Write username here..." value={username} required autoComplete='off'
                                    onChange={(item) => setUsername(item.target.value)} />
                            </div>
                            <div className="dialog-data-field">
                                <span>Name:</span>
                                <input type="text" placeholder="Write name here..." value={name} required autoComplete='off' onChange={(item) => setName(item.target.value)} />
                            </div>
                            <div className="dialog-data-field">
                                <span>Password:</span>
                                <input type="password" placeholder="Write password here..." value={password} required autoComplete='new-password' onChange={(item) => setPassword(item.target.value)} />
                            </div>
                            <div className="dialog-data-field">
                                <span>Confirm Password:</span>
                                <input type="password" placeholder="Write password here..." value={passwordConfirm} required autoComplete='off' onChange={(item) => setPasswordConfirm(item.target.value)} />
                            </div>
                            {!passwordsMatch &&
                                <div className="dialog-data-field">
                                    <span className="error-txt">*Passwords are not matching, try again...</span>
                                </div>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => handleClose()}>
                                Close
                            </Button>
                            <Button variant="primary" type='submit'>Create Admin</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            }
        </>
    );
}
