import { useContext, useEffect, useState } from 'react';
import './LicensePage.css';
import { CopyIcon, DeleteIcon, DownloadIcon, EditIcon } from '@fluentui/react-icons-mdl2';
import { Button, Container, Form, Table } from 'react-bootstrap';
import PaginationPartial from '../../components/pagination/pagination';
import { LicenseEntry, LicenseType, ResponseStatus, getAPIroot, getEdition } from '../../collector';
import ActionBar from '../../components/ActionBar/ActionBar';
import OpenLicense from './OpenLicense/OpenLicense';
import EditLicenseModal from './modals/EditLicense/EditLicense';
import { AuthContext } from '../../context/AuthContext';
import { ErrorContext } from '../../context/ErrorContext';
import { ConfirmModal } from '../../modals/ConfirmModal';
import CreateLicenseModal from './modals/CreateLicense/CreateLicense';
import Switch from '../../components/Switch/Switch';
import DateRange from '../../components/DateRange';
import LoadingPlaceholder from '../../components/LoadingPlaceholder';
import useDebounce from '../../hooks/useDebounce';

interface Sorting {
	id: number;
	status: SortStatus;
	name: string;
}
enum SortStatus {
	Not = 0,
	Asc = 1,
	Desc = -1
}
export default function LicensePage() {
	const [licenses, setLicenses] = useState<LicenseEntry[]>([]);
	const [licenseElements, setLicenseElements] = useState<JSX.Element[]>([]);
	const [selectedLicense, setSelectedLicense] = useState<LicenseEntry | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [totalLicenses, setTotalLicenses] = useState<number>(0); //The total number of licenses matching the filters
	const [editLicenseId, setEditLicenseId] = useState<string | null>(null); //Ref to the license we are editing (null if none)
	const [deleteLicenseId, setDeleteLicenseId] = useState<string | null>(null); //Ref to the license we want to delete (null if none)

	// Pagination values
	const [currentPage, setCurrentPage] = useState<number>(0);
	const [maxPages, setMaxPages] = useState<number>(1);
	const [elementsPerPage, setElementsPerPage] = useState<number>(20);

	// Filter values
	const [dateInterval, setDateInterval] = useState<{ startDate: Date | null, endDate: Date | null }>({ startDate: null, endDate: null });
	const [showExpired, setShowExpired] = useState<boolean>(false);
	const [licenseTypeFilterValue, setLicenseTypeFilterValue] = useState<LicenseType>(0);
	const [searchCriteria, setSearchCriteria] = useState<string>('name');
	const [searchValue, setSearchValue] = useState<string>('');
	const debouncedSearchValue = useDebounce(searchValue); // Used to minimize requests when typing the search value

	const { showErrorModal } = useContext(ErrorContext);
	const { token, logout } = useContext(AuthContext)

	// Sorting controller - name must match the database field attempting to sort on
	const [sortController, setSortController] = useState<Sorting[]>([
		{ id: 0, status: SortStatus.Desc, name: 'issue_date' }, //Issue Date
		{ id: 1, status: SortStatus.Not, name: 'licensed_to' }, //UserID
		{ id: 2, status: SortStatus.Not, name: 'name' }, //Name
		{ id: 3, status: SortStatus.Not, name: 'affiliation' }, //Affiliation
		{ id: 4, status: SortStatus.Not, name: 'license_type' }, //License Type
		{ id: 5, status: SortStatus.Not, name: 'expire_date' }, //Expiry Date
		{ id: 6, status: SortStatus.Not, name: 'persistent_license' }, //Persistent
		{ id: 7, status: SortStatus.Not, name: 'device_limit' }, //Device Limit
		{ id: 8, status: SortStatus.Not, name: 'devices' }, //Devices Used
	]);

	useEffect(() => {
		fetchLicenses();
	}, [currentPage, elementsPerPage, JSON.stringify(sortController), debouncedSearchValue, showExpired, licenseTypeFilterValue, JSON.stringify(dateInterval)])
	useEffect(() => {
		createLicenseRowElements();
	}, [JSON.stringify(licenses)])
	useEffect(() => {
		if (maxPages >= currentPage) setCurrentPage(0);
	}, [maxPages, elementsPerPage])

	const createLicenseRowElements = () => {
		if (licenses.length === 0) {
			setLicenseElements([]);
			return;
		}
		let elements = licenses.map((item, idx) => {
			return (
				<tr key={idx} className={'pointer'} onClick={() => setSelectedLicense(item)}>
					<td>{new Date(item.issue_date).toLocaleString()}</td>
					<td>{item.licensed_to}</td>
					<td>{item.name}</td>
					<td>{item.affiliation}</td>
					<td>{getEdition(item.license_type)}</td>
					<td>{item.devices.length} of {item.device_limit}</td>
					<td onClick={(e) => e.stopPropagation()}>
						<div style={{ display: 'flex' }}>
							<Button variant='outline-dark' size='sm' style={{ marginRight: '3px' }} onClick={() => navigator.clipboard.writeText(item.key)}>
								<CopyIcon />
							</Button>
							<Button variant='outline-dark' size='sm' onClick={() => downloadKey(item.licensed_to)}>
								<DownloadIcon />
							</Button>
						</div>
					</td>
					<td>{new Date(item.expire_date).toLocaleString()}</td>
					<td>{item.persistent_license ? 'Yes' : 'No'}</td>
					<td onClick={(e) => e.stopPropagation()}>
						<Button variant='outline-dark' size='sm' style={{ marginRight: '3px' }} onClick={() => { setEditLicenseId(item.licensed_to) }}>
							<EditIcon />
						</Button>
						<Button variant="danger" size="sm" onClick={(e) => setDeleteLicenseId(item.licensed_to)}>
							<DeleteIcon />
						</Button>
					</td>
				</tr>
			)
		}
		);
		setLicenseElements(elements);
	}

	const fetchLicenses = async () => {
		setIsLoading(true);
		try {
			const requestOptions = {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer' + token()
				},
				body: JSON.stringify({
					'currentPage': currentPage,
					'perPage': elementsPerPage,
					'sortController': sortController,
					'searchValue': debouncedSearchValue,
					'searchField': searchCriteria,
					'showExpired': showExpired,
					'licenseType': licenseTypeFilterValue,
					'dateInterval': dateInterval
				})
			}
			await fetch(getAPIroot() + 'get_all_licenses', requestOptions)
				.then((res) => {
					if (!res.ok) {
						throw Error("Could not fetch licenses");
					}
					return res.json();
				})
				.then(({ licenses, status, total }: { status: ResponseStatus, licenses: LicenseEntry[], total: number }) => {
					if (status === ResponseStatus.INVALID_AUTH_TOKEN) logout(true);
					setLicenses([...licenses]);
					setTotalLicenses(total);
					setMaxPages(Math.ceil(total / elementsPerPage));
					setIsLoading(false);
				});
		} catch (e: any) {
			setLicenses([]);
			setMaxPages(0);
			setTotalLicenses(0);
			setIsLoading(false);
			showErrorModal(e.message ?? "Could not fetch licenses - Server is unavailable");
		}
	};

	const deleteLicense = async (id: string | null) => {
		try {
			if (id !== null) {
				const requestOptions = {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'Authorization': 'Bearer' + token()
					},
					body: JSON.stringify({
						'userID': id,
					})
				}
				await fetch(getAPIroot() + 'delete_license', requestOptions)
					.then((res) => {
						if (!res.ok) {
							throw Error("Failed to delete license");
						}
						return res.json();
					})
					.then(({ status }) => {
						if (status === ResponseStatus.INVALID_AUTH_TOKEN) logout(true);
						if (id === selectedLicense?.licensed_to) {
							setSelectedLicense(null);
						}
						fetchLicenses();
					});
			}
		} catch (e: any) {
			showErrorModal(e.message ?? "Failed to delete license - Server is unavailable");
		}
	};

	const downloadKey = async (userID: string) => {
		if (isLoading) return
		try {
			const requestOptions = {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer' + token()
				},
				body: JSON.stringify({
					'userID': userID,
				})
			}
			await fetch(getAPIroot() + 'get_license_key', requestOptions)
				.then((res) => {
					if (!res.ok) {
						throw Error("Could not fetch the license key");
					}
					return res.json();
				})
				.then((data: { status: number, key: string }) => {
					if (data.status === ResponseStatus.INVALID_AUTH_TOKEN) logout(true);
					if (data.status !== 0) throw Error("Could not fetch the license key - Status: " + data.status);

					const url = window.URL.createObjectURL(new Blob([data.key]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', `license.doteLicense`);
					document.body.appendChild(link);
					link.click();
					link.parentNode!.removeChild(link);
				});
		} catch (e: any) {
			showErrorModal(e.message ?? "Could not fetch the license key - Server is unavailable");
		}
	}

	const getNewSortStatus = (status: number) => {
		switch (status) {
			case SortStatus.Not:
				return SortStatus.Desc;
			case SortStatus.Desc:
				return SortStatus.Asc;
			case SortStatus.Asc:
				return SortStatus.Not
			default:
				return SortStatus.Not;
		}
	}
	const handleSort = (id: number) => {
		let sortArr: Sorting[] = [...sortController];
		let sortIndex = sortArr.findIndex((val) => val.id === id);

		if (sortIndex !== -1) {
			// Update new sorting
			sortArr[sortIndex].status = getNewSortStatus(sortArr[sortIndex].status)
			// Reset other sorting
			sortArr = sortArr.map((val) => {
				return val.id === sortIndex ? val : { ...val, status: SortStatus.Not };
			})
		}

		setSortController([...sortArr]);
	}
	const sortArrow = (id: number) => {
		if (sortController[id].status === SortStatus.Asc) return '↑';
		else if (sortController[id].status === SortStatus.Desc) return '↓';
		else return '';
	}

	return (
		<Container>
			<ActionBar
				reloadCallback={() => fetchLicenses()}
				title='Licenses'
				isLoading={isLoading}
			>
				<CreateLicenseModal createdLicense={() => fetchLicenses()} />
				<DateRange dateRangeHasBeenSet={([startDate, endDate]) => setDateInterval({ startDate, endDate })} />
				<div className='d-flex flex-nowrap'>
					<input type="text" value={searchValue} placeholder={"Search for " + searchCriteria} onChange={(e) => setSearchValue(e.currentTarget.value)} />
					<Form.Select size="sm" style={{ marginLeft: '5px', minWidth: '150px' }} value={searchCriteria} onChange={(e) => {
						setSearchCriteria(e.target.value);
						setSearchValue('');
					}}>
						<option value={'licensed_to'}>UserID</option>
						<option value={'name'}>Name</option>
						<option value={'affiliation'}>Affiliation</option>
					</Form.Select>
				</div>
				<Form.Select size="sm" style={{ width: '130px' }} value={licenseTypeFilterValue} onChange={(e) => setLicenseTypeFilterValue(Number(e.target.value))}>
					<option value={0}>All license types</option>
					<option value={LicenseType.AAU}>{getEdition(LicenseType.AAU)}</option>
					<option value={LicenseType.Pro}>{getEdition(LicenseType.Pro)}</option>
					<option value={LicenseType.ProCommunity}>{getEdition(LicenseType.ProCommunity)}</option>
                    <option value={LicenseType.SiteLicense}>{getEdition(LicenseType.SiteLicense)}</option>
				</Form.Select>
				<div style={{ display: 'flex', minWidth: '130px', alignItems: 'center' }}>
					<label htmlFor='showExpireSwitch' style={{ marginRight: '5px' }}>Show expired</label>
					<Switch id='showExpireSwitch' onChange={(e) => setShowExpired(e.target.checked)} checked={showExpired} />
				</div>
			</ActionBar>
			<OpenLicense
				selectedLicense={selectedLicense}
				downloadKey={(userID: string) => downloadKey(userID)}
				closeOpenLicense={() => setSelectedLicense(null)}
			/>
			<Table striped bordered hover>
				<thead>
					<tr>
						<th style={{ minWidth: '75px' }} onClick={() => { handleSort(0) }}>{sortArrow(0)}Issue Date:</th>
						<th onClick={() => { handleSort(1) }}>{sortArrow(1)}UserID:</th>
						<th onClick={() => { handleSort(2) }}>{sortArrow(2)}Name:</th>
						<th onClick={() => { handleSort(3) }}>{sortArrow(3)}Affiliation:</th>
						<th onClick={() => { handleSort(4) }}>{sortArrow(4)}License Type:</th>
						<th onClick={() => { handleSort(8) }}>{sortArrow(8)}Devices:</th>
						<th>Key:</th>
						<th onClick={() => { handleSort(5) }}>{sortArrow(5)}Expiry Date:</th>
						<th onClick={() => { handleSort(6) }}>{sortArrow(6)}Persistent:</th>
						<th style={{ minWidth: '85px' }}>Count: {totalLicenses}</th>
					</tr>
				</thead>
				<tbody>
					{!isLoading && licenseElements}
				</tbody>
			</Table>
			{isLoading && <LoadingPlaceholder />}
			<PaginationPartial
				currentPage={currentPage}
				maxPages={maxPages}
				viewAmount={elementsPerPage}
				changePage={(newPage: number) => setCurrentPage(newPage)}
				changeViewAmount={(newAmount: number) => setElementsPerPage(newAmount)}
				viewAmountPossible={[10, 20, 50, 100]}
			/>
			{editLicenseId &&
				<EditLicenseModal
					licenseId={editLicenseId}
					editedLicense={() => {
						setSelectedLicense(null);
						fetchLicenses();
					}}
					close={() => setEditLicenseId(null)}
				/>
			}
			{deleteLicenseId &&
				<ConfirmModal
					confirm={() => {
						deleteLicense(deleteLicenseId);
						setDeleteLicenseId(null);
					}}
					message={['Are you sure you want to delete this license?', 'This action can not be undone']}
					show={deleteLicenseId !== null}
					cancel={() => setDeleteLicenseId(null)}
				/>
			}
		</Container>
	)
}