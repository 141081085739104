import { ChevronUpIcon, ChevronDownIcon, EditIcon, DeleteIcon } from "@fluentui/react-icons-mdl2";
import { useState, useContext, useEffect } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { CouponRate, CouponRateType } from "../../../../collector";
import PaginationPartial from "../../../../components/pagination/pagination";
import { AuthContext } from "../../../../context/AuthContext";
import { ErrorContext } from "../../../../context/ErrorContext";
import EditCouponRateModal from "../../modals/EditCouponRate";
import { ConfirmModal } from "../../../../modals/ConfirmModal";
import LoadingPlaceholder from "../../../../components/LoadingPlaceholder";
import EnabledLabel from "../../../../components/EnabledLabel";


interface CouponRatesProps {
    couponId: number;
    environment: string;
    rates: CouponRate[];
    fetchRates: () => void;
    isLoading: boolean;
}

export default function CouponRates(props: CouponRatesProps) {

    const [rateElements, setRateElements] = useState<JSX.Element[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [maxPages, setMaxPages] = useState<number>(0);
    const [ratesPerPage, setRatesPerPage] = useState<number>(5);
    const [editingCouponRateId, setEditingCouponRateId] = useState<number | null>(null);
    const [deleteCouponRateId, setDeleteCouponRateId] = useState<number | null>(null);
    const { token } = useContext(AuthContext);
    const { showErrorModal } = useContext(ErrorContext);

    useEffect(() => {
        setMaxPages(Math.ceil(props.rates.length / ratesPerPage));
        createRateElements();
    }, [JSON.stringify(props.rates), currentPage, ratesPerPage]);

    const getRateTypeString = (type: CouponRateType): string => {
        switch (type) {
            case CouponRateType.Fixed:
                return 'DKK';
            case CouponRateType.Percentage:
                return '%';
            default:
                return 'DKK';
        }
    }
    const deleteRate = async (id: number) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    'id': id
                })
            }
            await fetch(props.environment + 'deleteRate', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Failed to delete rate from server");
                    }
                    return res.json();
                })
                .then(() => {
                    props.fetchRates();
                });
        } catch (e: any) {
            showErrorModal(e.message ?? "Failed to delete rate - Server is unavailable at the moment");
        }
    }

    const moveRate = async (rateId: number, direction: string) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    'id': rateId,
                    'direction': direction
                })
            }
            await fetch(props.environment + 'moveRate', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Failed to move rate on the server");
                    }
                    return res.json();
                })
                .then(() => {
                    props.fetchRates();
                });
        } catch (e: any) {
            showErrorModal(e.message ?? "Failed to move rate - Server is unavailable at the moment");
        }
    }

    const createRateElements = () => {
        let elements: JSX.Element[] = [];
        if (props.rates.length > 0) {
            elements = props.rates.sort((a, b) => { return a.order - b.order })
                .slice(currentPage * ratesPerPage, (currentPage + 1) * ratesPerPage)
                .map((currentRate, index) => {
                    return (
                        <tr key={index}>
                            <td>{currentRate.invoice_line}</td>
                            <td>{currentRate.value + " " + getRateTypeString(currentRate.type)}</td>
                            <td>{(currentRate.usedAmount ?? 0) + ' of ' + (currentRate.max_uses > 0 ? currentRate.max_uses : 'unlimited')}</td>
                            <td><EnabledLabel enabled={currentRate.enabled} /></td>
                            <td style={{ display: 'flex' }}>
                                <Button variant='outline-dark' style={{ marginRight: 'auto' }} size='sm' onClick={() => { moveRate(currentRate.id, 'up') }}><ChevronUpIcon /></Button>
                                {currentRate.order}
                                <Button variant='outline-dark' style={{ marginLeft: 'auto' }} size='sm' onClick={() => { moveRate(currentRate.id, 'down') }}><ChevronDownIcon /></Button>
                            </td>
                            <td>
                                <Button variant='outline-dark' size='sm' style={{ marginRight: '3px' }} onClick={() => setEditingCouponRateId(currentRate.id)}>
                                    <EditIcon />
                                </Button>
                                <Button variant="danger" size="sm" onClick={(e) => setDeleteCouponRateId(currentRate.id)}>
                                    <DeleteIcon />
                                </Button>
                            </td>
                        </tr>
                    )
                });
        }
        setRateElements(elements);
    }

    return (
        <>
            <EditCouponRateModal
                editedCouponRate={() => props.fetchRates()}
                environment={props.environment}
                couponRateId={editingCouponRateId}
                close={() => setEditingCouponRateId(null)}
            />
            {deleteCouponRateId &&
                <ConfirmModal
                    confirm={() => {
                        deleteRate(deleteCouponRateId);
                        setDeleteCouponRateId(null);
                    }}
                    message={['Are you sure you want to delete this rate?', 'This action can not be undone']}
                    show={deleteCouponRateId !== null}
                    cancel={() => setDeleteCouponRateId(null)}
                />
            }
            <h3 >
                Rates:
            </h3>
            <Table striped bordered hover size="md">
                <thead>
                    <tr>
                        <th>Invoice line:</th>
                        <th>Value:</th>
                        <th>Used:</th>
                        <th>Enabled:</th>
                        <th>Order:</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {!props.isLoading && rateElements}
                </tbody>
            </Table>
            {props.isLoading && <LoadingPlaceholder />}
            <span>Note: If two or more rates are available at the same time, the rate with the lowest order will be applied</span>
            {props.rates.length > ratesPerPage &&
                <PaginationPartial
                    currentPage={currentPage}
                    maxPages={maxPages}
                    viewAmount={ratesPerPage}
                    changePage={(newPage: number) => setCurrentPage(newPage)}
                    changeViewAmount={(newAmount: number) => {
                        setRatesPerPage(newAmount);
                        setMaxPages(Math.ceil(props.rates.length / newAmount));
                        setCurrentPage(0);
                    }}
                    viewAmountPossible={[5, 10, 25, 50, 100]}
                />
            }
        </>
    )
}