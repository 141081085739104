import { useState, useEffect, useContext } from "react";
import { Button, Container, Table } from "react-bootstrap";
import PaginationPartial from "../../components/pagination/pagination";
import { CopyIcon, DeleteIcon, EditIcon, HideIcon, RedEyeIcon } from "@fluentui/react-icons-mdl2";
import { Env, getWebshopFetchPath, isLocal } from "../../collector";
import { ErrorContext } from "../../context/ErrorContext";
import { AuthContext } from "../../context/AuthContext";
import EditBannerModal from "./modals/EditBanner";
import { CreateBannerModal } from "./modals/CreateBanner";
import { ConfirmModal } from "../../modals/ConfirmModal";
import ActionBar from "../../components/ActionBar/ActionBar";
import LoadingPlaceholder from "../../components/LoadingPlaceholder";
import EnabledLabel from "../../components/EnabledLabel";
import EnvironmentTabs from "../../components/EnvironmentTabs";

export interface Banner {
    id: number;
    enabled: boolean;
    description: string;
    heading: string | null;
    body: string[];
    btns: { link: string, text: string, color: string, fontColor: string }[];
    color: string;
    fontColor: string;
}

export default function BannerPage() {
    const [banners, setBanners] = useState<Banner[]>([]);
    const [testBanner, setTestBanner] = useState<Banner | null>(null);
    const [bannerElements, setBannerElements] = useState<JSX.Element[]>([]);
    const [testBannerElement, setTestBannerElement] = useState<JSX.Element>(<></>);

    const [elementsPerPage, setElementsPerPage] = useState<number>(20);
    const [maxPages, setMaxPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [editingBannerId, setEditingBannerId] = useState<number | null>(null);
    const [deleteBannerId, setDeleteBannerId] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [selectedEnvTab, setSelectedEnvTab] = useState<Env>(isLocal() ? Env.LOCAL : Env.TEST);

    const [copyBanner, setCopyBanner] = useState<{ banner: Banner, env: Env } | null>(null);
    const [totalElements, setTotalElements] = useState<number>(0);

    const { showErrorModal } = useContext(ErrorContext);
    const { token } = useContext(AuthContext);

    useEffect(() => {
        fetchBanners();
        setTestBanner(null);
    }, [selectedEnvTab, currentPage, elementsPerPage]);

    useEffect(() => {
        setMaxPages(Math.ceil(totalElements / elementsPerPage));
        createBannerElements();
    }, [banners]);

    useEffect(() => {
        if (currentPage > (maxPages - 1)) setCurrentPage(0);
    }, [maxPages]);

    useEffect(() => {
        createTestBannerElement();
        createBannerElements();
    }, [testBanner]);

    const createBannerElements = () => {
        let elements: JSX.Element[] = [];
        elements = banners.map((banner, index) => {
            let eye = (testBanner && testBanner.id === banner.id);
            return (
                <tr key={index}>
                    <td>{banner.description}</td>
                    <td><EnabledLabel enabled={banner.enabled} /></td>
                    <td>
                        <Button variant='outline-dark' size='sm' style={{ marginRight: '3px' }} onClick={(e) => setTestBanner(eye ? null : banner)}>
                            {eye ? <HideIcon /> : <RedEyeIcon />}
                        </Button>
                        <Button variant='outline-dark' size='sm' style={{ marginRight: '3px' }} onClick={(e) => setCopyBanner({ banner: { ...banner, enabled: false }, env: selectedEnvTab === Env.TEST ? Env.PRODUCTION : Env.TEST })}>
                            <CopyIcon />Copy to {selectedEnvTab === Env.TEST ? Env.PRODUCTION : Env.TEST}
                        </Button>
                        {isLocal() && selectedEnvTab !== Env.LOCAL &&
                            <Button variant='outline-dark' size='sm' style={{ marginRight: '3px' }} onClick={() => setCopyBanner({ banner: { ...banner, enabled: false }, env: Env.LOCAL })}>
                                <CopyIcon />Copy to {Env.LOCAL}
                            </Button>
                        }
                        <Button variant='outline-dark' size='sm' style={{ marginRight: '3px' }} onClick={() => setEditingBannerId(banner.id)}>
                            <EditIcon />
                        </Button>
                        <Button variant="danger" size="sm" onClick={(e) => setDeleteBannerId(banner.id)}>
                            <DeleteIcon />
                        </Button>
                    </td>
                </tr>
            )
        })
        setBannerElements(elements);
    }

    const createTestBannerElement = () => {
        let element = <></>;
        if (testBanner !== null) {
            element = (
                <div className="banner-announcement font-weight-bold text-center" style={{ backgroundColor: testBanner.color, color: testBanner.fontColor }}>
                    <>{testBanner.heading &&
                        <p style={{ margin: '0', padding: '0' }}><u><b>{testBanner.heading.toUpperCase()}</b></u></p>
                    }</>
                    <>{testBanner.body.map((line, index) => {
                        return (<p key={index} style={{ margin: '0', padding: '0' }}>{line}</p>)
                    })}</>
                    <>
                        {testBanner.btns.length > 0 &&
                            <p style={{ margin: '0', padding: '0' }}>
                                {testBanner.btns.map((btn, index) => {
                                    return (
                                        <button key={index} type="button" className="btn banner-button"
                                            style={{ margin: '5px', color: btn.fontColor, backgroundColor: btn.color }} onClick={() => window.open(btn.link)}
                                        >
                                            {btn.text}
                                        </button>
                                    )
                                })}
                            </p>
                        }
                    </>
                </div>
            )
        }
        setTestBannerElement(element);
    }

    const fetchBanners = async () => {
        try {
            setIsLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    'perPage': elementsPerPage,
                    'currentPage': currentPage + 1
                })
            }
            await fetch(getWebshopFetchPath(selectedEnvTab) + 'getBanners', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        showErrorModal("Failed to fetch banners from " + selectedEnvTab);
                        return;
                    }
                    return res.json();
                })
                .then(({ banners }: { banners: { data: Banner[], total: number } }) => {
                    let temp = banners?.data.map((banner) => {
                        return {
                            id: banner.id,
                            enabled: banner.enabled,
                            description: banner.description,
                            heading: banner.heading,
                            body: banner.body,
                            btns: banner.btns,
                            color: banner.color,
                            fontColor: banner.fontColor
                        }
                    });
                    setBanners([...temp]);
                    setTotalElements(banners.total);
                    setIsLoading(false);
                });
        } catch (e: any) {
            showErrorModal(e.message ?? "Failed to fetch banner from " + selectedEnvTab + " - Server is unavailable at the moment");
            setBanners([]);
            setTotalElements(1)
            setIsLoading(false);
        }
    }

    const deleteBanner = async (bannerId: number) => {
        try {
            setIsLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    'id': bannerId
                })
            }
            await fetch(getWebshopFetchPath(selectedEnvTab) + 'deleteBanner', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        showErrorModal("Failed to delete banner from " + getWebshopFetchPath(selectedEnvTab));
                        return;
                    }
                    return res.json();
                }).then(() => {
                    setDeleteBannerId(null);
                    setIsLoading(false);
                    fetchBanners();
                });
        } catch (e: any) {
            showErrorModal("Failed to delete banner from " + getWebshopFetchPath(selectedEnvTab) + " - Server is unavailable at the moment");
            setIsLoading(false);
        }
    }

    const createBanner = async (banner: Banner, environment: Env) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    'description': banner.description,
                    'heading': banner.heading,
                    'color': banner.color,
                    'fontColor': banner.fontColor,
                    'body': banner.body,
                    'btns': banner.btns,
                    'enabled': banner.enabled
                })
            }
            await fetch(getWebshopFetchPath(environment) + 'createBanner', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Failed to create banner at: " + environment);
                    }
                    return res.json();
                }).then(() => {
                    fetchBanners();
                })
        } catch (e: any) {
            showErrorModal("Failed to create banner at: " + environment + " - Server is unavailable at the moment");
        }
    }

    return (
        <>
            {deleteBannerId &&
                <ConfirmModal
                    confirm={() => deleteBanner(deleteBannerId)}
                    message={['Are you sure you want to delete this rate?', 'This action can not be undone']}
                    show={deleteBannerId !== null}
                    cancel={() => setDeleteBannerId(null)}
                />
            }
            {copyBanner &&
                <ConfirmModal
                    confirm={() => {
                        createBanner(copyBanner.banner, copyBanner.env);
                        setCopyBanner(null);
                    }}
                    message={['Are you sure you want to copy this banner to ' + copyBanner.env + '?', 'Note: The banner will be disabled on the target location to avoid mistakes.']}
                    show={copyBanner !== null}
                    cancel={() => setCopyBanner(null)}
                />
            }
            <EditBannerModal
                updatedBanner={() => {
                    if (editingBannerId === testBanner?.id) {
                        setTestBanner(null);
                    }
                    setEditingBannerId(null);
                    fetchBanners();
                }}
                bannerId={editingBannerId}
                close={() => setEditingBannerId(null)}
                environment={selectedEnvTab}
            />
            {testBannerElement}
            <Container>
                <EnvironmentTabs
                    selectedTab={selectedEnvTab}
                    changedTab={(newTab: Env) => setSelectedEnvTab(newTab)}
                />
                <ActionBar
                    isLoading={isLoading}
                    reloadCallback={() => fetchBanners()}
                    title="Webshop Banners"
                >
                    <CreateBannerModal
                        createBanner={(banner) => createBanner(banner, selectedEnvTab)}
                        environment={selectedEnvTab}
                    />
                </ActionBar>
                <Table striped bordered hover size="md">
                    <thead>
                        <tr>
                            <th>Description:</th>
                            <th>Enabled:</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading && bannerElements}
                    </tbody>
                </Table>
                {isLoading && <LoadingPlaceholder />}
                <PaginationPartial
                    currentPage={currentPage}
                    maxPages={maxPages}
                    viewAmount={elementsPerPage}
                    changePage={(newPage: number) => setCurrentPage(newPage)}
                    changeViewAmount={(newAmount: number) => {
                        setElementsPerPage(newAmount);
                        setMaxPages(Math.ceil(totalElements / newAmount));
                        setCurrentPage(0);
                    }}
                    viewAmountPossible={[10, 20, 50, 100]}
                />
            </Container>
        </>
    );
}