import { useState, useContext, useEffect } from "react";
import { ErrorContext } from "../../../context/ErrorContext";
import { AuthContext } from "../../../context/AuthContext";
import { Banner } from "../Banners";
import { Button, Modal } from "react-bootstrap";
import { Env, getWebshopFetchPath } from "../../../collector";
import Switch from "../../../components/Switch/Switch";
import EnabledLabel from "../../../components/EnabledLabel";


interface EditBannerProps {
    environment: Env;
    updatedBanner: () => void;
    bannerId: number | null;
    close: () => void;
}

export default function EditBannerModal(props: EditBannerProps) {

    const [show, setShow] = useState<boolean>(false);
    const [banner, setBanner] = useState<Banner | null>(null);
    const { showErrorModal } = useContext(ErrorContext);
    const { token } = useContext(AuthContext);

    useEffect(() => {
        if (props.bannerId) {
            getBanner();
            handleShow();
        }
    }, [props.bannerId])

    const handleClose = () => {
        setBanner(null)
        props.close();
        setShow(false);
    }
    const handleShow = () => setShow(true);

    const getBanner = async () => {
        if (!props.bannerId) return;
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    'id': props.bannerId
                })
            }
            await fetch(getWebshopFetchPath(props.environment) + 'getBanner', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        showErrorModal("Failed to get banner from " + props.environment);
                        return;
                    }
                    return res.json();
                })
                .then(({ banner }: { banner: Banner }) => {
                    setBanner({ ...banner });
                });
        } catch (e: any) {
            showErrorModal("Failed to get banner from " + props.environment + " - Server is unavailable at the moment");
        }
    }

    const updateBanner = async () => {
        if (!banner) return;
        let id = banner.id;
        if (id < 1) return;
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    'id': id,
                    'description': banner.description,
                    'heading': banner.heading,
                    'color': banner.color,
                    'fontColor': banner.fontColor,
                    'body': banner.body,
                    'btns': banner.btns,
                    'enabled': banner.enabled
                })
            }
            await fetch(getWebshopFetchPath(props.environment) + 'updateBanner', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        showErrorModal("Failed to update banner at " + props.environment);
                        return;
                    }
                    return res.json();
                }).then(() => {
                    handleClose();
                    props.updatedBanner();
                })
        } catch (e: any) {
            showErrorModal("Failed to update banner at " + props.environment + " - Server is unavailable at the moment");
        }

    }


    return (
        <>
            {show && banner &&
                <Modal
                    show={show}
                    backdrop="static"
                    onHide={() => handleClose()}
                    keyboard={false}
                >
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        updateBanner();
                    }}>
                        <Modal.Header closeButton>
                            <Modal.Title>Update Banner</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="dialog-data-field">
                                <span>Description:</span>
                                <input type="text" value={banner.description} required onChange={(item) => setBanner({ ...banner, description: item.target.value })} />
                            </div>
                            <div className="dialog-data-field">
                                <span>Banner Color:</span>
                                <input type="color" value={banner.color} onChange={(item) => setBanner({ ...banner, color: item.target.value })} />
                            </div>
                            <div className="dialog-data-field">
                                <span>Font Color:</span>
                                <input type="color" value={banner.fontColor} onChange={(item) => setBanner({ ...banner, fontColor: item.target.value })} />
                            </div>
                            <div className="dialog-data-field">
                                <span>Banner Header:</span>
                                <input type="text" value={banner.heading ?? ''} onChange={(item) => setBanner({ ...banner, heading: item.target.value })} />
                            </div>
                            <div className="dialog-data-field">
                                <span>Banner Body:</span>
                                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}>
                                    <div style={{ display: 'flex' }}>
                                        <Button style={{ marginLeft: 'auto', width: '35px' }} variant="secondary" onClick={() => {
                                            let temp = { ...banner };
                                            temp.body.push("");
                                            setBanner(temp);
                                        }}>+</Button>
                                        <Button style={{ marginLeft: '3px', width: '35px' }} variant="secondary" onClick={() => {
                                            let temp = { ...banner };
                                            temp.body.pop();
                                            setBanner(temp);
                                        }}>-</Button>
                                    </div>
                                    {banner.body.map((line, index) => {
                                        return (
                                            <input key={index} type="text" value={line}
                                                onChange={(item) => {
                                                    let temp = { ...banner };
                                                    temp.body[index] = item.target.value;
                                                    setBanner(temp);
                                                }}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="dialog-data-field">
                                <span>Banner Buttons:</span>
                                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}>
                                    <div style={{ display: 'flex' }}>
                                        <Button style={{ marginLeft: 'auto', width: '35px' }} variant="secondary" onClick={() => {
                                            let temp = { ...banner };
                                            temp.btns.push({ link: '', text: '', color: '#ffffff', fontColor: '#000000' });
                                            setBanner(temp);
                                        }}>+</Button>
                                        <Button style={{ marginLeft: '3px', width: '35px' }} variant="secondary" onClick={() => {
                                            let temp = { ...banner };
                                            temp.btns.pop();
                                            setBanner(temp);
                                        }}>-</Button>
                                    </div>
                                    {banner.btns.map((btn, index) => {
                                        return (
                                            <div key={index} style={{ display: 'flex', flexDirection: 'column', rowGap: '3px', marginBottom: '7px' }}>
                                                <div style={{ display: 'flex', columnGap: '5px', justifyContent: 'flex-end' }}>
                                                    <span>Button {index + 1} Link:</span>
                                                    <input style={{ right: '0' }} key={index} type="text" value={btn.link}
                                                        onChange={(item) => {
                                                            let temp = { ...banner };
                                                            temp.btns[index].link = item.target.value;
                                                            setBanner(temp);
                                                        }}
                                                    />
                                                </div>
                                                <div style={{ display: 'flex', columnGap: '5px', justifyContent: 'flex-end' }}>
                                                    <span>Button {index + 1} Text:</span>
                                                    <input style={{ right: '0' }} key={index} type="text" value={btn.text}
                                                        onChange={(item) => {
                                                            let temp = { ...banner };
                                                            temp.btns[index].text = item.target.value;
                                                            setBanner(temp);
                                                        }}
                                                    />
                                                </div>
                                                <div style={{ display: 'flex', columnGap: '5px', justifyContent: 'flex-end' }}>
                                                    <span>Button {index + 1} Color:</span>
                                                    <input style={{ right: '0' }} key={index} type="color" value={btn.color}
                                                        onChange={(item) => {
                                                            let temp = { ...banner };
                                                            temp.btns[index].color = item.target.value;
                                                            setBanner(temp);
                                                        }}
                                                    />
                                                </div>
                                                <div style={{ display: 'flex', columnGap: '5px', justifyContent: 'flex-end' }}>
                                                    <span>Button {index + 1} Font Color:</span>
                                                    <input style={{ right: '0' }} key={index} type="color" value={btn.fontColor}
                                                        onChange={(item) => {
                                                            let temp = { ...banner };
                                                            temp.btns[index].fontColor = item.target.value;
                                                            setBanner(temp);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="dialog-data-field">
                                <span>Enabled:</span>
                                <Switch checked={banner.enabled} onChange={(item) => setBanner({ ...banner, enabled: item.target.checked })} />
                            </div>
                            <div className="text-end-container">
                                <EnabledLabel enabled={banner.enabled} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => handleClose()}>
                                Close
                            </Button>
                            <Button variant="primary" type='submit'>Save</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            }
        </>
    );
}
