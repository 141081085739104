import { DeleteIcon } from "@fluentui/react-icons-mdl2";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

interface ConfirmationProps {
    confirm: () => void;
    title?: string;
    message: string[];
    show: boolean;
    cancel: () => void;
}

export function ConfirmModal(props: ConfirmationProps) {

    return (
        <>
            {props.show &&
                <Modal
                    show={props.show}
                    onHide={() => props.cancel()}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{props.title ?? 'Warning!'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {props.message.map((txt, idx) => {
                            return (<span key={idx} className={"dialog-messages-message"}>{txt}<br></br></span>)
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => props.cancel()}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={() => props.confirm()}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
}