import { Tabs, Tab } from "react-bootstrap";
import { Env, isLocal } from "../collector";

interface EnvironmentTabsProps {
    selectedTab: string;
    changedTab: (newTab: Env) => void;
}

export default function EnvironmentTabs(props: EnvironmentTabsProps) {

    const handleTabChange = (key: string | null): void => {
        if (key !== null && Object.values(Env).includes(key as Env)) {
            const validKey: Env = key as Env;
            props.changedTab(validKey);
        }
        else {
            props.changedTab(Env.TEST);
        }
    };


    return (
        <Tabs
            activeKey={props.selectedTab} onSelect={handleTabChange}
            defaultActiveKey="test"
            className="mb-3 mt-4"
            fill
        >
            <Tab eventKey={Env.PRODUCTION} title="Production" />
            <Tab eventKey={Env.TEST} title="Test" />
            <Tab eventKey={Env.LOCAL} title={"Local" + (isLocal() ? "" : " (disabled)")} disabled={!isLocal()} />
        </Tabs>
    )
}
