import { useContext, useState } from "react";
import { Coupon, LicenseType } from "../../../collector";
import { AuthContext } from "../../../context/AuthContext";
import { ErrorContext } from "../../../context/ErrorContext";
import { Button, Modal } from "react-bootstrap";
import Switch from "../../../components/Switch/Switch";
import EnabledLabel from "../../../components/EnabledLabel";

interface CreateCouponProps {
    createdCoupon: () => void;
    environment: string;
}


export default function CreateCouponModal(props: CreateCouponProps) {

    const [coupon, setCoupon] = useState<Coupon>({
        id: 0,
        name: '',
        enabled: false,
        product_id: 1,
        couponable_type: 'DOTE',
        couponable_id: 0,
        created_at: new Date(Date.now()),
        updated_at: new Date(Date.now()),
        deleted_at: new Date(Date.now()),
    });
    const [show, setShow] = useState<boolean>(false);
    const { showErrorModal } = useContext(ErrorContext);
    const { token, invalidWSToken } = useContext(AuthContext);

    const handleClose = () => {
        setCoupon({
            id: 0,
            name: '',
            enabled: false,
            product_id: 1,
            couponable_type: 'DOTE',
            couponable_id: 0,
            created_at: new Date(Date.now()),
            updated_at: new Date(Date.now()),
            deleted_at: new Date(Date.now()),
        });
        setShow(false);
    }
    const handleShow = () => setShow(true);

    const createCoupon = async () => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    'name': coupon.name,
                    'product_id': coupon.product_id,
                    'enabled': coupon.enabled
                }
                )
            }
            await fetch(props.environment + 'createCoupon', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Failed to create coupon");
                    }
                    return res.json();
                })
                .then(() => {
                    props.createdCoupon();
                    handleClose();
                });
        } catch (e: any) {
            showErrorModal(e.message ?? "Failed to create coupon - Server is unavailable at the moment");
        }
    }

    return (
        <>
            <Button variant='outline-dark' disabled={invalidWSToken(props.environment)} onClick={() => handleShow()} >
                Create Coupon
            </Button>
            {show && !invalidWSToken(props.environment) &&
                <Modal
                    show={show}
                    backdrop="static"
                    onHide={() => handleClose()}
                    keyboard={false}
                >
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        createCoupon();
                    }}>
                        <Modal.Header closeButton>
                            <Modal.Title>Create Coupon</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="dialog-data-field">
                                <span>Product:</span>
                                <select value={coupon.product_id} required
                                    onChange={(item) => setCoupon({ ...coupon, product_id: Number(item.target.value) })}
                                >
                                    <option value={LicenseType.Pro}>DOTE Pro</option>
                                    <option value={LicenseType.ProCommunity}>DOTE Pro Community</option>
                                    <option value={LicenseType.ProCommunityUpgrade}>DOTE Pro Community Upgrade</option>
                                    {/* <option value={LicenseType.SiteLicense}>Site-License</option> */}
                                </select>
                            </div>
                            <div className="dialog-data-field">
                                <span>Name:</span>
                                <input placeholder="Write coupon name here..." value={coupon.name} required
                                    onChange={(item) => setCoupon({ ...coupon, name: item.target.value })}
                                />
                            </div>
                            <div className="dialog-data-field">
                                <span>Enabled:</span>
                                <Switch checked={coupon.enabled} onChange={(item) => setCoupon({ ...coupon, enabled: item.target.checked })} />
                            </div>
                            <div className="text-end-container">
                                <EnabledLabel enabled={coupon.enabled} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => handleClose()}>
                                Close
                            </Button>
                            <Button variant="primary" type='submit'>Create Coupon</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            }
        </>
    );
}