import { useState, useContext, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Coupon, CouponRate, CouponCode } from "../../../../collector";
import { AuthContext } from "../../../../context/AuthContext";
import { ErrorContext } from "../../../../context/ErrorContext";
import CreateCouponCodeModal from "../../modals/CreateCouponCode";
import CreateCouponRateModal from "../../modals/CreateCouponRate";
import CouponCodes from "./CouponCodes";
import CouponRates from "./CouponRates";


interface CouponContentProps {
    coupon: Coupon;
    environment: string;
    goBack: () => void;
}

export default function CouponContent(props: CouponContentProps) {

    const [rates, setRates] = useState<CouponRate[]>([]);
    const [codes, setCodes] = useState<CouponCode[]>([]);
    const [isLoadingRates, setIsLoadingRates] = useState<boolean>(false);
    const [isLoadingCodes, setIsLoadingCodes] = useState<boolean>(false);
    const { token } = useContext(AuthContext);
    const { showErrorModal } = useContext(ErrorContext);

    useEffect(() => {
        fetchCodes();
        fetchRates();
    }, []);

    const fetchRates = async () => {
        setIsLoadingRates(true);
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    'coupon_id': props.coupon.id
                })
            }
            await fetch(props.environment + 'getRates', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Failed to fetch rates from server");
                    }
                    return res.json();
                })
                .then(({ rates }: { rates: CouponRate[] }) => {
                    setRates(rates);
                    setIsLoadingRates(false);
                });
        } catch (e: any) {
            showErrorModal(e.message ?? "Failed to fetch rates - Server is unavailable at the moment");
            setIsLoadingRates(false);
        }
    }

    const fetchCodes = async () => {
        setIsLoadingCodes(true);
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    'coupon_id': props.coupon.id
                })
            }
            await fetch(props.environment + 'getCodes', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Failed to fetch codes from server");
                    }
                    return res.json();
                })
                .then(({ codes }: { codes: CouponCode[] }) => {
                    setCodes(codes);
                    setIsLoadingCodes(false);
                });
        } catch (e: any) {
            showErrorModal(e.message ?? "Failed to fetch codes - Server is unavailable at the moment");
            setIsLoadingCodes(false);
        }
    }

    return (
        <>
            <div style={{ marginTop: '30px', marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                <Button variant='primary' className='btn-1' onClick={() => props.goBack()}>
                    Go Back
                </Button>
                <span style={{ marginLeft: 'auto', marginRight: 'auto', fontSize: '1.8em' }}>
                    {props.coupon.name}
                </span>
                <CreateCouponCodeModal
                    environment={props.environment}
                    createdCode={() => fetchCodes()}
                    couponId={props.coupon.id}
                />
                <CreateCouponRateModal
                    environment={props.environment}
                    createdRate={() => fetchRates()}
                    couponId={props.coupon.id}
                />
            </div>
            <CouponRates
                environment={props.environment}
                couponId={props.coupon.id}
                rates={rates}
                fetchRates={() => fetchRates()}
                isLoading={isLoadingRates}
            />
            <div style={{ marginBottom: '15px' }} />
            <CouponCodes
                environment={props.environment}
                couponId={props.coupon.id}
                fetchCodes={() => fetchCodes()}
                codes={codes}
                isLoading={isLoadingCodes}
            />
        </>
    )
}

