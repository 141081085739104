import React, { useContext } from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';
import './nav-bar.css';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';



export default function NavBar() {
    const navigator = useNavigate();
    const { logout } = useContext(AuthContext);

    return (
        <Navbar bg="primary" variant="dark" style={{ minHeight: '70px', fontSize: '1.2em', padding: '0px 32px' }} sticky='top' expand='xl'>
            <Container>
                <Navbar.Brand style={{ marginRight: "40px", fontSize: "1.5em" }}>DOTE Administration</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <NavbarCollapse>
                    <Nav className="me-auto">
                        <Nav.Link style={{ marginRight: "10px" }} onClick={() => navigator('/')}>Dashboard</Nav.Link>
                        <Nav.Link style={{ marginRight: "10px" }} onClick={() => navigator('licenses')}>Licenses</Nav.Link>
                        <Nav.Link style={{ marginRight: "10px" }} onClick={() => navigator('admins')}>Admin Users</Nav.Link>
                        <Nav.Link style={{ marginRight: "10px" }} onClick={() => navigator('versions')}>DOTE Versions</Nav.Link>
                        <Nav.Link style={{ marginRight: "10px" }} onClick={() => navigator('banner')}>Banner</Nav.Link>
                        <NavDropdown title="Orders" id="collasible-nav-dropdown" style={{ marginRight: "10px" }}>
                            <NavDropdown.Item onClick={() => navigator('orders')}>Orders</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => navigator('site-license')}>Site License Orders</NavDropdown.Item>
                        </NavDropdown>
                        {/* <Nav.Link style={{ marginRight: "10px" }} onClick={() => navigator('orders')}>Orders</Nav.Link> */}
                        <Nav.Link onClick={() => navigator('discount')}>Discount</Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link onClick={() => logout()} className="justify-content-end">Log out</Nav.Link>
                    </Nav>
                </NavbarCollapse>
            </Container>
        </Navbar>
    );
}

