import { useContext, useState } from "react";
import { ErrorContext } from "../../../context/ErrorContext";
import { AuthContext } from "../../../context/AuthContext";
import { Button, Modal } from "react-bootstrap";
import { CouponRate, CouponRateType } from "../../../collector";
import Switch from "../../../components/Switch/Switch";
import EnabledLabel from "../../../components/EnabledLabel";

interface CreateCouponRateProps {
    couponId: number;
    createdRate: () => void;
    environment: string;
}

export default function CreateCouponRateModal(props: CreateCouponRateProps) {

    const [rate, setRate] = useState<CouponRate>({
        id: 0,
        coupon_id: 0,
        order: 1,
        start: null,
        end: null,
        enabled: false,
        max_uses: 0,
        type: CouponRateType.Fixed,
        value: 1,
        description: '',
        invoice_line: '',
        created_at: new Date(Date.now()),
        updated_at: null,
        deleted_at: null,
        usedAmount: null
    });
    const [show, setShow] = useState<boolean>(false);

    const { showErrorModal } = useContext(ErrorContext);
    const { token } = useContext(AuthContext);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setRate({
            id: 0,
            coupon_id: 0,
            order: 1,
            start: null,
            end: null,
            enabled: false,
            max_uses: 0,
            type: CouponRateType.Fixed,
            value: 1,
            description: '',
            invoice_line: '',
            created_at: new Date(Date.now()),
            updated_at: null,
            deleted_at: null,
            usedAmount: null
        });
    };

    const createRate = async () => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    'coupon_id': props.couponId,
                    'start': rate.start ?? null,
                    'end': rate.end ?? null,
                    'enabled': rate.enabled,
                    'max_uses': rate.max_uses,
                    'type': rate.type,
                    'value': rate.value,
                    'description': rate.description,
                    'invoice_line': rate.invoice_line
                })
            }
            await fetch(props.environment + 'createRate', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Failed to create rate");
                    }
                    return res.json();
                })
                .then(() => {
                    props.createdRate();
                    handleClose();
                });
        } catch (error: any) {
            showErrorModal(error.message ?? "Failed to create rate - Server is unavailable at the moment");
        }

    }

    return (
        <>
            <Button variant='outline-dark' style={{ marginLeft: '10px' }} onClick={() => handleShow()}>
                Create Rate
            </Button>
            {show &&
                <Modal
                    show={show}
                    backdrop="static"
                    onHide={() => handleClose()}
                    keyboard={false}
                >
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        createRate();
                    }}>
                        <Modal.Header closeButton>
                            <Modal.Title>Create Rate:</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="dialog-data-field">
                                <span>Type:</span>
                                <select value={rate.type}
                                    onChange={(item) => {
                                        let temp = { ...rate };
                                        temp.type = item.target.value as CouponRateType;
                                        if (temp.type === CouponRateType.Percentage && temp.value > 99) {
                                            temp.value = 99;
                                        }
                                        setRate(temp);
                                    }}
                                >
                                    <option value={CouponRateType.Fixed}>Fixed</option>
                                    <option value={CouponRateType.Percentage}>Percentage %</option>
                                </select>
                            </div>
                            <div className="dialog-data-field">
                                <span>Value:</span>
                                <input type='numbers' placeholder="Write value here..." min={1} value={rate.value} required
                                    onChange={(item) => {
                                        if (isNaN(Number(item.target.value))) {
                                            return;
                                        }
                                        let tooHigh = (rate.type === CouponRateType.Percentage && Number(item.target.value) >= 100);
                                        let temp = { ...rate };
                                        temp.value = tooHigh ? 99 : Number(item.target.value);
                                        setRate(temp);
                                    }} />
                            </div>
                            <div className="dialog-data-field">
                                <span>Max uses: (0 is inifity)</span>
                                <input type='numbers' placeholder="Write max uses here..." min={0} value={rate.max_uses} required
                                    onChange={(item) => setRate({ ...rate, max_uses: Number(item.target.value) })}
                                />
                            </div>
                            <div className="dialog-data-field">
                                <span>Start time:</span>
                                <input type='datetime-local' defaultValue={rate.start ?? undefined} required={rate.end ? true : false}
                                    onChange={(item) => setRate({ ...rate, start: item.target.value })}
                                />
                            </div>
                            <div className="dialog-data-field">
                                <span>End time:</span>
                                <input type='datetime-local' defaultValue={rate.end ?? undefined} required={rate.start ? true : false}
                                    onChange={(item) => setRate({ ...rate, end: item.target.value })}
                                />
                            </div>
                            <div className="dialog-data-field">
                                <span><em>Note: To use start- and end time, one must give both fields a value</em></span>
                            </div>
                            <div className="dialog-data-field">
                                <span>Invoice line:</span>
                                <input type="text" placeholder="Write invoice line here..." value={rate.invoice_line ?? ""} required
                                    onChange={(item) => setRate({ ...rate, invoice_line: item.target.value })}
                                />
                            </div>
                            <div className="dialog-data-field">
                                <span>Enabled:</span>
                                <Switch checked={rate.enabled} onChange={(item) => setRate({ ...rate, enabled: item.target.checked })} />
                            </div>
                            <div className="text-end-container">
                                <EnabledLabel enabled={rate.enabled} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => handleClose()}>
                                Close
                            </Button>
                            <Button variant="primary" type='submit'>Create Rate</Button>
                        </Modal.Footer>
                    </form>
                </Modal >
            }
        </>
    );
}