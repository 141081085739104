import { Component, ErrorInfo, ReactNode } from 'react';
import { SERVER_API_ROOT, getAPIroot, isLocal } from '../collector';

interface ErrorBoundaryProps {
    children: ReactNode;
    invalidateAuthToken: () => void;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        // You can also log the error to an error reporting service
        console.error('Error caught by ErrorBoundary:', error, errorInfo);
    }

    render(): ReactNode {
        if (this.state.hasError && !isLocal() && getAPIroot() === SERVER_API_ROOT) {
            this.props.invalidateAuthToken();
        }

        return this.props.children;
    }
}

export default ErrorBoundary;