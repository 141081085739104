import { Tab, Row, Col, Nav } from "react-bootstrap";
import CouponsView from "./CouponsView/CouponsView";
import RedeemCodesOverview from "./RedeemCodesView/RedeemCodesView";
import BulkRangesView from "./BulkRangesView/BulkRangesView";
import BulkCouponsView from "./BulkCouponsView/BulkCouponsView";


interface SideBarWrapperProps {
    environment: string;
    activeTab: string;
    changeTab: (activeSideBarTab: string | null) => void;
}

export default function SideBarWrapper(props: SideBarWrapperProps) {

    return (
        <Tab.Container
            id="left-tabs-example"
            activeKey={props.activeTab}
            mountOnEnter
            onSelect={props.changeTab}
            unmountOnExit
        >
            <Row>
                <Col sm={2}>
                    <Nav
                        variant="pills"
                        className="flex-column mt-4"
                        activeKey={props.activeTab}
                    >
                        <Nav.Item style={{ marginTop: '10px', paddingRight: '10px', borderRight: '2px solid #90bdff' }}>
                            <Nav.Link eventKey="coupons" className='p-2'>Coupons</Nav.Link>
                        </Nav.Item>
                        <Nav.Item style={{ paddingRight: '10px', borderRight: '2px solid #90bdff' }}>
                            <Nav.Link eventKey="bulk-coupons" className='p-2'>Bulk Coupons</Nav.Link>
                        </Nav.Item>
                        <Nav.Item style={{ paddingRight: '10px', borderRight: '2px solid #90bdff' }}>
                            <Nav.Link eventKey="bulk-ranges" className='p-2'>Bulk Ranges</Nav.Link>
                        </Nav.Item>
                        <Nav.Item style={{ paddingRight: '10px', borderRight: '2px solid #90bdff' }}>
                            <Nav.Link eventKey="redeem-codes" className='p-2'>Redeem Codes</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col sm={10}>
                    <Tab.Content>
                        <Tab.Pane eventKey="coupons">
                            <CouponsView
                                environment={props.environment}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="bulk-coupons">
                            <BulkCouponsView
                                environment={props.environment}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="bulk-ranges">
                            <BulkRangesView
                                environment={props.environment}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={"redeem-codes"}>
                            <RedeemCodesOverview
                                environment={props.environment}
                            />
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    )
}
