import { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap'
import { LicenseStats, PRODSERVER_WS_API_ROOT, ResponseStatus, getAPIroot } from '../../collector';
import { AuthContext } from '../../context/AuthContext';
import { ErrorContext } from '../../context/ErrorContext';
import DateRange from '../../components/DateRange';
import ActionBar from '../../components/ActionBar/ActionBar';

export default function Dashboard() {

    const { token, logout } = useContext(AuthContext);
    const { showErrorModal } = useContext(ErrorContext);
    const [licenseStats, setLicenseStats] = useState<LicenseStats | null>(null);
    const [webshopStats, setWebshopStats] = useState<LicenseStats | null>(null);

    useEffect(() => {
        fetchStats(getAPIroot(), null, null);
        // fetchStats(PRODSERVER_WS_API_ROOT);
    }, [])

    const fetchStats = async (url: string, startDate: Date | null, endDate: Date | null) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer' + token(),
                },
                body: JSON.stringify({
                    "start_date": startDate ? startDate.getTime() : null,
                    "end_date": endDate ? endDate.getTime() : null
                })
            }
            await fetch(url + 'get_stats', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        showErrorModal("Failed to fetch stats from " + getAPIroot() === url ? "license server" : "webshop production server");
                        return;
                    }
                    return res.json();
                })
                .then(({ stats, status }) => {
                    if (status === ResponseStatus.INVALID_AUTH_TOKEN) logout(true);
                    if (url === PRODSERVER_WS_API_ROOT) {
                        console.log("WEBSHOP");
                    }
                    else if (url === getAPIroot()) {
                        setLicenseStats(stats);
                    }
                });
        } catch (e: any) {
            showErrorModal(e.message ?? "Failed to fetch stats from " + (getAPIroot() === url ? "license server" : "webshop production server") + " - Server is unavailable at the moment");
        }
    }

    const totalLicenses = (licenseStats?.aauLicenses ?? 0) + (licenseStats?.proLicenses ?? 0) + (licenseStats?.proCommunityLicenses ?? 0);
    return (
        <Container>
            <ActionBar
                title='Dashboard'
                reloadCallback={() => { }}
            >
                <DateRange
                    label='Issue date filter:'
                    dateRangeHasBeenSet={([startDate, endDate]) => fetchStats(getAPIroot(), startDate, endDate)}
                />
            </ActionBar>
            <Container fluid>
                <Row>
                    <Col md={6} lg={6}>
                        <Card style={{ marginTop: '10px', height: '90%' }}>
                            <Card.Body>
                                <Card.Title>N- Year Licenses:</Card.Title>
                                <div className="dashboard-content-container">
                                    {licenseStats &&
                                        licenseStats?.nYearsLicenses.sort((a, b) => a.years - b.years).map((val, i) => {
                                            return (<p key={i}>{val.years} Year Licenses: {val.amount}</p>)
                                        })
                                    }
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} lg={6}>
                        <Card style={{ marginTop: '10px', height: '90%' }}>
                            <Card.Body>
                                <Card.Title>N- Devices Activated:</Card.Title>
                                <div className="dashboard-content-container">
                                    {licenseStats &&
                                        licenseStats?.nDevicesActivated.sort((a, b) => a.deviceCount - b.deviceCount).map((val, i) => {
                                            return (<p key={i}>{val.deviceCount} Device(s): {val.amount}</p>)
                                        })
                                    }
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={5} lg={5}>
                        <Card style={{ marginTop: '10px' }}>
                            <Card.Body>
                                <Card.Title>License Statistics:</Card.Title>
                                <div className="dashboard-content-container">
                                    <p>Licenses (Total): {totalLicenses}</p>
                                    <p>Licenses (Active): {totalLicenses - (licenseStats?.expiredLicenses ?? 0)}</p>
                                    <p>Expired Licenses: {licenseStats?.expiredLicenses ?? "undefined"}</p>
                                    <p>Revoked Licenses: {licenseStats?.revokedLicenses ?? "undefined"}</p>
                                    <p>Pro Licenses: {licenseStats?.proLicenses ?? "undefined"}</p>
                                    <p>Pro Community Licenses: {licenseStats?.proCommunityLicenses ?? "undefined"}</p>
                                    <p>AAU Licenses (Total): {licenseStats?.aauLicenses ?? "undefined"}</p>
                                    <p>AAU Student Licenses: {licenseStats?.aauStudentLicenses ?? "undefined"}</p>
                                    <p>AAU Staff Licenses: {licenseStats?.aauStaffLicenses ?? "undefined"}</p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={7} lg={7}>
                        <Card style={{ marginTop: '10px' }}>
                            <Card.Body>
                                <Card.Title>AAU Department Licenses:</Card.Title>
                                <div className="dashboard-content-container">
                                    {licenseStats &&
                                        licenseStats?.aauDepartmentLicenses.map((val, i) => {
                                            return (<p key={i}>{val.department}: {val.amount}</p>)
                                        })
                                    }
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
