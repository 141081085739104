import { useState, useEffect, useContext } from "react";
import { ErrorContext } from "../../../context/ErrorContext";
import { AuthContext } from "../../../context/AuthContext";
import { Button, Table } from "react-bootstrap";
import PaginationPartial from "../../../components/pagination/pagination";
import { DeleteIcon, EditIcon } from "@fluentui/react-icons-mdl2";
import { BulkCoupon, LOCAL_WS_API_ROOT, PRODSERVER_WS_API_ROOT, TESTSERVER_WS_API_ROOT, getEdition, getWSEnvironment } from "../../../collector";
import CreateBulkRangeModal from "../modals/CreateBulkRange";
import { ConfirmModal } from "../../../modals/ConfirmModal";
import EditBulkRangeModal from "../modals/EditBulkRange";
import ActionBar from "../../../components/ActionBar/ActionBar";
import LoadingPlaceholder from "../../../components/LoadingPlaceholder";
import EnabledLabel from "../../../components/EnabledLabel";

interface BulkRangesViewProps {
    environment: string;
}

export default function BulkRangesView(props: BulkRangesViewProps) {

    const [bulkRanges, setBulkRanges] = useState<BulkCoupon[]>([]);
    const [bulkRangeElements, setBulkRangeElements] = useState<JSX.Element[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [maxPages, setMaxPages] = useState<number>(0);
    const [bulkRangesPerPage, setBulkRangesPerPage] = useState<number>(20);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [deleteBulkRangeId, setDeleteBulkRangeId] = useState<number | null>(null);
    const [editBulkRangeId, setEditBulkRangeId] = useState<number | null>(null);

    const { showErrorModal } = useContext(ErrorContext);
    const { token, invalidWSToken } = useContext(AuthContext);

    useEffect(() => {
        fetchBulkRanges();
    }, [props.environment])
    useEffect(() => {
        setMaxPages(Math.ceil(bulkRanges.length / bulkRangesPerPage));
        createBulkRangeElements();
    }, [JSON.stringify(bulkRanges), currentPage, bulkRangesPerPage]);

    const createBulkRangeElements = () => {
        let elements: JSX.Element[] = [];
        if (bulkRanges.length > 0) {
            elements = bulkRanges
                .sort((a, b) => a.id - b.id)
                .slice(currentPage * bulkRangesPerPage, (currentPage + 1) * bulkRangesPerPage)
                .map((currentBulkRange, index) => {
                    return (
                        <tr key={index}>
                            <td>{currentBulkRange.delimiter}</td>
                            <td>{getEdition(currentBulkRange.productId)}</td>
                            <td>{currentBulkRange.discount}%</td>
                            <td><EnabledLabel enabled={currentBulkRange.enabled} /></td>
                            <td onClick={(e) => e.preventDefault()}>
                                <Button variant='outline-dark' size='sm' style={{ marginRight: '3px' }} onClick={() => setEditBulkRangeId(currentBulkRange.id)}>
                                    <EditIcon />
                                </Button>
                                <Button variant="danger" size="sm" onClick={(e) => setDeleteBulkRangeId(currentBulkRange.id)}>
                                    <DeleteIcon />
                                </Button>
                            </td>
                        </tr>
                    )
                }
                );
        }
        setBulkRangeElements(elements);
    }

    const fetchBulkRanges = async () => {
        setIsLoading(true);
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                }
            }
            await fetch(props.environment + 'getBulkRanges', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw Error("Failed to fetch bulk ranges from " + getWSEnvironment(props.environment));
                    }
                    return res.json();
                })
                .then(({ bulkRanges }) => {
                    setBulkRanges([...bulkRanges]);
                });
        } catch (e: any) {
            setBulkRanges([]);
            showErrorModal(e.message ?? "Can't access the " + getWSEnvironment(props.environment) +
                ' database.\nTry to log in again.'
            );
        }
        setIsLoading(false);
    }

    const deleteBulkRange = async (id: number) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    'id': id
                })
            }
            await fetch(props.environment + 'deleteCoupon', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw Error("Failed to delete bulk coupon from server");
                    }
                    return res.json();
                })
                .then(() => {
                    fetchBulkRanges();
                });
        } catch (error: any) {
            showErrorModal(error ?? "Failed to delete bulk coupon - Server is unavailable at the moment");
        }
    }

    return (
        <>
            <ActionBar
                isLoading={isLoading}
                reloadCallback={() => fetchBulkRanges()}
                title="Bulk Ranges"
            >
                <CreateBulkRangeModal
                    environment={props.environment}
                    createdBulkRange={() => fetchBulkRanges()}
                />
            </ActionBar>
            {invalidWSToken(props.environment) &&
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <h5 style={{ color: 'red' }}>Could not connect to the database, try to log in again</h5>
                </div>}
            {!invalidWSToken(props.environment) &&
                <>
                    <Table striped bordered hover size="md">
                        <thead>
                            <tr>
                                <th>Delimiter:</th>
                                <th>Product:</th>
                                <th>Discount:</th>
                                <th>Enabled:</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isLoading && bulkRangeElements}
                        </tbody>
                    </Table>
                    {isLoading && <LoadingPlaceholder />}
                    <PaginationPartial
                        currentPage={currentPage}
                        maxPages={maxPages}
                        viewAmount={bulkRangesPerPage}
                        changePage={(newPage: number) => setCurrentPage(newPage)}
                        changeViewAmount={(newAmount: number) => {
                            setBulkRangesPerPage(newAmount);
                            setMaxPages(Math.ceil(bulkRanges.length / newAmount));
                            setCurrentPage(0);
                        }}
                        viewAmountPossible={[10, 20, 50, 100]}
                    />
                </>
            }
            {deleteBulkRangeId &&
                <ConfirmModal
                    confirm={() => {
                        deleteBulkRange(deleteBulkRangeId);
                        setDeleteBulkRangeId(null);
                    }}
                    message={['Are you sure you want to delete this bulk range?', 'This action can not be undone']}
                    show={deleteBulkRangeId !== null}
                    cancel={() => setDeleteBulkRangeId(null)}
                />
            }
            {editBulkRangeId &&
                <EditBulkRangeModal
                    environment={props.environment}
                    bulkRangeId={editBulkRangeId}
                    editedBulkRange={() => {
                        fetchBulkRanges();
                        setEditBulkRangeId(null);
                    }}
                    close={() => setEditBulkRangeId(null)}
                />
            }
        </>
    )
}