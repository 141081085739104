import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/AuthContext';
import './Login.css';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function Login() {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const { login, isInvalidCredentials, isTokenExpired, isLoading, token } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (token()) {
            navigate('/licenses');
        }
    }, [token()]);

    return (
        <div className='login-container' >
            <div className="form-signin w-100 m-auto">
                <form
                    style={{ textAlign: 'center' }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        login(username, password)
                    }}
                >
                    <h1 className="h3 fw-normal" style={{ marginBottom: '30px' }}>DOTE License Administration</h1>
                    <h3 className="mb-3 fw-normal" style={{ textAlign: 'left' }}>Sign in</h3>
                    <div className="form-floating">
                        <input type="text" className="form-control" id="floatingUsername" placeholder="Type username here..."
                            value={username} required onChange={(e) => setUsername(e.target.value)}
                        />
                        <label htmlFor="floatingUsername">Username</label>
                    </div>
                    <div className="form-floating">
                        <input type="password" className="form-control" id="floatingPassword" placeholder="Password"
                            value={password} required onChange={(e) => setPassword(e.target.value)}
                        />
                        <label htmlFor="floatingPassword">Password</label>
                    </div>
                    {isInvalidCredentials &&
                        <p style={{ color: 'red', marginBottom: '10px' }}>*Incorrect username or password</p>
                    }
                    {isTokenExpired &&
                        <p style={{ color: 'red', marginBottom: '10px' }}>*Token expired - please log in again</p>
                    }
                    <button className="w-100 btn btn-lg btn-primary" type="submit" disabled={isLoading}>
                        {isLoading ? <Spinner animation='border' size='sm' /> : 'Sign In'}
                    </button>
                    <p className="mt-5 mb-3 text-muted">&copy; DOTE - Administration </p>
                </form>
            </div>
        </div>
    )
}
