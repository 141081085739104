import { Row, Spinner } from "react-bootstrap";

export default function LoadingPlaceholder() {
    return (
        <Row className="d-flex flex-grow-1 justify-content-center m-5 gap-4">
            <Spinner animation="grow" variant={'secondary'} />
            <Spinner animation="grow" variant={'secondary'} />
            <Spinner animation="grow" variant={'secondary'} />
        </Row>
    )
}
