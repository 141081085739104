import { useEffect, useState } from "react";

export const useError = () => {
    const [show, setShow] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);

    const showErrorModal = (text: string) => {
        setShow(true);
        setErrorMsg(text);
    }

    const closeErrorModal = () => {
        setShow(false);
        setErrorMsg(null);
    }

    const removeErrorMsg = (id: number) => {
        // setErrorMsgQueue(errorMsgQueue.filter((val, index) => index !== id));
    }

    return { show, errorMsg, showErrorModal, closeErrorModal };
};