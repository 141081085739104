import { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../../../../context/AuthContext';
import { ErrorContext } from '../../../../context/ErrorContext';
import { LicenseEntry, LicenseType, getAPIroot } from '../../../../collector';
import { Button, Modal } from 'react-bootstrap';
import { Utils } from '../../../../components/utils';
import { ConfirmModal } from '../../../../modals/ConfirmModal';
import Switch from '../../../../components/Switch/Switch';

interface EditLicenseProps {
	licenseId: string | null;
	editedLicense: () => void;
	close: () => void;
}

export default function EditLicenseModal(props: EditLicenseProps) {

	const [license, setLicense] = useState<LicenseEntry | null>(null);
	const [oldLicense, setOldLicense] = useState<LicenseEntry | null>(null);
	const [generateNewKey, setGenerateNewKey] = useState<boolean>(false);
	const [show, setShow] = useState<boolean>(false);
	const [showConfirm, setShowConfirm] = useState<boolean>(false);
	const { showErrorModal } = useContext(ErrorContext);
	const { token } = useContext(AuthContext);

	useEffect(() => {
		if (props.licenseId) {
			fetchLicense();
			handleShow();
		}
	}, [props.licenseId])

	const handleClose = () => {
		setLicense(null);
		setOldLicense(null);
		props.close();
		setShow(false);
	}
	const handleShow = () => setShow(true);

	const editLicense = async () => {
		try {
			if (!license || !oldLicense) throw Error('Failed to edit license - license was null');
			const requestOptions = {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer' + token(),
				},
				body: JSON.stringify({
					'oldLicense': oldLicense,
					'newLicense': license
				})
			}
			await fetch(getAPIroot() + 'update_license', requestOptions)
				.then((res) => {
					if (!res.ok) {
						throw Error("Failed to update license");
					}
					return res.json();
				})
				.then(() => {
					props.editedLicense();
					handleClose();
				});
		} catch (e: any) {
			showErrorModal(e.message ?? "Failed to update license - Server is unavailable at the moment");
		}
	}

	const fetchLicense = async () => {
		if (!props.licenseId) return;
		try {
			const requestOptions = {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer' + token(),
				},
				body: JSON.stringify({ 'userID': props.licenseId })
			}
			await fetch(getAPIroot() + 'get_license', requestOptions)
				.then((res) => {
					if (!res.ok) {
						throw Error("Failed to fetch license");
					}
					return res.json();
				})
				.then((data: { status: number, license: LicenseEntry }) => {
					if (data.status === 0) {
						setLicense({ ...data.license });
						setOldLicense({ ...data.license });
					}
					else {
						throw Error("Failed to fetch license - Status: " + data.status);
					}
				});
		} catch (e: any) {
			showErrorModal(e.message ?? "Failed to fetch license - Server is unavailable at the moment");
			handleClose();
		}
	}

	return (
		<>
			{show && license &&
				<Modal
					style={{ visibility: (showConfirm ? 'hidden' : 'visible') }}
					show={show}
					backdrop="static"
					onHide={() => handleClose()}
					keyboard={false}
				>
					<Modal.Header closeButton>
						<Modal.Title>Edit License</Modal.Title>
					</Modal.Header>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							if (license === null || oldLicense === null) return;
							if (license.name !== oldLicense.name ||
								license.affiliation !== oldLicense.affiliation ||
								license.start_date !== oldLicense.start_date ||
								license.expire_date !== oldLicense.expire_date ||
								license.persistent_license !== oldLicense.persistent_license ||
								license.license_type !== oldLicense.license_type) {
								setShowConfirm(true);
							}
							else {
								editLicense();
							}
						}}
					>
						<Modal.Body>
							<div className="dialog-data-field">
								<span>User ID:</span>
								<span>{license.licensed_to}</span>
							</div>
							<div className="dialog-data-field">
								<span>Issue Date:</span>
								<span>{new Date(license.issue_date).toLocaleString()}</span>
							</div>
							<div className="dialog-data-field">
								<span>Revoked:</span>
								<Switch checked={license.revoked} onChange={(item) => setLicense({ ...license, revoked: item.target.checked })} />
							</div>
							<div className="dialog-data-field">
								<span>Device Limit:</span>
								<input type="number" value={license.device_limit} min={1} max={999} onChange={(item) => {
									if (Number(item.currentTarget.value) > Number(item.currentTarget.max)) {
										item.currentTarget.value = item.currentTarget.max;
									}
									else if (Number(item.currentTarget.value) < Number(item.currentTarget.min)) {
										item.currentTarget.value = item.currentTarget.min;
									}
									setLicense({ ...license, device_limit: item.target.valueAsNumber });
								}}
								/>
							</div>
							<div className="dialog-data-field">
								<span>Devices:</span>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									{license.devices.map((item, idx) => {
										return (<input key={idx} value={item} readOnly style={{ marginBottom: '2px' }} />)
									})}
								</div>
							</div>
							<div className="dialog-data-field">
								<span>Clear Devices:</span>
								<Button size='sm' variant='outline-dark' onClick={() => setLicense({ ...license, devices: [] })} >Clear</Button>
							</div>
							<div className="dialog-data-field">
								<span>Generate new key: (Enables more fields)</span>
								<Switch checked={generateNewKey} onChange={(item) => setGenerateNewKey(item.target.checked)} />
							</div>
							{generateNewKey &&
								<>
									<div className="dialog-data-field">
										<span>License Type:</span>
										<select value={license.license_type} required onChange={(item) => setLicense({ ...license, license_type: Number(item.target.value) })} >
											<option value={LicenseType.Pro}>DOTE Pro</option>
											<option value={LicenseType.ProCommunity}>DOTE Pro Community</option>
											<option value={LicenseType.AAU}>DOTE AAU</option>
											<option value={LicenseType.SiteLicense}>Site-License</option>
										</select>
									</div>
									<div className="dialog-data-field">
										<span>Name:</span>
										<input type="text" value={license.name} required onChange={(item) => setLicense({ ...license, name: item.target.value })} />
									</div>
									<div className="dialog-data-field">
										<span>Affiliation:</span>
										<input type="text" value={license.affiliation ?? ""} onChange={(item) => setLicense({ ...license, affiliation: item.target.value })} />
									</div>
									<div className="dialog-data-field">
										<span>Start Date:</span>
										<input type="date" value={Utils.dateToValidString(new Date(license.start_date))} required onChange={(item) => {
											let currVal = new Date(item.target.value).getTime();
											let temp = { ...license! };
											if (currVal > temp.expire_date) {
												temp.expire_date = currVal;
											}
											temp.start_date = new Date(item.target.value).getTime();
											setLicense({ ...temp });
										}}

										/>
									</div>
									<div className="dialog-data-field">
										<span>Expiry Date:</span>
										<input type="date" value={Utils.dateToValidString(new Date(license.expire_date))}
											min={Utils.dateToValidString(new Date(license.start_date))}
											onChange={(item) => setLicense({ ...license, expire_date: new Date(item.target.value).getTime() })}
											required
										/>
									</div>
									<div className="dialog-data-field">
										<span>Persistent License:</span>
										<Switch checked={license.persistent_license} onChange={(item) => setLicense({ ...license, persistent_license: item.target.checked })} />
									</div>
								</>
							}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={() => handleClose()}>
								Close
							</Button>
							<Button type='submit'>Save Changes</Button>
						</Modal.Footer>
					</form>
				</Modal>
			}
			<ConfirmModal
				show={showConfirm}
				message={["This will invalidate the existing key!", "Are you sure that you would like to proceed?"]}
				confirm={() => {
					editLicense();
					setShowConfirm(false);
				}}
				cancel={() => setShowConfirm(false)}
			/>
		</>
	);
}
