import React, { InputHTMLAttributes } from 'react';
import './Switch.css';

interface SwitchProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
}

const Switch: React.FC<SwitchProps> = ({ label, ...rest }) => {
    return (
        <div>
            {label && <label htmlFor={rest.id}>{label}</label>}
            <label className="switch">
                <input type="checkbox" checked={rest.checked} onChange={rest.onChange} />
                <span className="slider round"></span>
            </label>
        </div>

    );
};

export default Switch;