import { Icon } from '@iconify/react';
import { useContext, useEffect, useState } from 'react';
import { Table, Container, Button } from 'react-bootstrap';
import { getAPIroot } from '../../collector';
import { AuthContext } from '../../context/AuthContext';
import { ErrorContext } from '../../context/ErrorContext';
import { ConfirmModal } from '../../modals/ConfirmModal';
import ActionBar from '../../components/ActionBar/ActionBar';

export enum DoteBranches {
    main = "main",
    beta = "beta",
    alpha = "alpha"
}

export enum DynUrlRefs {
    DOTE_Download_PC_x64 = "DOTE_Download_PC_x64",
    DOTE_Download_Mac_Intel = "DOTE_Download_Mac_Intel",
    DOTE_Download_Mac_Arm64 = "DOTE_Download_Mac_Arm64",
    DOTEbase_Download_PC_x64 = "DOTEbase_Download_PC_x64",
    DOTEbase_Download_Mac_Intel = "DOTEbase_Download_Mac_Intel",
    DOTEbase_Download_Mac_Arm64 = "DOTEbase_Download_Mac_Arm64",
    DOTE_Download_Lego_Demo_Project = "DOTE_Download_Lego_Demo_Project",
    DOTEvase_Download_Lego_Demo_Dotespace = "DOTEbase_Download_Lego_Demo_Dotespace",
}

interface CurrentDoteVersion {
    branch: DoteBranches;
    version: string;
    changeLog?: string;
}

interface CurrentDotebaseVersion {
    branch: DoteBranches;
    version: string;
    changeLog: string;
}

export interface DynamicUrls {
    ref: string;
    url: string;
}

interface DoteVersionState {
    branchThatIsUpdating: DoteBranches | null;
    newVersion: string | null;

    urlReferenceThatIsUpdating: DynUrlRefs | null;
    newUrl: string | null;
}

export default function DoteVersionsPage() {

    const { token } = useContext(AuthContext);
    const { showErrorModal } = useContext(ErrorContext);
    const doteBranches = Object.values(DoteBranches).map((val) => val);
    const dynUrlRefs = Object.values(DynUrlRefs).map((val) => val);

    const [branchThatIsUpdating, setBranchThatIsUpdating] = useState<DoteBranches | null>(null);
    const [dotebaseBranchThatIsUpdating, setDotebaseBranchThatIsUpdating] = useState<DoteBranches | null>(null);
    const [urlReferenceThatIsUpdating, setUrlReferenceThatIsUpdating] = useState<DynUrlRefs | null>(null);
    const [newVersion, setNewVersion] = useState<string | null>(null);
    const [newChangeLog, setNewChangelog] = useState<string | null>(null);
    const [newUrl, setNewUrl] = useState<string | null>(null);
    const [branchVersions, setBranchVersions] = useState<CurrentDoteVersion[]>([]);
    const [dotebaseBranchVersions, setDotebaseBranchVersions] = useState<CurrentDotebaseVersion[]>([]);
    const [dynamicUrls, setDynamicUrls] = useState<DynamicUrls[]>([]);
    const [showVersionChangeConfirmModal, setShowVersionChangeConfirmModal] = useState<boolean>(false);
    const [showDotebaseVersionChangeConfirmModal, setDotebaseShowVersionChangeConfirmModal] = useState<boolean>(false);
    const [showUrlChangeConfirmModal, setShowUrlChangeConfirmModal] = useState<boolean>(false);

    useEffect(() => {
        updateTables();
    }, []);

    const updateTables = () => {
        let temp: CurrentDoteVersion[] = [];
        let temp2: CurrentDotebaseVersion[] = [];
        let temp3: DynamicUrls[] = [];
        Promise.all(doteBranches.map((branch: DoteBranches) => {
            return fetchVersion(branch).then(({ version, changeLog }) => {
                temp.push({ branch: branch, version: version, changeLog: changeLog });
            });
        })).then(() => { setBranchVersions([...temp]) });

        Promise.all(doteBranches.map((branch: DoteBranches) => {
            return fetchDotebaseVersion(branch).then(({ version, changeLog} ) => {
                temp2.push({ branch: branch, version: version, changeLog: changeLog });
            });
        })).then(() => { setDotebaseBranchVersions([...temp2]) });

        Promise.all(dynUrlRefs.map((ref: DynUrlRefs) => {
            return fetchUrl(ref).then((url: string) => {
                temp3.push({ ref: ref, url: url });
            })
        })).then(() => setDynamicUrls([...temp3]))
    }

    const fetchVersion = async (branch: DoteBranches): Promise<{ version:string; changeLog?:string }> => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer' + token(),

                },
                body: JSON.stringify({
                    'branch': branch
                })
            }
            const result = await fetch(getAPIroot() + 'get_current_version', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        showErrorModal(`Failed to fetch version number for branch ${branch}.`);
                        return;
                    }
                    return res.json();
                })
                .then((data: { status: number, version: string, changeLog?: string }) => {
                    if (data.status === 0) {
                        return {
                            version: data.version,
                            changeLog: data.changeLog
                        }
                    }
                    else {
                        showErrorModal(`Failed to fetch version number for branch ${branch} [Error no. ${data.status}].`);
                    }
                });
            return result ?? {
                version: "",
                changeLog: ""
            }
        } catch (error) {
            showErrorModal("Failed to fetch version number - Server is unavailable at the momment");
        }
        return {
            version: "",
            changeLog: ""
        }
    }
    const setVersion = async (branch: DoteBranches, version: string, changeLog: string) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer' + token(),

                },
                body: JSON.stringify({
                    'branch': branch,
                    'version': version,
                    'changeLog': changeLog
                })
            }
            await fetch(getAPIroot() + 'set_current_version', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        showErrorModal(`Failed to set version number for branch ${branch}.`);
                        return;
                    }
                    return res.json();
                })
                .then((data: { status: number }) => {
                    if (data.status === 0) {
                        return data.status;
                    }
                    else {
                        showErrorModal(`Failed to set version number for branch ${branch} [Error no. ${data.status}].`);
                    }
                });
        } catch (error) {
            showErrorModal("Failed to fetch version number - Server is unavailable at the momment");
        }
    }

    const fetchDotebaseVersion = async (branch: DoteBranches):Promise<{ version:string; changeLog:string }> => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer' + token(),

                },
                body: JSON.stringify({
                    'branch': branch
                })
            }
            const result = await fetch(getAPIroot() + 'get_current_dotebase_version', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        showErrorModal(`Failed to fetch dotebase version number for branch ${branch}.`);
                        return;
                    }
                    return res.json();
                })
                .then((data: { status: number, version: string, changeLog: string }) => {
                    if (data.status === 0) {
                        return {
                            version: data.version,
                            changeLog: data.changeLog
                        }
                    }
                    else {
                        showErrorModal(`Failed to fetch dotebase version number for branch ${branch} [Error no. ${data.status}].`);
                    }
                });
            return result ?? {
                version: "",
                changeLog: ""
            }
        } catch (error) {
            showErrorModal("Failed to fetch dotebase version number - Server is unavailable at the momment");
        }
        return {
            version: "",
            changeLog: ""
        }
    }
    const setDotebaseVersion = async (branch: DoteBranches, version: string, changeLog: string) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer' + token(),

                },
                body: JSON.stringify({
                    'branch': branch,
                    'version': version,
                    'changeLog': changeLog
                })
            }
            await fetch(getAPIroot() + 'set_current_dotebase_version', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        showErrorModal(`Failed to set dotebase version number for branch ${branch}.`);
                        return;
                    }
                    return res.json();
                })
                .then((data: { status: number }) => {
                    if (data.status === 0) {
                        return data.status;
                    }
                    else {
                        showErrorModal(`Failed to set dotebase version number for branch ${branch} [Error no. ${data.status}].`);
                    }
                });
        } catch (error) {
            showErrorModal("Failed to fetch dotebase version number - Server is unavailable at the momment");
        }
    }

    const fetchUrl = async (ref: DynUrlRefs) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer' + token(),

                },
                body: JSON.stringify({
                    'ref': ref
                })
            }
            const result = await fetch(getAPIroot() + 'get_dynamic_url', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        showErrorModal(`Failed to fetch dynamic url for reference ${ref}.`);
                        return;
                    }
                    return res.json();
                })
                .then((data: { status: number, url: string }) => {
                    if (data.status === 0) {
                        return data.url;
                    }
                    else {
                        showErrorModal(`Failed to fetch dynamic url for reference ${ref} [Error no. ${data.status}].`);
                    }
                });
            return result ?? "";
        } catch (error) {
            showErrorModal("Failed to fetch dynamic url - Server is unavailable at the momment");
        }
        return "";
    }
    const setUrl = async (ref: DynUrlRefs, url: string) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer' + token(),

                },
                body: JSON.stringify({
                    'ref': ref,
                    'url': url
                })
            }
            await fetch(getAPIroot() + 'set_dynamic_url', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        showErrorModal(`Failed to set dynamic url for ref ${ref}.`);
                        return;
                    }
                    return res.json();
                })
                .then((data: { status: number }) => {
                    if (data.status === 0) {
                        return data.status;
                    }
                    else {
                        showErrorModal(`Failed to set dynamic url for ref ${ref} [Error no. ${data.status}].`);
                    }
                });
        } catch (error) {
            showErrorModal("Failed to fetch dynamic url - Server is unavailable at the momment")
        }
    }

    return (
        <Container>
            <ConfirmModal
                show={showVersionChangeConfirmModal}
                message={["This will change the current version of DOTE. Users will be notified that a new release is available. (Have you already uploaded the new version!?)"
                    , "Are you sure that you would like to proceed?"]}
                cancel={() => {
                    if (branchThatIsUpdating === null) return;

                    setBranchThatIsUpdating(null);
                    setNewVersion(null);
                    setNewChangelog(null);
                    updateTables();
                    setShowVersionChangeConfirmModal(false);
                }}
                confirm={() => {
                    if (branchThatIsUpdating !== null && newVersion !== null && newChangeLog !== null) {
                        setVersion(branchThatIsUpdating, newVersion, newChangeLog).then(() => {
                            setBranchThatIsUpdating(null);
                            setNewVersion(null);
                            setNewChangelog(null);
                            updateTables();
                        });
                    }
                    setShowVersionChangeConfirmModal(false);
                }}
            />
            <ConfirmModal
                show={showDotebaseVersionChangeConfirmModal}
                message={["This will change the current version of DOTEbase. Users will be notified that a new release is available. (Have you already uploaded the new version!?)"
                    , "Are you sure that you would like to proceed?"]}
                cancel={() => {
                    if (dotebaseBranchThatIsUpdating === null) return;
                    setDotebaseBranchThatIsUpdating(null);
                    setNewVersion(null);
                    setNewChangelog(null);
                    updateTables();
                    setDotebaseShowVersionChangeConfirmModal(false);
                }}
                confirm={() => {
                    if (dotebaseBranchThatIsUpdating !== null && newVersion !== null && newChangeLog !== null) {
                        setDotebaseVersion(dotebaseBranchThatIsUpdating, newVersion, newChangeLog).then(() => {
                            setDotebaseBranchThatIsUpdating(null);
                            setNewVersion(null);
                            setNewChangelog(null);
                            updateTables();
                        });
                    }
                    setDotebaseShowVersionChangeConfirmModal(false);
                }}
            />
            <ConfirmModal
                show={showUrlChangeConfirmModal}
                message={["This will immediately change the url used on the DOTE.aau.dk website. (Have you already uploaded/updated the target of this URL!?)"
                    , "Are you sure that you would like to proceed?"]}
                cancel={() => {
                    if (urlReferenceThatIsUpdating === null) return;
                    setUrlReferenceThatIsUpdating(null);
                    setNewUrl(null);
                    updateTables();
                    setShowUrlChangeConfirmModal(false);
                }}
                confirm={() => {
                    if (urlReferenceThatIsUpdating !== null && newUrl !== null) {
                        setUrl(urlReferenceThatIsUpdating, newUrl).then(() => {
                            setUrlReferenceThatIsUpdating(null);
                            setNewUrl(null);
                            updateTables();
                        });
                    }
                    setShowUrlChangeConfirmModal(false);
                }}
            />
            <ActionBar title='DOTE Versions' reloadCallback={() => { }} />
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th className='versions-list-col1'>Branch:</th>
                        <th className='versions-list-col2'>Current Version:</th>
                        <th className='versions-list-col3'>Current Message for Version:</th>
                        <th className='versions-list-col4'></th>
                    </tr>
                </thead>
                <tbody>
                    {Object.values(DoteBranches).map((branch: DoteBranches, index: number) => {
                        let result = branchVersions.find((val) => val.branch === branch);
                        let version = result?.version ?? "";
                        let changeLog = result?.changeLog ?? "";
                        if (branchThatIsUpdating === branch && newVersion !== null) {
                            version = newVersion;
                        }
                        if (branchThatIsUpdating === branch && newChangeLog !== null) {
                            changeLog = newChangeLog;
                        }
                        return (
                            <tr key={index}>
                                <td className='versions-list-col1'>{branch}</td>
                                <td className='versions-list-col2'>
                                    <input
                                        type="text"
                                        value={version}
                                        disabled={branchThatIsUpdating !== branch}
                                        autoComplete='off'
                                        onChange={(item) => setNewVersion(item.target.value)} />
                                </td>
                                <td className='versions-list-col3'>
                                    {   branchThatIsUpdating === null
                                        ?   <input
                                                type="text"
                                                value={changeLog}
                                                disabled={true}
                                                autoComplete='off'
                                                // onChange={(item) => setNewChangeLog(item.target.value)}
                                            />
                                        :   <div className="form-floating">
                                                <textarea
                                                    className="form-control"
                                                    value={changeLog}
                                                    readOnly={branchThatIsUpdating !== branch}
                                                    onChange={(item) => {
                                                        setNewChangelog(item.target.value)}}
                                                    // placeholder="Leave a comment here"
                                                    style={{height: "100px"}}/>
                                            </div>
                                    }
                                </td>
                                <td className='versions-list-col4'>
                                    <Button
                                        variant='outline-dark'
                                        size='sm'
                                        style={{ marginRight: '3px' }}
                                        onClick={() => {
                                            if (!branchThatIsUpdating) {
                                                setBranchThatIsUpdating(branch);
                                                setNewVersion(version);
                                                setNewChangelog(changeLog);
                                            } else {
                                                setBranchThatIsUpdating(null);
                                                setNewVersion(null);
                                                setNewChangelog(null);
                                            }
                                        }}
                                    >
                                        {branchThatIsUpdating !== branch ? <Icon icon="fa-solid:lock" /> : <Icon icon="fa-solid:lock-open" />}
                                        Change
                                    </Button>
                                    <Button
                                        variant='outline-danger'
                                        size='sm'
                                        disabled={branchThatIsUpdating !== branch}
                                        onClick={() => setShowVersionChangeConfirmModal(true)}
                                    >
                                        <Icon icon="fluent:save-arrow-right-24-regular" />
                                        Save
                                    </Button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            <hr className='p-2 mt-5'></hr>
            <ActionBar title='DOTEbase Versions' reloadCallback={() => { }} />
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th className='versions-list-col1'>Branch:</th>
                        <th className='versions-list-col2'>Current Version:</th>
                        <th className='versions-list-col3'>Current Message for Version:</th>
                        <th className='versions-list-col4'></th>
                    </tr>
                </thead>
                <tbody>
                    {Object.values(DoteBranches).map((branch: DoteBranches, index: number) => {
                        let result = dotebaseBranchVersions.find((val) => val.branch === branch);
                        let version = result?.version ?? "";
                        let changeLog = result?.changeLog ?? "";
                        if (dotebaseBranchThatIsUpdating === branch && newVersion !== null) {
                            version = newVersion;
                        }
                        if (dotebaseBranchThatIsUpdating === branch && newChangeLog !== null) {
                            changeLog = newChangeLog;
                        }
                        return (
                            <tr key={index}>
                                <td className='versions-list-col1'>{branch}</td>
                                <td className='versions-list-col2'>
                                    <input
                                        type="text"
                                        value={version}
                                        disabled={dotebaseBranchThatIsUpdating !== branch}
                                        autoComplete='off'
                                        onChange={(item) => setNewVersion(item.target.value)} />
                                </td>
                                <td className='versions-list-col3'>
                                    {   dotebaseBranchThatIsUpdating === null
                                        ?   <input
                                                type="text"
                                                value={changeLog}
                                                disabled={true}
                                                autoComplete='off'
                                                // onChange={(item) => setNewChangeLog(item.target.value)}
                                            />
                                        :   <div className="form-floating">
                                                <textarea
                                                    className="form-control"
                                                    readOnly={dotebaseBranchThatIsUpdating !== branch}
                                                    value={changeLog}
                                                    onChange={(item) => setNewChangelog(item.target.value)}
                                                    // placeholder="Leave a comment here"
                                                    style={{height: "100px"}}/>
                                            </div>
                                    }
                                </td>
                                <td className='versions-list-col4'>
                                    <Button
                                        variant='outline-dark'
                                        size='sm'
                                        style={{ marginRight: '3px' }}
                                        onClick={() => {
                                            if (!dotebaseBranchThatIsUpdating) {
                                                setDotebaseBranchThatIsUpdating(branch);
                                                setNewVersion(version);
                                                setNewChangelog(changeLog);
                                            } else {
                                                setDotebaseBranchThatIsUpdating(null);
                                                setNewVersion(null);
                                                setNewChangelog(null);
                                            }
                                        }}
                                    >
                                        {dotebaseBranchThatIsUpdating !== branch ? <Icon icon="fa-solid:lock" /> : <Icon icon="fa-solid:lock-open" />}
                                        Change
                                    </Button>
                                    <Button
                                        variant='outline-danger'
                                        size='sm'
                                        disabled={dotebaseBranchThatIsUpdating !== branch}
                                        onClick={() => setDotebaseShowVersionChangeConfirmModal(true)}
                                    >
                                        <Icon icon="fluent:save-arrow-right-24-regular" />
                                        Save
                                    </Button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            <hr className='p-2 mt-5'></hr>
            <Table striped bordered hover responsive className="url-list">
                <thead>
                    <tr>
                        <th>Reference name:</th>
                        <th>URL:</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {Object.values(DynUrlRefs).map((ref: DynUrlRefs, index: number) => {
                        let value = dynamicUrls.find((val) => val.ref === ref)?.url ?? "";
                        return (
                            <tr key={index}>
                                <td>{ref}</td>
                                <td>
                                    <input
                                        className='url-field'
                                        type="text"
                                        value={urlReferenceThatIsUpdating === ref && newUrl !== null ? newUrl : value}
                                        disabled={urlReferenceThatIsUpdating !== ref}
                                        autoComplete='off'
                                        onChange={(event) => setNewUrl(event.target.value)}
                                    />
                                </td>
                                <td>
                                    <Button
                                        variant='outline-dark'
                                        size='sm'
                                        style={{ marginRight: "3px" }}
                                        onClick={() => {
                                            if (!urlReferenceThatIsUpdating) {
                                                setUrlReferenceThatIsUpdating(ref);
                                            } else {
                                                setUrlReferenceThatIsUpdating(null);
                                                setNewUrl(null);
                                            }
                                        }}
                                    >
                                        {urlReferenceThatIsUpdating !== ref ? <Icon icon="fa-solid:lock" /> : <Icon icon="fa-solid:lock-open" />}
                                        Change
                                    </Button>
                                    <Button
                                        variant='outline-danger'
                                        size='sm'
                                        className='list-btn'
                                        disabled={urlReferenceThatIsUpdating !== ref}
                                        onClick={() => setShowUrlChangeConfirmModal(true)}
                                    >
                                        <Icon icon="fluent:save-arrow-right-24-regular" />
                                        Save
                                    </Button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </Container>
    );
}