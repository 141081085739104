import React, { useContext, useEffect } from 'react'
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function RequireAuth({ children }: any) {
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!token()) {
            navigate('/login');
        }
    }, []);

    return children;
}
