import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { ErrorContext } from "../../../context/ErrorContext";
import { Button, Form, Table } from "react-bootstrap";
import { DeleteIcon, EditIcon } from "@fluentui/react-icons-mdl2";
import PaginationPartial from "../../../components/pagination/pagination";
import { getEdition, getWSEnvironment } from "../../../collector";
import EditRedeemCodeModal from "../modals/EditRedeemCode";
import CreateRedeemCodeModal from "../modals/CreateRedeemCode";
import { ConfirmModal } from "../../../modals/ConfirmModal";
import ActionBar from "../../../components/ActionBar/ActionBar";
import LoadingPlaceholder from "../../../components/LoadingPlaceholder";
import useDebounce from "../../../hooks/useDebounce";
import EnabledLabel from "../../../components/EnabledLabel";

enum OrderIdFilter {
    ALL = -1,
    UNDEFINED = 0,
    DEFINED = 1
}

export interface RedeemCode {
    id: number;
    enabled: boolean;
    productId: number;
    code: string;
    uses: number;
    maxUses: number;
    orderId: number | null | undefined;
    created_at: Date | null;
    users?: { firstname: string | null, lastname: string | null, email: string | null }[];
}

interface RedeemCodesProps {
    environment: string;
}

export default function RedeemCodesView(props: RedeemCodesProps) {

    const [redeemCodes, setRedeemCodes] = useState<RedeemCode[]>([]);
    const [redeemCodeElements, setRedeemCodeElements] = useState<JSX.Element[]>([]);
    const [editingRedeemCodeId, setEditingRedeemCodeId] = useState<number | null>(null);
    const [deleteRedeemCodeId, setDeleteRedeemCodeId] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [totalElements, setTotalElements] = useState<number>(0);

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [maxPages, setMaxPages] = useState<number>(0);
    const [elementsPerPage, setElementsPerPage] = useState<number>(20);

    const [searchValue, setSearchValue] = useState<string>('');
    const debouncedSearchValue = useDebounce(searchValue);
    const [orderIdFilterValue, setOrderIdFilterValue] = useState<OrderIdFilter>(OrderIdFilter.ALL);

    const { token, invalidWSToken } = useContext(AuthContext);
    const { showErrorModal } = useContext(ErrorContext);

    useEffect(() => {
        if (!invalidWSToken(props.environment)) {
            fetchRedeemCodes();
        }
    }, [props.environment, currentPage, elementsPerPage, debouncedSearchValue, orderIdFilterValue]);

    useEffect(() => {
        createRedeemCodeElements();
    }, [JSON.stringify(redeemCodes)]);

    useEffect(() => {
        if (currentPage > (maxPages - 1)) setCurrentPage(0);
    }, [maxPages])

    const createRedeemCodeElements = () => {
        let elements: JSX.Element[] = [];
        if (redeemCodes.length > 0) {
            elements = redeemCodes.map((currentRedeemCode, index) => {
                return (
                    <tr key={index}>
                        <td>{currentRedeemCode.created_at ? new Date(currentRedeemCode.created_at).toLocaleString() : 'Unknown'}</td>
                        <td>{currentRedeemCode.orderId ?? 'None'}</td>
                        <td>{currentRedeemCode.code}</td>
                        <td>{getEdition(currentRedeemCode.productId)}</td>
                        <td><EnabledLabel enabled={currentRedeemCode.enabled} /></td>
                        <td>{currentRedeemCode.uses} of {currentRedeemCode.maxUses === 0 ? '∞' : currentRedeemCode.maxUses}</td>
                        <td>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {currentRedeemCode.users ? currentRedeemCode.users.map((user, idx) => <span key={idx}>{user.email}</span>) : "None"}
                            </div>
                        </td>
                        <td onClick={(e) => e.preventDefault()}>
                            <Button variant='outline-dark' size='sm' style={{ marginRight: '3px' }} onClick={(e) => setEditingRedeemCodeId(currentRedeemCode.id)}>
                                <EditIcon />
                            </Button>
                            <Button variant="danger" size="sm" onClick={(e) => setDeleteRedeemCodeId(currentRedeemCode.id)}>
                                <DeleteIcon />
                            </Button>
                        </td>
                    </tr>
                )
            });
        }
        setRedeemCodeElements(elements);
    }

    const fetchRedeemCodes = async () => {
        setIsLoading(true);
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    'perPage': elementsPerPage,
                    'currentPage': currentPage,
                    'searchValue': debouncedSearchValue,
                    'withOrderId': orderIdFilterValue
                })
            }
            await fetch(props.environment + 'getRedeemCodes', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Failed to fetch redeem codes from " + getWSEnvironment(props.environment));
                    }
                    return res.json();
                })
                .then(({ redeemCodes }: { redeemCodes: { data: RedeemCode[], total: number } }) => {
                    setRedeemCodes([...redeemCodes.data]);
                    setTotalElements(redeemCodes.total);
                    setMaxPages(Math.ceil(redeemCodes.total / elementsPerPage));
                    setIsLoading(false);
                });
        } catch (e: any) {
            setRedeemCodes([]);
            setTotalElements(0);
            setMaxPages(0);
            setIsLoading(false);
            showErrorModal(e.message ?? "Can't access the " + getWSEnvironment(props.environment) + ' database.\nTry to log in again.')
        }
    }

    const deleteRedeemCode = async (id: number) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    'id': id
                })
            }
            await fetch(props.environment + 'deleteRedeemCode', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Failed to delete redeem code from server");
                    }
                    return res.json();
                })
                .then(() => {
                    fetchRedeemCodes();
                });
        } catch (e: any) {
            showErrorModal(e.message ?? "Failed to delete redeem code - Server is unavailable at the moment");
        }
    }

    return (
        <>
            <EditRedeemCodeModal
                environment={props.environment}
                editedRedeemCode={() => fetchRedeemCodes()}
                redeemCodeId={editingRedeemCodeId}
                close={() => setEditingRedeemCodeId(null)}
            />
            {deleteRedeemCodeId &&
                <ConfirmModal
                    confirm={() => {
                        deleteRedeemCode(deleteRedeemCodeId);
                        setDeleteRedeemCodeId(null);
                    }}
                    message={['Are you sure you want to delete this redeem code?', 'This action can not be undone']}
                    show={deleteRedeemCodeId !== null}
                    cancel={() => setDeleteRedeemCodeId(null)}
                />
            }
            <ActionBar
                isLoading={isLoading}
                reloadCallback={() => fetchRedeemCodes()}
                title="Redeem Codes"
            >
                <CreateRedeemCodeModal
                    createdRedeemCode={() => fetchRedeemCodes()}
                    environment={props.environment}
                />
                <input style={{ marginLeft: '4px' }} value={searchValue} placeholder={"Search for OrderID..."} onChange={(e) => setSearchValue(e.currentTarget.value)} />
                <Form.Select style={{ marginLeft: '5px', width: '150px' }} value={orderIdFilterValue} onChange={(e) => setOrderIdFilterValue(Number(e.target.value))}>
                    <option value={OrderIdFilter.ALL}>All</option>
                    <option value={OrderIdFilter.DEFINED}>With OrderID</option>
                    <option value={OrderIdFilter.UNDEFINED}>Without OrderID</option>
                </Form.Select>
            </ActionBar>
            {invalidWSToken(props.environment) &&
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <h5 style={{ color: 'red' }}>Could not connect to the database, try to log in again (Not currently working)</h5>
                </div>
            }
            {!invalidWSToken(props.environment) &&
                <>
                    <Table striped bordered hover size="md">
                        <thead>
                            <tr>
                                <th>Issue Date:</th>
                                <th>Order ID:</th>
                                <th>Code:</th>
                                <th>Product:</th>
                                <th>Enabled:</th>
                                <th>Used:</th>
                                <th>Users:</th>
                                <th>Count: {totalElements}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isLoading && redeemCodeElements}
                        </tbody>
                    </Table>
                    {isLoading && <LoadingPlaceholder />}
                    <PaginationPartial
                        currentPage={currentPage}
                        maxPages={maxPages}
                        viewAmount={elementsPerPage}
                        changePage={(newPage: number) => setCurrentPage(newPage)}
                        changeViewAmount={(newAmount: number) => {
                            setElementsPerPage(newAmount);
                            setCurrentPage(0);
                        }}
                        viewAmountPossible={[10, 20, 50, 100]}
                    />
                </>
            }
        </>
    )
}