import { Container } from "react-bootstrap";
import { Env, LOCAL_WS_API_ROOT, PRODSERVER_WS_API_ROOT, TESTSERVER_WS_API_ROOT, isLocal } from "../../collector";
import { useState } from "react";
import SideBarWrapper from "./SideBarWrapper";
import EnvironmentTabs from "../../components/EnvironmentTabs";

export default function DiscountPage() {

    const [selectedSideBarTab, setSelectedSideBarTab] = useState<string>('coupons');
    const [selectedEnvTab, setSelectedEnvTab] = useState<Env>(isLocal() ? Env.LOCAL : Env.TEST);

    const handleChangeTab = (activeTab: string | null) => setSelectedSideBarTab(activeTab ?? 'coupons');

    const getEnv = (tab: Env) => {
        switch (tab) {
            case Env.LOCAL:
                return LOCAL_WS_API_ROOT;
            case Env.TEST:
                return TESTSERVER_WS_API_ROOT;
            case Env.PRODUCTION:
                return PRODSERVER_WS_API_ROOT;
            default:
                return TESTSERVER_WS_API_ROOT;
        }
    }

    return (
        <Container>
            <EnvironmentTabs
                selectedTab={selectedEnvTab}
                changedTab={(newTab: Env) => setSelectedEnvTab(newTab)}
            />
            <SideBarWrapper
                environment={getEnv(selectedEnvTab)}
                activeTab={selectedSideBarTab}
                changeTab={handleChangeTab}
            />
        </Container>
    )
}