import { useContext, useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ErrorContext } from '../context/ErrorContext';

export function ErrorModal() {
    const { show, errorMsg, closeErrorModal } = useContext(ErrorContext);

    return (
        <Modal
            show={show}
            onHide={() => closeErrorModal()}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title style={{ color: 'red', marginLeft: 'auto' }}>
                    An error occured!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{errorMsg ?? ''}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => closeErrorModal()}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}
