import { createContext } from 'react';

type ErrorContext = {
    showErrorModal: (text: string) => void;
    closeErrorModal: () => void;
    // removeErrorMsg: (id: number) => void;
    show: boolean;
    errorMsg: string | null;
}

export const ErrorContext = createContext<ErrorContext>({
    showErrorModal: (text: string) => { },
    closeErrorModal: () => { },
    show: false,
    errorMsg: null,
    // removeErrorMsg: (id: number) => { },
});