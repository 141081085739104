import { createContext } from 'react';

interface AuthContext {
    token: () => string | null;
    invalidWSToken: (env: string) => boolean;
    login: (username: string, password: string) => void;
    logout: (tokenExpired?: boolean | undefined) => void;
    isTokenExpired: boolean;
    isLoading: boolean;
    isInvalidCredentials: boolean;
}

export const AuthContext = createContext<AuthContext>({
    token: () => null,
    invalidWSToken: () => true,
    login: () => { },
    logout: () => { },
    isTokenExpired: false,
    isLoading: false,
    isInvalidCredentials: false
});