import { useCallback, useContext, useEffect, useState } from 'react';
import { Container, Table, Row, Col } from 'react-bootstrap'
import { Order, isLocal, LicenseType, Env, getWebshopFetchPath } from '../../collector';
import { AuthContext } from '../../context/AuthContext';
import { ErrorContext } from '../../context/ErrorContext';
import PaginationPartial from '../../components/pagination/pagination';
import EnvironmentTabs from '../../components/EnvironmentTabs';
import DateRange from '../../components/DateRange';
import ActionBar from '../../components/ActionBar/ActionBar';
import LoadingPlaceholder from '../../components/LoadingPlaceholder';
import React from 'react';
import SiteLicenseOrdersTable from './SiteLicenseOrdersTable';


export default function SiteLicenseOrdersPage() {

    const { token } = useContext(AuthContext);
    const { showErrorModal } = useContext(ErrorContext);
    const [selectedEnvTab, setSelectedEnvTab] = useState<Env>(isLocal() ? Env.LOCAL : Env.TEST);
    const [expandedRow, setExpandedRow] = useState<number | null>(null);

    const handleRowToggle = (rowId: number) => setExpandedRow(expandedRow === rowId ? null : rowId);

    const getProductName = (productId: number | undefined) => {
        switch (productId) {
            case LicenseType.Pro:
                return "DOTE Pro";
            case LicenseType.ProCommunity:
                return "DOTE Pro Community";
            case LicenseType.ProCommunityUpgrade:
                return "DOTE Pro Community Upgrade";
            case LicenseType.AAU:
                return "DOTE AAU";
            default:
                return "undefined";
        }
    }
    const handleRateType = (rateType: string | null) => {
        if (rateType === 'fixed') {
            return 'DKK'
        } else return rateType;
    }

    const fetchOrders = useCallback(async (currentPage: number, perPage: number, notPaid: boolean, dateInterval: any) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    currentPage: currentPage + 1,
                    perPage: perPage,
                    notPaid: notPaid,
                    // startDate: dateInterval.startDate ? dateInterval.startDate.getTime() : null,
                    // endDate: dateInterval.endDate ? dateInterval.endDate.getTime() : null
                })
            }
            const result = await fetch(getWebshopFetchPath(selectedEnvTab) + 'getSiteLicenses', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        showErrorModal("Failed to fetch " + (notPaid ? "pending" : "paid") + " orders from " + selectedEnvTab);
                        return;
                    }
                    return res.json();
                })
                .then(({ siteLicenses }: { siteLicenses: { data: any[], total: number } }) => {
                    return { data: [...siteLicenses.data], total: siteLicenses.total };
                })
            return result;
        } catch (e: any) {
            showErrorModal("Failed to fetch " + (notPaid ? "pending" : "paid") + " orders from " + selectedEnvTab + " - Server is unavailable at the moment");
            return { data: [], total: 0 };
        }
    }, [selectedEnvTab, showErrorModal, token]);

    return (
        <Container>
            <EnvironmentTabs
                selectedTab={selectedEnvTab}
                changedTab={(newTab: Env) => setSelectedEnvTab(newTab)}
            />
            <SiteLicenseOrdersTable selectedEnvTab={selectedEnvTab} fetchOrders={fetchOrders} isPendingList={true} />
            <SiteLicenseOrdersTable selectedEnvTab={selectedEnvTab} fetchOrders={fetchOrders} isPendingList={false} />
        </Container>
    )
}
