import React, { Component } from 'react'
import { Form, Pagination } from 'react-bootstrap';

interface PaginationPartialProps {
    currentPage: number;
    maxPages: number;
    viewAmount: number;
    viewAmountPossible: number[];

    changePage: (newPage: number) => void;
    changeViewAmount: (newAmount: number) => void;
}

export default class PaginationPartial extends Component<PaginationPartialProps, {}> {

    render() {
        return (
			<div style={{display: 'flex', justifyContent:'center'}}>
				<Pagination>
					<Pagination.Prev disabled={this.props.currentPage <= 0} onClick={()=>this.props.changePage(this.props.currentPage - 1)}/>
					
					<Pagination.Item active={this.props.currentPage===0}
						onClick={()=>{
							if(this.props.currentPage !== 0)
                                this.props.changePage(0);
						}}>
						{1}
					</Pagination.Item>

					{this.props.currentPage >= 3 && <Pagination.Ellipsis disabled/>}
					
					{this.props.currentPage === this.props.maxPages - 1 && this.props.maxPages > 3 &&
					<Pagination.Item  
						onClick={()=>{
							this.props.changePage(this.props.currentPage - 2);
						}}>
						{this.props.currentPage - 1}
					</Pagination.Item>}
					
					{this.props.currentPage >= 2 &&
					<Pagination.Item
						onClick={()=>{
							this.props.changePage(this.props.currentPage - 1);
						}}>
						{this.props.currentPage}
					</Pagination.Item>}
					
					{this.props.currentPage !== 0 && this.props.currentPage !== this.props.maxPages-1 && this.props.maxPages !== 1 &&
					<Pagination.Item
						active>
						{this.props.currentPage+1}
					</Pagination.Item>}
					
					{this.props.currentPage < this.props.maxPages - 2 && 
					<Pagination.Item
						onClick={()=>{
							this.props.changePage(this.props.currentPage + 1);
						}}>
						{this.props.currentPage + 2}
					</Pagination.Item>}
					
					{this.props.currentPage === 0 && this.props.maxPages > 3 && 
					<Pagination.Item
						onClick={()=>{
							this.props.changePage(this.props.currentPage + 2);
						}}>
						{this.props.currentPage + 3}
					</Pagination.Item>}
					
					{this.props.currentPage < this.props.maxPages - 3 && <Pagination.Ellipsis disabled/>}

					{this.props.maxPages > 1 &&
					<Pagination.Item active={this.props.currentPage===this.props.maxPages - 1} 
						onClick={()=>{
							this.props.changePage(this.props.maxPages - 1);
						}}>
						{this.props.maxPages}
					</Pagination.Item>}

					<Pagination.Next disabled={this.props.currentPage >= this.props.maxPages-1} onClick={()=>this.props.changePage(this.props.currentPage + 1)}/>
				</Pagination>
				<div style={{ marginLeft: '5px', height: '33.5px' }}>
					<Form.Select size="sm" style={{height: '100%'}} value={this.props.viewAmount} 
						onChange={(e)=>{
                            this.props.changeViewAmount(Number(e.target.value));
						}
					}>
                        {this.props.viewAmountPossible.map((amount, index) => {
                            return (
                                <option key={index} value={amount}>{amount} per page</option>
                            );
                        })}
					</Form.Select>
				</div>
			</div>
		);
    }
}
