import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PRODSERVER_WS_API_ROOT, TESTSERVER_WS_API_ROOT, getAPIroot, isLocal } from "../collector";
import { useSessionStorage } from "./useSessionStorage";
import { LOCAL_API_ROOT } from "../collector";
import { ErrorContext } from "../context/ErrorContext";
import { LOCAL_WS_API_ROOT } from "../collector";

export const useAuth = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isTokenExpired, setIsTokenExpired] = useState<boolean>(false);
    const [isInvalidCredentials, setIsInvalidCredentials] = useState<boolean>(false);
    const navigate = useNavigate();
    const { getItem, setItem, removeItem } = useSessionStorage();
    const { showErrorModal } = useContext(ErrorContext);


    const login = async (username: string, password: string) => {
        try {
            setIsTokenExpired(false);
            setIsLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    'username': username,
                    'password': password,
                })
            }
            await fetch(getAPIroot() + 'login', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw Error;
                    }
                    return res.json();
                })
                .then((data: { status: number, token: string }) => {
                    if (data.status !== 0) {
                        setIsInvalidCredentials(true);
                        setIsLoading(false);
                        return;
                    }
                    if (isLocal()) {
                        saveTokenInWebshopDB(data.token, LOCAL_WS_API_ROOT);
                    }
                    saveTokenInWebshopDB(data.token, PRODSERVER_WS_API_ROOT);
                    saveTokenInWebshopDB(data.token, TESTSERVER_WS_API_ROOT);

                    setItem('authToken', data.token);
                    setIsInvalidCredentials(false);
                    setIsLoading(false);
                    navigate('/');
                });
        } catch (error) {
            showErrorModal('It is not possible to log in at the moment due to a server error');
            removeItem('authToken');
            setIsLoading(false);
        }
    };

    const saveTokenInWebshopDB = async (token: string, urlPrefix: string) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'token': token,
                })
            }
            await fetch(urlPrefix + 'saveToken', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error();
                    }
                    return res.json();
                })
                .then(() => {
                    setItem('authToken' + urlPrefix, token);
                });
        } catch (error) {
            let environment = urlPrefix === TESTSERVER_WS_API_ROOT ? 'development' : 'production';
            if (isLocal()) environment = 'local';
            console.error('Could not save jwt token in the ' + environment +
                ' database.\nCoupons for this environment will not be available.'
            );
        }
    }

    const logout = (tokenExpired?: boolean | undefined) => {
        if (tokenExpired === true) setIsTokenExpired(true);
        removeItem('authToken');
        removeItem('authToken' + PRODSERVER_WS_API_ROOT);
        removeItem('authToken' + TESTSERVER_WS_API_ROOT);
        if (isLocal()) removeItem('authToken' + LOCAL_WS_API_ROOT);
        navigate('/login');
        // TODO: Perhaps in the future, invalidate the token server-side
    };

    const token = () => {
        //TODO: Add check to see if token is valid?
        return getItem('authToken');
    }
    const invalidWSToken = (env: string) => {
        return getItem('authToken' + env) === null;
    }

    return { token, isTokenExpired, login, invalidWSToken, logout, isLoading, isInvalidCredentials };
};