import { useContext, useEffect, useState } from "react";
import { Coupon, LicenseType } from "../../../collector";
import { AuthContext } from "../../../context/AuthContext";
import { ErrorContext } from "../../../context/ErrorContext";
import { Button, Modal } from "react-bootstrap";
import Switch from "../../../components/Switch/Switch";
import EnabledLabel from "../../../components/EnabledLabel";

interface EditCouponProps {
    editedCoupon: () => void;
    couponId: number | null;
    environment: string;
    close: () => void;
}

export default function EditCouponModal(props: EditCouponProps) {
    const [coupon, setCoupon] = useState<Coupon | null>(null);
    const [show, setShow] = useState<boolean>(false);
    const { showErrorModal } = useContext(ErrorContext);
    const { token } = useContext(AuthContext);

    useEffect(() => {
        if (props.couponId) {
            fetchCoupon();
            handleShow();
        }
    }, [props.couponId])

    const handleClose = () => {
        setCoupon(null);
        props.close();
        setShow(false);
    }
    const handleShow = () => setShow(true);

    const fetchCoupon = async () => {
        if (!props.couponId) return;
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    'id': props.couponId
                })
            }
            await fetch(props.environment + 'getCoupon', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Failed to fetch coupon");
                    }
                    return res.json();
                })
                .then(({ coupon }) => {
                    setCoupon(coupon);
                });
        } catch (e: any) {
            showErrorModal(e.message ?? "Failed to fetch coupon - Server is unavailable at the moment");
            handleClose();
        }
    }

    const editCoupon = async () => {
        if (!coupon) return;
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    'id': coupon.id,
                    'name': coupon.name,
                    'product_id': coupon.product_id,
                    'enabled': coupon.enabled
                })
            }
            await fetch(props.environment + 'editCoupon', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Failed to update coupon");
                    }
                    return res.json();
                })
                .then(() => {
                    props.editedCoupon();
                    handleClose();
                });
        } catch (e: any) {
            showErrorModal(e.message ?? "Failed to update coupon - Server is unavailable at the moment");
        }
    }

    return (
        <>
            {show && coupon &&
                <Modal
                    show={show}
                    backdrop="static"
                    onHide={() => handleClose()}
                    keyboard={false}
                >
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        editCoupon();
                    }}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Coupon</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="dialog-data-field">
                                <span>Product:</span>
                                <select value={coupon.product_id} required
                                    onChange={(item) => setCoupon({ ...coupon, product_id: Number(item.target.value) })}
                                >
                                    <option value={LicenseType.Pro}>DOTE Pro</option>
                                    <option value={LicenseType.ProCommunity}>DOTE Pro Community</option>
                                    <option value={LicenseType.ProCommunityUpgrade}>DOTE Pro Community Upgrade</option>
                                    {/* <option value={LicenseType.SiteLicense}>Site-License</option> */}
                                </select>
                            </div>
                            <div className="dialog-data-field">
                                <span>Name:</span>
                                <input placeholder="Write coupon name here..." value={coupon.name} required
                                    onChange={(item) => setCoupon({ ...coupon, name: item.target.value })}
                                />
                            </div>
                            <div className="dialog-data-field">
                                <span>Enabled:</span>
                                <Switch checked={coupon.enabled} onChange={(item) => setCoupon({ ...coupon, enabled: item.target.checked })} />
                            </div>
                            <div className="text-end-container">
                                <EnabledLabel enabled={coupon.enabled} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => handleClose()}>
                                Close
                            </Button>
                            <Button variant="primary" type='submit'>Save</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            }
        </>
    );
}