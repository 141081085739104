import { useEffect, useState, useContext } from 'react';
import { Table, Container, Button } from 'react-bootstrap';
import { DeleteIcon } from '@fluentui/react-icons-mdl2';
import { ResponseStatus, getAPIroot } from '../../collector';
import { ErrorContext } from '../../context/ErrorContext';
import { AuthContext } from '../../context/AuthContext';
import ChangePasswordModal from './modals/ChangePassword/ChangePassword';
import { ConfirmModal } from '../../modals/ConfirmModal';
import ActionBar from '../../components/ActionBar/ActionBar';
import CreateAdminModal from './modals/CreateAdmin/CreateAdmin';
import LoadingPlaceholder from '../../components/LoadingPlaceholder';

interface AdminUser {
    username: string;
    name: string;
    createdDate: number;
    lastLogin: number;
}



export default function AdminsPage() {
    const [admins, setAdmins] = useState<AdminUser[]>([]);
    const [adminElements, setAdminElements] = useState<JSX.Element[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { showErrorModal } = useContext(ErrorContext);
    const { token, logout } = useContext(AuthContext);
    const [deleteAdminUsername, setDeleteAdminUsername] = useState<string | null>(null);

    useEffect(() => {
        fetchAdmins();
    }, []);

    useEffect(() => {
        createAdminElements();
    }, [JSON.stringify(admins)]);

    const createAdminElements = () => {
        if (admins.length === 0) {
            setAdminElements([]);
            return;
        }
        let elements = admins.map((item, idx) => {
            return (
                <tr key={idx}>
                    <td>{item.username}</td>
                    <td>{item.name}</td>
                    <td>{new Date(item.createdDate).toLocaleString()}</td>
                    <td>{new Date(item.lastLogin).toLocaleString()}</td>
                    <td>
                        <ChangePasswordModal
                            adminUsername={item.username}
                        />
                        <Button variant="danger" size="sm" onClick={(e) => setDeleteAdminUsername(item.username)}>
                            <DeleteIcon />
                        </Button>
                    </td>
                </tr>
            )
        }
        );
        setAdminElements(elements);
    }

    const fetchAdmins = async () => {
        setIsLoading(true);
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer' + token(),
                },
            }
            await fetch(getAPIroot() + 'get_all_admins', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw Error;
                    }
                    return res.json();
                })
                .then(({ admins, status }: { admins: AdminUser[], status: ResponseStatus }) => {
                    if (status === ResponseStatus.INVALID_AUTH_TOKEN) logout(true);
                    setAdmins(admins);
                });
        } catch (e: any) {
            showErrorModal(e.message ?? "Failed to fetch admins - Server is unavailable at the momment");
        }
        setIsLoading(false);
    }

    const deleteAdmin = async (username: string) => {
        setIsLoading(true);
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer' + token(),
                },
                body: JSON.stringify({
                    'username': username
                })
            }
            await fetch(getAPIroot() + 'delete_admin', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw Error("Failed to delete admin");
                    }
                    return res.json();
                })
                .then(({ status }) => {
                    if (status === ResponseStatus.INVALID_AUTH_TOKEN) logout(true);
                    fetchAdmins();
                });
        } catch (e: any) {
            showErrorModal(e.message ?? "Failed to delete admin - Server is unavailable at the moment");
        }
        setIsLoading(false);
    }

    return (
        <Container>
            {deleteAdminUsername &&
                <ConfirmModal
                    confirm={() => {
                        deleteAdmin(deleteAdminUsername);
                        setDeleteAdminUsername(null);
                    }}
                    message={['Are you sure you want to delete this admin?', 'This action can not be undone']}
                    show={deleteAdminUsername !== null}
                    cancel={() => setDeleteAdminUsername(null)}
                />
            }
            <ActionBar
                reloadCallback={() => fetchAdmins()}
                title='Administrators'
                isLoading={isLoading}
            >
                <CreateAdminModal createdAdmin={() => fetchAdmins()} />
            </ActionBar>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Username:</th>
                        <th>Name:</th>
                        <th>Created Date:</th>
                        <th>Last Login:</th>
                        <th style={{ maxWidth: '50px' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {!isLoading && adminElements}
                </tbody>
            </Table>
            {isLoading && <LoadingPlaceholder />}
        </Container>
    )
}
