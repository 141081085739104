import { useEffect, useState, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { getAPIroot } from "../../../../collector";
import { AuthContext } from "../../../../context/AuthContext";
import { ErrorContext } from "../../../../context/ErrorContext";

enum ValidationTypes {
	SUCCESS = 0,
	NEW_PASSWORD_DONT_MATCH = 1,
	FAILED = 2,
	NEW_EQUAL_OLD = 23,
	PASSWORD_INCORRECT = 24,
}

interface ChangePasswordProps {
	adminUsername: string;
}

export default function ChangePasswordModal(props: ChangePasswordProps) {

	const [show, setShow] = useState<boolean>(false);
	const [oldPassword, setOldPassword] = useState<string>('');
	const [newPassword, setNewPassword] = useState<string>('');
	const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>('');
	const [validation, setValidation] = useState<ValidationTypes>(0);
	const [errorElement, setErrorElement] = useState<JSX.Element>(<></>);
	const { token } = useContext(AuthContext);
	const { showErrorModal } = useContext(ErrorContext);

	useEffect(() => {
		switch (validation) {
			case ValidationTypes.SUCCESS:
				setErrorElement(<></>);
				break;
			case ValidationTypes.NEW_PASSWORD_DONT_MATCH:
				setErrorElement(<span className='error-txt'>*The new password does not match...</span>);
				break;
			case ValidationTypes.NEW_EQUAL_OLD:
				setErrorElement(<span className='error-txt'>*New password can not be the same as the old password...</span>);
				break;
			case ValidationTypes.PASSWORD_INCORRECT:
				setErrorElement(<span className='error-txt'>*Current password is incorrect...</span>);
				break;
			case ValidationTypes.FAILED:
				setErrorElement(<span className='error-txt'>*Something went wrong...</span>);
				break;
			default:
				break;
		}
	}, [validation]);

	const handleHideModal = () => {
		setOldPassword('');
		setNewPassword('');
		setNewPasswordConfirm('');
		setValidation(0);
		setErrorElement(<></>);
		setShow(false);
	}

	const changePassword = async () => {
		try {
			const requestOptions = {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer' + token(),
				},
				body: JSON.stringify({
					'username': props.adminUsername,
					'password': oldPassword,
					'newPassword': newPassword
				})
			}
			await fetch(getAPIroot() + 'change_admin_password', requestOptions)
				.then((res) => {
					if (!res.ok) {
						throw Error;
					}
					return res.json();
				})
				.then((data: { status: number }) => {
					if (data.status === ValidationTypes.NEW_EQUAL_OLD) {
						setValidation(ValidationTypes.NEW_EQUAL_OLD);
					}
					else if (data.status === ValidationTypes.PASSWORD_INCORRECT) {
						setValidation(ValidationTypes.PASSWORD_INCORRECT);
					}
					else if (data.status === ValidationTypes.SUCCESS) {
						handleHideModal();
					}
					else {
						setValidation(ValidationTypes.FAILED);
					}
				});
		} catch (error) {
			showErrorModal("Failed to change password due to a server error");
		}
	}

	return (
		<>
			<Button variant='outline-dark' size='sm' style={{ marginRight: '3px' }} onClick={() => setShow(true)}>
				Change Password
			</Button>
			{show &&
				<Modal
					show={show}
					onHide={() => setShow(false)}
					backdrop="static"
					keyboard={false}
				>
					<Modal.Header closeButton>
						<Modal.Title>Change Password</Modal.Title>
					</Modal.Header>
					<form onSubmit={(e) => {
						e.preventDefault();
						if (newPassword !== newPasswordConfirm) {
							setValidation(ValidationTypes.NEW_PASSWORD_DONT_MATCH);
							return;
						}
						changePassword();
					}}>
						<Modal.Body>
							<div className='dialog-data-field' style={{ marginBottom: '25px' }}>
								<span>Current Password:</span>
								<input
									type="password"
									value={oldPassword}
									onChange={(item) => setOldPassword(item.target.value)}
									required
									autoComplete='off'
								/>
							</div>
							<div className='dialog-data-field'>
								<span>New Password:</span>
								<input
									type="password"
									value={newPassword}
									onChange={(item) => setNewPassword(item.target.value)}
									required
									autoComplete='off'
								/>
							</div>
							<div className='dialog-data-field'>
								<span>Confirm new Password:</span>
								<input
									type="password"
									value={newPasswordConfirm}
									onChange={(item) => setNewPasswordConfirm(item.target.value)}
									required
									autoComplete='off'
								/>
							</div>
							{errorElement}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={() => setShow(false)}>
								Close
							</Button>
							<Button variant="primary" type='submit'>Confirm</Button>
						</Modal.Footer>
					</form>
				</Modal>
			}
		</>

	);
}
