import { useState, useEffect, useContext } from "react";
import { ErrorContext } from "../../../context/ErrorContext";
import { AuthContext } from "../../../context/AuthContext";
import { Button, Table } from "react-bootstrap";
import PaginationPartial from "../../../components/pagination/pagination";
import { DeleteIcon, EditIcon, RefreshIcon } from "@fluentui/react-icons-mdl2";
import { BulkCoupon, LOCAL_WS_API_ROOT, PRODSERVER_WS_API_ROOT, TESTSERVER_WS_API_ROOT, getEdition, getWSEnvironment } from "../../../collector";
import CreateBulkCouponModal from "../modals/CreateBulkCoupon";
import { ConfirmModal } from "../../../modals/ConfirmModal";
import EditBulkCouponModal from "../modals/EditBulkCoupon";
import ActionBar from "../../../components/ActionBar/ActionBar";
import LoadingPlaceholder from "../../../components/LoadingPlaceholder";
import EnabledLabel from "../../../components/EnabledLabel";

interface BulkCouponsViewProps {
    environment: string;
}

export default function BulkCouponsView(props: BulkCouponsViewProps) {

    const [bulkCoupons, setBulkCoupons] = useState<BulkCoupon[]>([]);
    const [bulkCouponElements, setBulkCouponElements] = useState<JSX.Element[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [maxPages, setMaxPages] = useState<number>(0);
    const [bulkCouponsPerPage, setBulkCouponsPerPage] = useState<number>(20);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [deleteBulkCouponId, setDeleteBulkCouponId] = useState<number | null>(null);
    const [editBulkCouponId, setEditBulkCouponId] = useState<number | null>(null);

    const { showErrorModal } = useContext(ErrorContext);
    const { token, invalidWSToken } = useContext(AuthContext);

    useEffect(() => {
        fetchBulkCoupons();
    }, [props.environment])

    useEffect(() => {
        setMaxPages(Math.ceil(bulkCoupons.length / bulkCouponsPerPage));
        createBulkCouponElements();
    }, [JSON.stringify(bulkCoupons), currentPage, bulkCouponsPerPage]);

    const createBulkCouponElements = () => {
        let elements: JSX.Element[] = [];
        if (bulkCoupons.length > 0) {
            elements = bulkCoupons
                .sort((a, b) => a.id - b.id)
                .slice(currentPage * bulkCouponsPerPage, (currentPage + 1) * bulkCouponsPerPage)
                .map((currentBulkCoupon, index) => {
                    return (
                        <tr key={index}>
                            <td>{currentBulkCoupon.delimiter}</td>
                            <td>{getEdition(currentBulkCoupon.productId)}</td>
                            <td>{currentBulkCoupon.code}</td>
                            <td>{currentBulkCoupon.discount}%</td>
                            <td>{currentBulkCoupon.usedAmount} of {currentBulkCoupon.maxUses}</td>
                            <td><EnabledLabel enabled={currentBulkCoupon.enabled} /></td>
                            <td onClick={(e) => e.preventDefault()}>
                                <Button variant='outline-dark' size='sm' style={{ marginRight: '3px' }} onClick={() => setEditBulkCouponId(currentBulkCoupon.id)}>
                                    <EditIcon />
                                </Button>
                                <Button variant="danger" size="sm" onClick={(e) => setDeleteBulkCouponId(currentBulkCoupon.id)}>
                                    <DeleteIcon />
                                </Button>
                            </td>
                        </tr>
                    )
                }
                );
        }
        setBulkCouponElements(elements);
    }

    const fetchBulkCoupons = async () => {
        setIsLoading(true);
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                }
            }
            await fetch(props.environment + 'getBulkCoupons', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw Error("Failed to fetch bulk coupons from " + getWSEnvironment(props.environment));
                    }
                    return res.json();
                })
                .then(({ bulkCoupons }) => {
                    setBulkCoupons([...bulkCoupons]);
                });
        } catch (e: any) {
            setBulkCoupons([]);
            showErrorModal(e.message ?? "Can't access the " + getWSEnvironment(props.environment) + ' database.\nTry to log in again.');
        }
        setIsLoading(false);
    }

    const deleteBulkCoupon = async (id: number) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    'id': id
                })
            }
            await fetch(props.environment + 'deleteCoupon', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw Error("Failed to delete bulk bulk coupon from server");
                    }
                    return res.json();
                })
                .then(() => {
                    fetchBulkCoupons();
                });
        } catch (error: any) {
            showErrorModal(error ?? "Failed to delete bulk coupon - Server is unavailable at the moment");
        }
    }

    return (
        <>
            <ActionBar
                isLoading={isLoading}
                reloadCallback={() => fetchBulkCoupons()}
                title="Bulk Coupons"
            >
                <CreateBulkCouponModal
                    environment={props.environment}
                    createdBulkCoupon={() => fetchBulkCoupons()}
                />
            </ActionBar>
            {invalidWSToken(props.environment) &&
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <h5 style={{ color: 'red' }}>Could not connect to the database, try to log in again</h5>
                </div>}
            {!invalidWSToken(props.environment) &&
                <>
                    <Table striped bordered hover size="md">
                        <thead>
                            <tr>
                                <th>Delimiter:</th>
                                <th>Product:</th>
                                <th>Code:</th>
                                <th>Discount:</th>
                                <th>Used:</th>
                                <th>Enabled:</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isLoading && bulkCouponElements}
                        </tbody>
                    </Table>
                    {isLoading && <LoadingPlaceholder />}
                    <PaginationPartial
                        currentPage={currentPage}
                        maxPages={maxPages}
                        viewAmount={bulkCouponsPerPage}
                        changePage={(newPage: number) => setCurrentPage(newPage)}
                        changeViewAmount={(newAmount: number) => {
                            setBulkCouponsPerPage(newAmount);
                            setMaxPages(Math.ceil(bulkCoupons.length / newAmount));
                            setCurrentPage(0);
                        }}
                        viewAmountPossible={[10, 20, 50, 100]}
                    />
                </>
            }
            {deleteBulkCouponId &&
                <ConfirmModal
                    confirm={() => {
                        deleteBulkCoupon(deleteBulkCouponId);
                        setDeleteBulkCouponId(null);
                    }}
                    message={['Are you sure you want to delete this bulk coupon?', 'This action can not be undone']}
                    show={deleteBulkCouponId !== null}
                    cancel={() => setDeleteBulkCouponId(null)}
                />
            }
            {editBulkCouponId &&
                <EditBulkCouponModal
                    environment={props.environment}
                    editedBulkCoupon={() => {
                        fetchBulkCoupons();
                        setEditBulkCouponId(null);
                    }}
                    bulkCouponId={editBulkCouponId}
                    close={() => setEditBulkCouponId(null)}
                />
            }
        </>
    )
}