import { useContext, useEffect, useState } from "react";
import { CouponCode } from "../../../collector";
import { ErrorContext } from "../../../context/ErrorContext";
import { AuthContext } from "../../../context/AuthContext";
import { Button, Form, Modal } from "react-bootstrap";
import Switch from "../../../components/Switch/Switch";
import EnabledLabel from "../../../components/EnabledLabel";


interface EditCouponCodeProps {
    editedCode: () => void;
    couponCodeId: number | null;
    environment: string;
    close: () => void;
}

export default function EditCouponCodeModal(props: EditCouponCodeProps) {

    const [isStatic, setIsStatic] = useState<boolean>(false);
    const [zeroFields, setZeroFields] = useState<boolean>(false);
    const [uniqueCode, setUniqueCode] = useState<boolean | null>(null);
    const [validRegex, setValidRegex] = useState<boolean | null>(null);

    const [code, setCode] = useState<CouponCode | null>(null);
    const [oldFields, setOldFields] = useState<string[]>([]);
    const [show, setShow] = useState<boolean>(false);
    const { showErrorModal } = useContext(ErrorContext);
    const { token } = useContext(AuthContext);

    useEffect(() => {
        if (props.couponCodeId) {
            fetchCode();
            handleShow();
        }
    }, [props.couponCodeId]);

    useEffect(() => {
        if (uniqueCode) {
            editCode();
        }
    }, [uniqueCode]);

    const handleClose = () => {
        setIsStatic(false);
        setUniqueCode(null);
        setZeroFields(false);
        setValidRegex(null);
        setCode(null);
        setOldFields([]);
        props.close();
        setShow(false);
    }
    const handleShow = () => setShow(true);

    const checkField = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!code) return;
        let fields = code.fields;
        if (fields.includes(e.target.value)) {
            setCode({ ...code, fields: code.fields.filter((x) => x !== e.target.value) });
        }
        else {
            setCode({ ...code, fields: [...code.fields, e.target.value] });
        }
    }
    const checkIfRegexIsValid = (): boolean => {
        try {
            if (!code) return false;
            let parts = code.code.slice(6).split('/');
            let regex = code.code.slice(6);
            let options = "";
            if (parts.length > 1) {
                regex = parts[1];
                options = parts[2];
            }
            new RegExp(regex, options);
            return true;
        }
        catch (e) {
            return false;
        }
    }

    const editCode = async () => {
        if (!code) return;
        if (code.code === '') return;
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    'id': code.id,
                    'code': code.code.toLowerCase(),
                    'regex': code.regex,
                    'enabled': code.enabled,
                    'fields': code.fields
                })
            }
            await fetch(props.environment + 'editCode', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Failed to update code");
                    }
                    return res.json();
                })
                .then(() => {
                    props.editedCode();
                    handleClose();
                });
        } catch (e: any) {
            showErrorModal(e.message ?? "Failed to update code - Server is unavailable at the moment");
        }
    }

    const fetchCode = async () => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    'id': props.couponCodeId
                })
            }
            await fetch(props.environment + 'getCode', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Failed to fetch code from server");
                    }
                    return res.json();
                })
                .then(({ code }: { code: CouponCode }) => {
                    setCode(code);
                    setIsStatic(code.fields[0] === 'static:staticCode');
                    setOldFields([...code.fields]);
                });
        } catch (e: any) {
            showErrorModal(e.message ?? "Failed to fetch code - Server is unavailable at the moment");
            handleClose()
        }
    }

    const checkCodeIsUnique = async () => {
        if (!code) return;
        if (code.code === '') return;
        if (code.regex) {
            if (!checkIfRegexIsValid()) {
                setValidRegex(false);
                return;
            }
        }
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    'coupon_id': code.coupon_id,
                    'code': code.code,
                    'id': code.id,
                })
            }
            await fetch(props.environment + 'checkUniqueCode', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Failed to check code uniqueness");
                    }
                    return res.json();
                })
                .then(({ exist }) => {
                    setUniqueCode(!exist);
                    setZeroFields(false);
                });
        } catch (e: any) {
            showErrorModal(e.message ?? "Failed to check code uniqueness - Server is unavailable at the moment");
        }
    }

    return (
        <>
            {show && code &&
                <Modal
                    show={show}
                    backdrop="static"
                    onHide={() => handleClose()}
                    keyboard={false}
                >
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        if (code.fields.length >= 1) checkCodeIsUnique();
                        else setZeroFields(true);
                    }}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Code</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="dialog-data-field">
                                <span>Fields:</span>
                                <div style={{ display: 'flex', columnGap: '3px', justifyContent: 'flex-end' }}>
                                    <Button style={{ opacity: (isStatic ? '1' : '0.5') }} onClick={() => {
                                        if (!isStatic) {
                                            setIsStatic(true);
                                            setCode({ ...code, fields: ['static:staticCode'] });
                                        }
                                    }}>
                                        Static
                                    </Button>
                                    <Button style={{ opacity: (!isStatic ? '1' : '0.5') }} onClick={() => {
                                        if (isStatic) {
                                            setIsStatic(false);
                                            setCode({ ...code, fields: [...oldFields] });
                                        }
                                    }}>
                                        Multiple
                                    </Button>
                                </div>
                            </div>
                            {!isStatic &&
                                <div style={{ marginBottom: '10px' }}>
                                    <div className="d-flex" style={{ justifyContent: "space-evenly", marginBottom: '10px' }}>
                                        <div>
                                            <Form.Label>License Details:</Form.Label>
                                            <Form.Check type={'checkbox'} onChange={checkField} checked={code.fields.includes('license-details:firstname')} value={'license-details:firstname'} label={'Firstname'} />
                                            <Form.Check type={'checkbox'} onChange={checkField} checked={code.fields.includes('license-details:lastname')} value={'license-details:lastname'} label={'Lastname'} />
                                            <Form.Check type={'checkbox'} onChange={checkField} checked={code.fields.includes('license-details:email')} value={'license-details:email'} label={'Email'} />
                                            <Form.Check type={'checkbox'} onChange={checkField} checked={code.fields.includes('license-details:affiliation')} value={'license-details:affiliation'} label={'Affiliation'} />
                                        </div>
                                        <div>
                                            <Form.Label>Customer Details:</Form.Label>
                                            <Form.Check type={'checkbox'} onChange={checkField} checked={code.fields.includes('customer-details:firstname')} value={'customer-details:firstname'} label={'Firstname'} />
                                            <Form.Check type={'checkbox'} onChange={checkField} checked={code.fields.includes('customer-details:lastname')} value={'customer-details:lastname'} label={'Lastname'} />
                                            <Form.Check type={'checkbox'} onChange={checkField} checked={code.fields.includes('customer-details:email')} value={'customer-details:email'} label={'Email'} />
                                            <Form.Check type={'checkbox'} onChange={checkField} checked={code.fields.includes('customer-details:address')} value={'customer-details:address'} label={'Address'} />
                                            <Form.Check type={'checkbox'} onChange={checkField} checked={code.fields.includes('customer-details:postal')} value={'customer-details:postal'} label={'Postal'} />
                                            <Form.Check type={'checkbox'} onChange={checkField} checked={code.fields.includes('customer-details:city')} value={'customer-details:city'} label={'City'} />
                                        </div>
                                    </div>
                                    {zeroFields && <span className="dialog-data-field" style={{ color: 'red' }}>Please choose at least one field affected by the code!</span>}
                                </div>}
                            <div className="dialog-data-field">
                                <span>Code:</span>
                                <input placeholder="Write code here..." value={code.code} required
                                    onChange={(item) => {
                                        let temp = { ...code };
                                        temp.code = item.target.value.toLowerCase();
                                        temp.regex = item.target.value.toLowerCase().startsWith('regex:');
                                        setCode(temp);
                                    }}
                                />
                            </div>
                            <span><em>To use regex, start with 'regex:' no spaces. (Ex: regex:/.*@student.aau.dk$/)</em></span>
                            {validRegex === false && <span style={{ color: "red" }}><br />Regex is invalid</span>}
                            {uniqueCode === false && <span style={{ color: "red" }}><br />Code must be unique for this coupon!</span>}
                            <div className="dialog-data-field">
                                <span>Enabled:</span>
                                <Switch checked={code.enabled} onChange={(item) => setCode({ ...code, enabled: item.target.checked })} />
                            </div>
                            <div className="text-end-container">
                                <EnabledLabel enabled={code.enabled} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => handleClose()}>
                                Close
                            </Button>
                            <Button variant="primary" type='submit'>Save</Button>
                        </Modal.Footer>
                    </form>
                </Modal >
            }
        </>
    );
}