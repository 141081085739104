import { useContext, useState } from "react";
import { RedeemCode } from "../RedeemCodesView/RedeemCodesView";
import { AuthContext } from "../../../context/AuthContext";
import { ErrorContext } from "../../../context/ErrorContext";
import { Button, Modal } from "react-bootstrap";
import { LicenseType } from "../../../collector";
import Switch from "../../../components/Switch/Switch";
import EnabledLabel from "../../../components/EnabledLabel";


interface CreateRedeemCodeProps {
    createdRedeemCode: () => void;
    environment: string;
}

export default function CreateRedeemCodeModal(props: CreateRedeemCodeProps) {

    const [redeemCode, setRedeemCode] = useState<RedeemCode>({
        id: 0,
        code: '',
        uses: 0,
        maxUses: 0,
        orderId: null,
        enabled: false,
        productId: 1,
        created_at: null
    });
    const [autoGenerateCode, setAutoGenerateCode] = useState<boolean>(false);
    const [uniqueCode, setUniqueCode] = useState<boolean>(true);
    const [show, setShow] = useState<boolean>(false);
    const { showErrorModal } = useContext(ErrorContext);
    const { token, invalidWSToken } = useContext(AuthContext);

    const handleClose = () => {
        setRedeemCode({
            id: 0,
            code: '',
            uses: 0,
            maxUses: 0,
            orderId: null,
            enabled: false,
            productId: 1,
            created_at: null
        });
        setAutoGenerateCode(false);
        setUniqueCode(true);
        setShow(false);
    }
    const handleShow = () => setShow(true);

    const checkRedeemCode = async () => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    'code': redeemCode.code,
                    'id': 0
                })
            }
            await fetch(props.environment + 'checkRedeemCode', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Failed to check if code is unique");
                    }
                    return res.json();
                })
                .then(({ exists }) => {
                    exists ? setUniqueCode(false) : createRedeemCode();
                });
        } catch (e: any) {
            showErrorModal(e.message ?? "Failed to check if code is unique- Server is unavailable at the moment");
        }
    }

    const createRedeemCode = async () => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    'code': redeemCode.code,
                    'productId': redeemCode.productId,
                    'enabled': redeemCode.enabled,
                    'maxUses': redeemCode.maxUses,
                    'autoGenerateCode': autoGenerateCode,
                })
            }
            await fetch(props.environment + 'createRedeemCode', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Failed to create redeem code");
                    }
                    return res.json();
                })
                .then(() => {
                    props.createdRedeemCode();
                    handleClose();
                });
        } catch (e: any) {
            showErrorModal(e.message ?? "Failed to create redeem code - Server is unavailable at the moment");
        }

    }

    return (
        <>
            <Button variant='outline-dark' disabled={invalidWSToken(props.environment)} onClick={() => handleShow()}>
                Create Redeem Code
            </Button>
            {show &&
                <Modal
                    show={show}
                    backdrop="static"
                    onHide={() => handleClose()}
                    keyboard={false}
                >
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        autoGenerateCode ? createRedeemCode() : checkRedeemCode();
                    }}>
                        <Modal.Header closeButton>
                            <Modal.Title>Create Redeem Code</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="dialog-data-field">
                                <span>Product:</span>
                                <select value={redeemCode.productId} required
                                    onChange={(item) => setRedeemCode({ ...redeemCode, productId: Number(item.target.value) })}
                                >
                                    <option value={LicenseType.Pro}>DOTE Pro</option>
                                    <option value={LicenseType.ProCommunity}>DOTE Pro Community</option>
                                    <option value={LicenseType.SiteLicense}>Site-License</option>
                                </select>
                            </div>
                            <div className="dialog-data-field">
                                <span>Max uses:</span>
                                <input type={'number'} min={0} value={redeemCode.maxUses} required
                                    onChange={(item) => setRedeemCode({ ...redeemCode, maxUses: Number(item.target.value) })}
                                />
                            </div>
                            <div className="dialog-data-field" style={{ alignItems: 'center' }}>
                                <span style={{ alignSelf: 'flex-start' }}>Code:</span>
                                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}>
                                    <div style={{ display: 'flex', columnGap: '3px', justifyContent: 'flex-end' }}>
                                        <Button
                                            style={{ opacity: (autoGenerateCode ? '1' : '0.5') }}
                                            onClick={() => setAutoGenerateCode(!autoGenerateCode)}
                                        >
                                            Auto generate
                                        </Button>
                                    </div>
                                    {!autoGenerateCode &&
                                        <input placeholder="Write code here..." value={redeemCode.code} required
                                            onChange={(item) => setRedeemCode({ ...redeemCode, code: item.target.value.toLowerCase() })}
                                        />
                                    }
                                </div>
                            </div>
                            {!uniqueCode && <p style={{ color: 'red' }}>Code must be unique, please enter a valid code</p>}
                            <div className="dialog-data-field">
                                <span>Enabled:</span>
                                <Switch checked={redeemCode.enabled} onChange={(item) => setRedeemCode({ ...redeemCode, enabled: item.target.checked })} />
                            </div>
                            <div className="text-end-container">
                                <EnabledLabel enabled={redeemCode.enabled} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => handleClose()}>
                                Close
                            </Button>
                            <Button variant="primary" type='submit'>Create Redeem Code</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            }
        </>
    );
}