import { guardOfNeverland } from "./utils";

export const SERVER_API_ROOT = "https://license.dote.aau.dk/api/";
export const LOCAL_API_ROOT = "http://localhost:3001/api/";

export function getAPIroot(): string {
    return SERVER_API_ROOT;
}

export const LOCAL_WS_API_ROOT = 'http://dote/api/';
export const TESTSERVER_WS_API_ROOT = 'https://dote.sfx.aau.dk/api/';
export const PRODSERVER_WS_API_ROOT = 'https://www.dote.aau.dk/api/';
export function isLocal() {
    return false;
}

export interface LicenseStats {
    aauStudentLicenses: number,
    aauStaffLicenses: number,
    aauDepartmentLicenses: { department: string, amount: number }[],
    aauLicenses: number,
    proLicenses: number,
    proCommunityLicenses: number,
    expiredLicenses: number,
    revokedLicenses: number,
    nYearsLicenses: { years: number, amount: number }[],
    nDevicesActivated: { amount: number, deviceCount: number }[],
}

export interface SiteLicenseOrder {
    createdAt: Date;
    orderId: number;
    licenseOrderId: number;
    buyAmount: number;
    email: string;
    address: string;
    address2?: string | null;
    postal: string;
    city: string;
    country: string;
    companyName: string;
    departmentName: string;
    eanNumber: string;
    cvrNumber?: string | null;
    language: string;
    contactFirstname: string;
    contactLastname: string;
    contactEmail: string;
    status: string;
}

export interface Customer {
    email: string;
    firstname: string;
    lastname: string;
    city: string;
    country: string;
    postal: string;
    address: string;
    address2?: string;
    vat_number?: string;
    department?: string;
    company?: string;
    your_reference?: string;
}
export interface Order {
    id: number;
    createdAt: Date;
    orderPlacedAt?: Date;
    product: LicenseType;
    quantity: number;
    originalUnitPrice: string;
    rateType: string | null;
    rateValue: string | null;
    savingPerItem: number | null;
    customer: Customer;
    paymentType: string | null;
    status: string | null;
    couponUsed: string | null;
    codeUsed: string | null;
}

export interface BulkCoupon {
    id: number;
    delimiter: number;
    productId: number;
    code: string;
    maxUses: number;
    usedAmount: number;
    discount: number;
    enabled: boolean;
}

export interface Coupon {
    id: number;
    name: string;
    enabled: boolean;
    product_id: number;
    couponable_type: string;
    couponable_id: number;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date | null;
}
export interface CouponCode {
    id: number;
    coupon_id: number;
    code: string;
    regex: boolean;
    fields: string[];
    enabled: boolean;
    created_at: Date;
    deleted_at: Date | null;
}
export interface CouponRate {
    id: number;
    coupon_id: number;
    order: number;
    start: string | null;
    end: string | null;
    enabled: boolean;
    max_uses: number; //0 is infinite
    type: CouponRateType;
    value: number;
    description: string;
    invoice_line: string;
    created_at: Date;
    updated_at: Date | null;
    deleted_at: Date | null;
    usedAmount: number | null;
}
export enum CouponRateType {
    Fixed = 'fixed',
    Percentage = '%'
}

export interface LicenseEntry {
    licensed_to: string;                      // aka. licensed_to
    name: string;
    affiliation?: string;
    key: string;
    issue_date: number;
    start_date: number;
    expire_date: number;
    persistent_license: boolean;
    revoked: boolean;
    device_limit: number;
    devices: string[];
    license_type: LicenseType;
}
export enum PanelType {
    USERS = 0,
    LICENSEKEYS = 1,
    DOTE_VERSIONS = 2,
    DISCOUNT = 3,
    BANNER = 4
}

// NOTE: these enum values are the same as the `product_id` in the webshop database storing our 'products'
export enum LicenseType {
    NotFound = -1,
    Unlicensed = 0,         // Free version
    Pro = 1,
    ProCommunity = 2,
    AAU = 3,
    ProCommunityUpgrade = 4,
    SiteLicense = 5,
}

export enum ResponseStatus {
    SUCCESS = 0,
    BAD_REQUEST = 1,
    NULL_DATA = 2,
    NO_PERMISSION = 3,
    INVALID_INPUT = 4,
    SERVER_ERROR = 5,
    DUPLICATE_DATA = 6,
    INVALID_CREDENTIAL = 7,
    INVALID_CODE = 8,
    LOGIN_REQUIRED = 9,
    PENDING = 10,
    ACCOUNT_DISABLED = 11,
    FAILURE = 12,
    MAX_DEVICE_LIMIT_REACHED = 13,
    USERID_DOES_NOT_EXIST = 14,
    FEATURE_NOT_IMPLEMENTED = 15,
    LICENSE_KEY_INVALID = 16,
    LICENSE_KEY_REVOKED = 17,
    LICENSE_KEY_EXPIRED = 18,
    LICENSE_INVALID = 19,
    BAD_LICENSE_USER_ID_DOES_NOT_MATCH = 20,
    BAD_LICENSE_INCORRECT_KEY = 21,
    BAD_LICENSE_DEVICE_ID_MISSING = 22,
    NEW_PASSWORD_EQUAL_OLD_PASSWORD = 23,
    INCORRECT_PASSWORD = 24,
    INVALID_AUTH_TOKEN = 25,
};

export function getEdition(id: LicenseType) {
    switch (id) {
        case LicenseType.Unlicensed:
            return 'Unlicensed';
        case LicenseType.Pro:
            return 'Pro';
        case LicenseType.ProCommunity:
            return 'Pro Community';
        case LicenseType.AAU:
            return 'AAU';
        case LicenseType.ProCommunityUpgrade:
            return 'Pro Community Upgrade';
        case LicenseType.SiteLicense:
            return 'Site-License';
        case LicenseType.NotFound:
            console.warn(`Found 'Unknown' License-type`);
            return 'Unknown';
        default:
            guardOfNeverland(id);
            return 'Not set..'
    }

}

export enum Env {
    LOCAL = "Local",
    TEST = "Test",
    PRODUCTION = "Production"
}
export function getWebshopFetchPath(environment: Env) {
    switch (environment) {
        case Env.LOCAL:
            return LOCAL_WS_API_ROOT;
        case Env.TEST:
            return TESTSERVER_WS_API_ROOT;
        case Env.PRODUCTION:
            return PRODSERVER_WS_API_ROOT;
        default:
            return TESTSERVER_WS_API_ROOT;
    }
}

export function getWSEnvironment(path: string): string {
    switch (path) {
        case LOCAL_WS_API_ROOT:
            return 'LOCAL';
        case TESTSERVER_WS_API_ROOT:
            return "TEST";
        case PRODSERVER_WS_API_ROOT:
            return "PRODUCTION";
    }
    return 'UNDEFINED';
}