import './App.css';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Login from './pages/Login/Login';
import { AuthContext } from './context/AuthContext';
import { useAuth } from './hooks/useAuth';
import RequireAuth from './components/RequireAuth';
import LicensePage from './pages/Licenses/LicensePage';
import NavBar from './components/nav-bar/nav-bar';
import { ErrorContext } from './context/ErrorContext';
import { useError } from './hooks/useError';
import { ErrorModal } from './modals/ErrorModal';
import AdminsPage from './pages/Admins/Admins';
import DoteVersionsPage from './pages/Versions/Versions';
import BannerPage from './pages/Banners/Banners';
import DiscountPage from './pages/Discount/Discount';
import ErrorBoundary from './components/ErrorBoundary';
import Dashboard from './pages/Dashboard/Dashboard';
import OrdersPage from './pages/Orders/Orders';
import SiteLicenseOrdersPage from './pages/SiteLicenseOrders/SiteLicenseOrders';

function App() {
	const auth = useAuth();
	const error = useError();

	return (
		<AuthContext.Provider value={auth}>
			<ErrorContext.Provider value={error}>
				<div style={{ width: '100vw', height: '100vh', overflow: 'auto' }}>
					<Routes>
						<Route path='/login' element={<Login />} />
						<Route path='/' element={
							<ErrorBoundary invalidateAuthToken={() => auth.logout()}>
								<RequireAuth>
									<ErrorModal />
									<NavBar />
									<Outlet />
								</RequireAuth>
							</ErrorBoundary>
						}>
							<Route index element={<Dashboard />} />
							<Route path='/licenses' element={<LicensePage />} />
							<Route path='/site-license' element={<SiteLicenseOrdersPage />} />
							<Route path='/admins' element={<AdminsPage />} />
							<Route path='/versions' element={<DoteVersionsPage />} />
							<Route path='/banner' element={<BannerPage />} />
							<Route path='/discount' element={<DiscountPage />} />
							<Route path='/orders' element={<OrdersPage />} />
						</Route>
						<Route path='*' element={
							<RequireAuth>
								<Navigate to="/licenses" />
							</RequireAuth>
						} />
					</Routes>
				</div>
			</ErrorContext.Provider>
		</AuthContext.Provider>
	);
}

export default App;
