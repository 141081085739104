import React from 'react'
type LabelColor = "success" | "danger" | "warning";
interface Props {
    color: LabelColor;
    text: string;
}

export default function Label(props: Props) {
    const backgroundColors: Record<LabelColor, string> = {
        success: '#4CAF50', // Green
        danger: '#F44336',  // Red
        warning: '#FFC107' // Yellow
    };

    const labelStyle: React.CSSProperties = {
        backgroundColor: backgroundColors[props.color],
        color: 'white',
        padding: '4px 8px',
        borderRadius: '4px',
    };

    return (
        <span style={labelStyle}>{props.text}</span>
    );
}
