import React from 'react';
import Label from './Label';

interface Props {
    enabled: boolean;
}

export default function EnabledLabel(props: Props) {
    return (
        <Label color={props.enabled ? "success" : "danger"} text={props.enabled ? "Enabled" : "Disabled"} />
    )
}
