import { useContext, useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { CouponCode } from "../../../../collector";
import PaginationPartial from "../../../../components/pagination/pagination";
import { AuthContext } from "../../../../context/AuthContext";
import { ErrorContext } from "../../../../context/ErrorContext";
import EditCouponCodeModal from "../../modals/EditCouponCode";
import { DeleteIcon, EditIcon } from "@fluentui/react-icons-mdl2";
import { ConfirmModal } from "../../../../modals/ConfirmModal";
import LoadingPlaceholder from "../../../../components/LoadingPlaceholder";
import EnabledLabel from "../../../../components/EnabledLabel";

interface CouponCodesProps {
    couponId: number;
    environment: string;
    fetchCodes: () => void;
    codes: CouponCode[];
    isLoading: boolean;
}

export default function CouponCodes(props: CouponCodesProps) {

    const [codeElements, setCodeElements] = useState<JSX.Element[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [maxPages, setMaxPages] = useState<number>(0);
    const [codesPerPage, setCodesPerPage] = useState<number>(5);
    const { token } = useContext(AuthContext);
    const { showErrorModal } = useContext(ErrorContext);
    const [editingCouponCodeId, setEditingCouponCodeId] = useState<number | null>(null);
    const [deleteCouponCodeId, setDeleteCouponCodeId] = useState<number | null>(null);
    const [showDeleteAllCodes, setShowDeleteAllCodes] = useState<boolean>(false);

    useEffect(() => {
        setMaxPages(Math.ceil(props.codes.length / codesPerPage));
        createCodeElements();
    }, [JSON.stringify(props.codes), currentPage, codesPerPage]);

    const deleteCode = async (id: number) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    'id': id
                })
            }
            await fetch(props.environment + 'deleteCode', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Failed to delete code from server");
                    }
                    return res.json();
                })
                .then(() => {
                    props.fetchCodes();
                });
        } catch (e: any) {
            showErrorModal(e.message ?? "Failed to delete code - Server is unavailable at the moment");
        }
    }

    const deleteAllCodes = async () => {
        for (let i = 0; i < props.codes.length; i++) {
            await deleteCode(props.codes[i].id);
        }
        props.fetchCodes();
    }

    const createCodeElements = () => {
        let elements: JSX.Element[] = [];
        if (props.codes.length > 0) {
            elements = props.codes.sort((a, b) => a.id - b.id)
                .slice(currentPage * codesPerPage, (currentPage + 1) * codesPerPage)
                .map((currentCode, index) => {
                    return (
                        <tr key={index}>
                            <td>{currentCode.code}</td>
                            <td>{currentCode.regex ? 'True' : 'False'}</td>
                            <td><EnabledLabel enabled={currentCode.enabled} /></td>
                            <td>
                                <Button variant='outline-dark' size='sm' style={{ marginRight: '3px' }} onClick={() => setEditingCouponCodeId(currentCode.id)}>
                                    <EditIcon />
                                </Button>
                                <Button variant="danger" size="sm" onClick={(e) => setDeleteCouponCodeId(currentCode.id)}>
                                    <DeleteIcon />
                                </Button>
                            </td>
                        </tr>
                    )
                });
        }
        setCodeElements(elements);
    }

    return (
        <>
            <EditCouponCodeModal
                editedCode={() => props.fetchCodes()}
                environment={props.environment}
                couponCodeId={editingCouponCodeId}
                close={() => setEditingCouponCodeId(null)}
            />
            {deleteCouponCodeId &&
                <ConfirmModal
                    confirm={() => {
                        deleteCode(deleteCouponCodeId);
                        setDeleteCouponCodeId(null);
                    }}
                    message={['Are you sure you want to delete this code?', 'This action can not be undone']}
                    show={deleteCouponCodeId !== null}
                    cancel={() => setDeleteCouponCodeId(null)}
                />
            }
            {showDeleteAllCodes &&
                <ConfirmModal
                    confirm={() => {
                        deleteAllCodes();
                        setShowDeleteAllCodes(false);
                    }}
                    message={['Are you sure you want to delete all codes for this coupon?', 'This action can not be undone']}
                    show={showDeleteAllCodes}
                    cancel={() => setShowDeleteAllCodes(false)}
                />
            }
            <h3>
                Codes:
            </h3>
            <Table striped bordered hover size="md">
                <thead>
                    <tr>
                        <th>Code:</th>
                        <th>Regex:</th>
                        <th>Enabled:</th>
                        <th>
                            <Button variant="danger" size="sm" onClick={(e) => setShowDeleteAllCodes(true)}>
                                <DeleteIcon /> All
                            </Button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {!props.isLoading && codeElements}
                </tbody>
            </Table>
            {props.isLoading && <LoadingPlaceholder />}
            {props.codes.length > codesPerPage &&
                <PaginationPartial
                    currentPage={currentPage}
                    maxPages={maxPages}
                    viewAmount={codesPerPage}
                    changePage={(newPage: number) => setCurrentPage(newPage)}
                    changeViewAmount={(newAmount: number) => {
                        setCodesPerPage(newAmount);
                        setMaxPages(Math.ceil(props.codes.length / newAmount));
                        setCurrentPage(0);
                    }}
                    viewAmountPossible={[5, 10, 20, 50, 100]}
                />
            }
        </>
    )
}