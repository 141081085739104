import { CopyIcon, DownloadIcon } from '@fluentui/react-icons-mdl2';
import { LicenseEntry, getEdition } from '../../../collector';
import { Button, CloseButton, Container } from 'react-bootstrap';
import './OpenLicense.css';

interface OpenLicenseProps {
	selectedLicense: LicenseEntry | null;
	downloadKey: (userID: string) => void;
	closeOpenLicense: () => void;
}

export default function OpenLicense(props: OpenLicenseProps) {

	return (
		<>
			{props.selectedLicense === null ? <></> :
				<Container className='open-license-container'>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<div style={{ textAlign: "center", fontSize: "1.2rem", marginBottom: '15px', flexGrow: '1' }}>
							All information:
						</div>
						<CloseButton onClick={() => props.closeOpenLicense()} />
					</div>
					<div style={{ display: 'flex', flexWrap: 'wrap' }}>
						<div className='expanded-text-element' style={{ flexBasis: "100%" }}>
							<label><strong>Key:</strong></label>
							<div style={{ width: "100%", display: "flex" }}>
								<input type={"text"} value={props.selectedLicense.key} readOnly style={{ flexGrow: "1" }} />
								<Button variant='outline-dark' size='sm' style={{ margin: '0 3px' }} onClick={() => navigator.clipboard.writeText(props.selectedLicense!.key)}><CopyIcon /></Button>
								<Button variant='outline-dark' size='sm' onClick={() => props.downloadKey(props.selectedLicense!.licensed_to)}><DownloadIcon /></Button>
							</div>
						</div>
						<div className='expanded-text-element'>
							<label><strong>User ID:</strong></label>
							{props.selectedLicense.licensed_to}
						</div>
						<div className='expanded-text-element'>
							<label><strong>Name:</strong></label>
							{props.selectedLicense.name}
						</div>
						<div className='expanded-text-element'>
							<label><strong>Affiliation:</strong></label>
							{props.selectedLicense.affiliation ?? ""}
						</div>
						<div className='expanded-text-element'>
							<label><strong>Issue Date:</strong></label>
							{new Date(props.selectedLicense.issue_date).toLocaleString()}
						</div>
						<div className='expanded-text-element'>
							<label><strong>Start Date:</strong></label>
							{new Date(props.selectedLicense.start_date).toLocaleString()}
						</div>
						<div className='expanded-text-element'>
							<label><strong>Expiry Date:</strong></label>
							{new Date(props.selectedLicense.expire_date).toLocaleString()}
						</div>
						<div className='expanded-text-element'>
							<label><strong>Persistent License:</strong></label>
							{props.selectedLicense.persistent_license ? 'True' : 'False'}
						</div>
						<div className='expanded-text-element'>
							<label><strong>License Type:</strong></label>
							{getEdition(props.selectedLicense.license_type)}
						</div>
						<div className='expanded-text-element'>
							<label><strong>Revoked:</strong></label>
							{props.selectedLicense.revoked ? 'True' : 'False'}
						</div>
						<div className='expanded-text-element'>
							<label><strong>Device Limit:</strong></label>
							{props.selectedLicense.device_limit}
						</div>
						<div className='expanded-text-element'>
							<label><strong>Devices:</strong></label>
							{props.selectedLicense.devices.map((dev, idx) => {
								return <div key={idx}>{dev}</div>
							})}
						</div>
					</div>
				</Container>}
		</>
	)
}

