import { useContext, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { AuthContext } from "../../../context/AuthContext";
import { ErrorContext } from "../../../context/ErrorContext";
import { LicenseType } from "../../../collector";
import Switch from "../../../components/Switch/Switch";
import EnabledLabel from "../../../components/EnabledLabel";

interface CreateBulkCouponProps {
    createdBulkCoupon: () => void;
    environment: string;
}

export default function CreateBulkCouponModal(props: CreateBulkCouponProps) {

    const [discountPercentage, setDiscountPercentage] = useState<number>(0);
    const [delimiter, setDelimiter] = useState<number>(2);
    const [code, setCode] = useState<string>('');
    const [productType, setProductType] = useState<LicenseType>(LicenseType.Pro);
    const [enabled, setEnabled] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [autoGenerateCode, setAutoGenerateCode] = useState<boolean>(false);
    const [maxUses, setMaxUses] = useState<number>(0);

    const { showErrorModal } = useContext(ErrorContext);
    const { token, invalidWSToken } = useContext(AuthContext);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setDelimiter(2);
        setMaxUses(0);
        setDiscountPercentage(0);
        setProductType(LicenseType.Pro);
        setAutoGenerateCode(false);
        setEnabled(false);
    };

    const createBulkCoupon = async () => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    'delimiter': delimiter,
                    'value': discountPercentage,
                    'product_id': productType,
                    'enabled': enabled,
                    'max_uses': maxUses,
                    'autoGenerateCode': autoGenerateCode,
                    'code': code
                })
            }
            await fetch(props.environment + 'createBulkCoupon', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Failed to create bulk coupon");
                    }
                    return res.json();
                })
                .then(() => {
                    props.createdBulkCoupon();
                    handleClose();
                });
        } catch (error: any) {
            showErrorModal(error ?? "Failed to create bulk coupon - Server is unavailable at the moment");
        }
    }

    return (
        <>
            <Button variant='outline-dark' disabled={invalidWSToken(props.environment)} onClick={() => handleShow()}>
                Create Bulk Coupon
            </Button>
            {show && !invalidWSToken(props.environment) &&
                <Modal
                    show={show}
                    backdrop="static"
                    onHide={() => handleClose()}
                    keyboard={false}
                >
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        createBulkCoupon();
                    }}>
                        <Modal.Header closeButton>
                            <Modal.Title>Create Bulk Coupon</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="dialog-data-field">
                                <span>Product:</span>
                                <select value={productType} required onChange={(item) => setProductType(Number(item.target.value))} >
                                    <option value={LicenseType.Pro}>DOTE Pro</option>
                                    <option value={LicenseType.ProCommunity}>DOTE Pro Community</option>
                                    <option value={LicenseType.SiteLicense}>Site-License</option>
                                </select>
                            </div>
                            <div className="dialog-data-field">
                                <span>Delimiter:</span>
                                <input type="number" min={2} value={delimiter} required
                                    onChange={(item) => setDelimiter(Number(item.target.value))} />
                            </div>
                            <div className="dialog-data-field">
                                <span>Discount Value (%):</span>
                                <input min={0} max={99} type='number' value={discountPercentage} required
                                    onChange={(item) => setDiscountPercentage(Number(item.target.value))}
                                />
                            </div>
                            <div className="dialog-data-field">
                                <span>Max Uses (0 = infinite):</span>
                                <input min={0} type='number' value={maxUses} required
                                    onChange={(item) => setMaxUses(item.target.valueAsNumber)}
                                />
                            </div>
                            <div className="dialog-data-field" style={{ alignItems: 'center' }}>
                                <span style={{ alignSelf: 'flex-start' }}>Code:</span>
                                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}>
                                    <div style={{ display: 'flex', columnGap: '3px', justifyContent: 'flex-end' }}>
                                        <Button style={{ opacity: (autoGenerateCode ? '1' : '0.5') }} onClick={() => setAutoGenerateCode(!autoGenerateCode)} >
                                            Auto generate code
                                        </Button>
                                    </div>
                                    {!autoGenerateCode &&
                                        <input placeholder="Write code here..." value={code} required
                                            onChange={(item) => setCode(item.target.value.toLowerCase())}
                                        />
                                    }
                                </div>
                            </div>
                            <div className="dialog-data-field">
                                <span>Enabled:</span>
                                <Switch checked={enabled} onChange={(item) => setEnabled(item.target.checked)} />
                            </div>
                            <div className="text-end-container">
                                <EnabledLabel enabled={enabled} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => handleClose()}>
                                Close
                            </Button>
                            <Button variant="primary" type='submit'>Create Bulk Coupon</Button>
                        </Modal.Footer>
                    </form>
                </Modal>}
        </>
    );
}
