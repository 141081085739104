import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

interface DateRangeProps {
    dateRangeHasBeenSet: ([startDate, endDate]: [Date | null, Date | null]) => void;
    label?: string;
}

export default function DateRange(props: DateRangeProps) {
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
    const [startDate, endDate] = dateRange;

    useEffect(() => {
        if (startDate) {
            if (endDate) {
                props.dateRangeHasBeenSet(dateRange);
            }
        }
        else {
            props.dateRangeHasBeenSet(dateRange);
        }
    }, [startDate, endDate]);

    return (
        <div style={{ display: 'flex', alignSelf: 'flex-start', justifyContent: 'center', alignItems: 'center', height: "100%" }}>
            {props.label &&
                <span style={{ whiteSpace: 'nowrap', marginRight: '4px' }}>{props.label}</span>
            }
            <ReactDatePicker
                showWeekNumbers
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                dateFormat={"dd/MM-yyyy"}
                onChange={(update) => {
                    setDateRange(update);
                }}
                placeholderText='Date range...'
                showMonthDropdown
                showYearDropdown
                maxDate={new Date()}
                minDate={new Date("2021")}
            />
            {startDate && endDate &&
                <Button style={{ marginLeft: '5px', height: '27px' }} size='sm' variant='outline-danger' onClick={() => setDateRange([null, null])}>Clear</Button>
            }
        </div>
    );
}
