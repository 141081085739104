import { useContext, useEffect, useState } from "react";
import { Coupon, LOCAL_WS_API_ROOT, PRODSERVER_WS_API_ROOT, TESTSERVER_WS_API_ROOT, getEdition } from "../../../collector";
import { AuthContext } from "../../../context/AuthContext";
import { ErrorContext } from "../../../context/ErrorContext";
import { Button, Table } from "react-bootstrap";
import PaginationPartial from "../../../components/pagination/pagination";
import { getWSEnvironment } from "../../../collector";
import EditCouponModal from "../modals/EditCoupon";
import { DeleteIcon, EditIcon } from "@fluentui/react-icons-mdl2";
import CreateCouponModal from "../modals/CreateCoupon";
import { ConfirmModal } from "../../../modals/ConfirmModal";
import ActionBar from "../../../components/ActionBar/ActionBar";
import LoadingPlaceholder from "../../../components/LoadingPlaceholder";
import EnabledLabel from "../../../components/EnabledLabel";

interface CouponsProps {
    environment: string;
    selectCoupon: (coupon: Coupon) => void;
}

export default function Coupons(props: CouponsProps) {

    const [coupons, setCoupons] = useState<Coupon[]>([]);
    const [couponElements, setCouponElements] = useState<JSX.Element[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [maxPages, setMaxPages] = useState<number>(0);
    const [couponsPerPage, setCouponsPerPage] = useState<number>(20);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [editingCouponId, setEditingCouponId] = useState<number | null>(null);
    const [deleteCouponId, setDeleteCouponId] = useState<number | null>(null);
    const { token, invalidWSToken } = useContext(AuthContext);
    const { showErrorModal } = useContext(ErrorContext);

    useEffect(() => {
        if (!invalidWSToken(props.environment)) {
            fetchCoupons();
        }
    }, [props.environment]);

    useEffect(() => {
        setMaxPages(Math.ceil(coupons.length / couponsPerPage));
        createCouponElements();
    }, [JSON.stringify(coupons), currentPage, couponsPerPage]);

    const createCouponElements = () => {
        let elements: JSX.Element[] = [];
        if (coupons.length > 0) {
            elements = coupons
                .sort((a, b) => a.id - b.id)
                .slice(currentPage * couponsPerPage, (currentPage + 1) * couponsPerPage)
                .map((currentCoupon, index) => {
                    return (
                        <tr key={index} style={{ cursor: 'pointer' }} onClick={(e) => props.selectCoupon(currentCoupon)}>
                            <td>{currentCoupon.name}</td>
                            <td>{getEdition(currentCoupon.product_id)}</td>
                            <td><EnabledLabel enabled={currentCoupon.enabled} /></td>
                            <td onClick={(e) => e.stopPropagation()}>
                                <Button variant='outline-dark' size='sm' style={{ marginRight: '3px' }} onClick={() => setEditingCouponId(currentCoupon.id)}>
                                    <EditIcon />
                                </Button>
                                <Button variant="danger" size="sm" onClick={(e) => setDeleteCouponId(currentCoupon.id)}>
                                    <DeleteIcon />
                                </Button>
                            </td>
                        </tr>
                    )
                }
                );
        }
        setCouponElements(elements);
    }

    const fetchCoupons = async () => {
        try {
            setIsLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                }
            }
            await fetch(props.environment + 'getCoupons', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Failed to fetch coupons from " + getWSEnvironment(props.environment));
                    }
                    return res.json();
                })
                .then(({ coupons }) => {
                    setCoupons([...coupons]);
                });
        } catch (error) {
            setCoupons([]);
            showErrorModal("Can't access the " + getWSEnvironment(props.environment) + ' database.\nTry to log in again.');
        }
        setIsLoading(false);
    }

    const deleteCoupon = async (id: number) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    'id': id
                })
            }
            await fetch(props.environment + 'deleteCoupon', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Failed to delete coupon from server");
                    }
                    return res.json();
                })
                .then(() => {
                    fetchCoupons();
                });
        } catch (e: any) {
            showErrorModal(e.message ?? "Failed to delete coupon - Server is unavailable at the moment");
        }
    }

    return (
        <>
            <EditCouponModal
                couponId={editingCouponId}
                editedCoupon={() => fetchCoupons()}
                environment={props.environment}
                close={() => setEditingCouponId(null)}
            />
            {deleteCouponId &&
                <ConfirmModal
                    confirm={() => {
                        deleteCoupon(deleteCouponId);
                        setDeleteCouponId(null);
                    }}
                    message={['Are you sure you want to delete this coupon?', 'This action can not be undone']}
                    show={deleteCouponId !== null}
                    cancel={() => setDeleteCouponId(null)}
                />
            }
            <ActionBar
                isLoading={isLoading}
                reloadCallback={() => fetchCoupons()}
                title="Coupons"
            >
                <CreateCouponModal
                    environment={props.environment}
                    createdCoupon={() => fetchCoupons()}
                />
            </ActionBar>
            {invalidWSToken(props.environment) &&
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <h5 style={{ color: 'red' }}>Could not connect to the database, try to log in again...<br />T</h5>
                </div>
            }
            {!invalidWSToken(props.environment) &&
                <>
                    <Table striped bordered hover size="md">
                        <thead>
                            <tr>
                                <th>Name:</th>
                                <th>Product:</th>
                                <th>Enabled:</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isLoading && couponElements}
                        </tbody>
                    </Table>
                    {isLoading && <LoadingPlaceholder />}
                    <PaginationPartial
                        currentPage={currentPage}
                        maxPages={maxPages}
                        viewAmount={couponsPerPage}
                        changePage={(newPage: number) => setCurrentPage(newPage)}
                        changeViewAmount={(newAmount: number) => {
                            setCouponsPerPage(newAmount);
                            setCurrentPage(0);
                        }}
                        viewAmountPossible={[10, 20, 50, 100]}
                    />
                </>
            }
        </>
    );
}
