import { useContext, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { ErrorContext } from "../../../context/ErrorContext";
import { CouponCode } from "../../../collector";
import { Button, Form, Modal } from "react-bootstrap";
import Switch from "../../../components/Switch/Switch";
import EnabledLabel from "../../../components/EnabledLabel";


interface CreateCouponCodeProps {
    couponId: number;
    createdCode: () => void;
    environment: string;
}

export default function CreateCouponCodeModal(props: CreateCouponCodeProps) {

    const [show, setShow] = useState<boolean>(false);
    const { showErrorModal } = useContext(ErrorContext);
    const { token } = useContext(AuthContext);
    const [code, setCode] = useState<CouponCode>({
        id: 0,
        coupon_id: 0,
        code: '',
        fields: [],
        regex: false,
        enabled: false,
        created_at: new Date(Date.now()),
        deleted_at: null
    });

    const [isStatic, setIsStatic] = useState<boolean>(false);
    const [singleCode, setSingleCode] = useState<boolean>(true);
    const [uniqueCode, setUniqueCode] = useState<boolean | null>(null);
    const [zeroFields, setZeroFields] = useState<boolean>(false);
    const [validRegex, setValidRegex] = useState<boolean | null>(null);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setCode({
            id: 0,
            coupon_id: 0,
            code: '',
            fields: [],
            regex: false,
            enabled: false,
            created_at: new Date(Date.now()),
            deleted_at: null
        });
    };

    const checkField = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (code.fields.includes(e.target.value)) {
            setCode({ ...code, fields: code.fields.filter((x) => x !== e.target.value) })
        }
        else {
            setCode({ ...code, fields: [...code.fields, e.target.value] })
        }
    }

    const createCode = async () => {
        let codeList = code.code.split('\n');
        let regexAllowed = codeList.length === 1
        for (let i = 0; i < codeList.length; i++) {
            if (codeList[i] === '') continue;
            try {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token(),
                    },
                    body: JSON.stringify({
                        'coupon_id': props.couponId,
                        'code': codeList[i],
                        'regex': regexAllowed ? code.regex : false,
                        'enabled': code.enabled,
                        'fields': code.fields
                    })
                }
                await fetch(props.environment + 'createCode', requestOptions)
                    .then((res) => {
                        if (!res.ok) {
                            throw new Error("Failed to create code");
                        }
                        return res.json();
                    })
                    .then(() => {
                        props.createdCode();
                        handleClose();
                    });
            } catch (e: any) {
                showErrorModal(e.message ?? "Failed to create code - Server is unavailable at the moment");
            }
        }
    }

    const checkCodeIsUnique = async (): Promise<boolean> => {
        let codeList = code.code.split('\n');
        for (let i = 0; i < codeList.length; i++) {
            if (codeList[i] === '') return false;
            if (code.regex) {
                if (!checkIfRegexIsValid()) {
                    setValidRegex(false);
                    return false;
                }
            }
            try {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token(),
                    },
                    body: JSON.stringify({
                        'coupon_id': props.couponId,
                        'code': codeList[i].toLowerCase(),
                        'id': -1
                    })
                }
                await fetch(props.environment + 'checkUniqueCode', requestOptions)
                    .then((res) => {
                        if (!res.ok) {
                            throw new Error("Failed to check code uniqueness");
                        }
                        return res.json();
                    })
                    .then(({ exist }) => {
                        if (exist) return false;
                    });
            } catch (e: any) {
                showErrorModal(e.message ?? "Failed to check code uniqueness - Server is unavailable at the moment");
                return false;
            }
        }
        return true;
    }

    const checkIfRegexIsValid = (): boolean => {
        try {
            let parts = code.code.slice(6).split('/');
            let regex = code.code.slice(6);
            let options = "";
            if (parts.length > 1) {
                regex = parts[1];
                options = parts[2];
            }
            new RegExp(regex, options);
            return true;
        }
        catch (e) {
            return false;
        }
    }

    return (
        <>
            <Button variant='outline-dark' style={{ marginLeft: '10px' }} onClick={() => handleShow()}>
                Create Code
            </Button>
            {show &&
                <Modal
                    show={show}
                    backdrop="static"
                    onHide={() => handleClose()}
                    keyboard={false}
                >
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        if (code.fields.length >= 1) {
                            checkCodeIsUnique().then(isUnique => {
                                setUniqueCode(isUnique)
                                if (isUnique) createCode();
                            });
                        }
                        else {
                            setZeroFields(true);
                        }
                    }}>
                        <Modal.Header closeButton>
                            <Modal.Title>Create Code:</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="dialog-data-field">
                                <span>Fields:</span>
                                <div style={{ display: 'flex', columnGap: '3px', justifyContent: 'flex-end' }}>
                                    <Button style={{ opacity: (isStatic ? '1' : '0.5') }} onClick={() => {
                                        if (!isStatic) {
                                            setCode({ ...code, fields: ['static:staticCode'] });
                                            setIsStatic(true);
                                        }
                                    }}>
                                        Static
                                    </Button>
                                    <Button style={{ opacity: (!isStatic ? '1' : '0.5') }} onClick={() => {
                                        if (isStatic) {
                                            setCode({ ...code, fields: [] });
                                            setIsStatic(false);
                                        }
                                    }}>
                                        Multiple
                                    </Button>
                                </div>
                            </div>
                            {!isStatic &&
                                <div style={{ marginBottom: '10px' }}>
                                    <div className="d-flex" style={{ justifyContent: "space-evenly", marginBottom: '10px' }}>
                                        <div>
                                            <Form.Label>License Details:</Form.Label>
                                            <Form.Check type={'checkbox'} onChange={checkField} checked={code.fields.includes('license-details:firstname')} value={'license-details:firstname'} label={'Firstname'} />
                                            <Form.Check type={'checkbox'} onChange={checkField} checked={code.fields.includes('license-details:lastname')} value={'license-details:lastname'} label={'Lastname'} />
                                            <Form.Check type={'checkbox'} onChange={checkField} checked={code.fields.includes('license-details:email')} value={'license-details:email'} label={'Email'} />
                                            <Form.Check type={'checkbox'} onChange={checkField} checked={code.fields.includes('license-details:affiliation')} value={'license-details:affiliation'} label={'Affiliation'} />
                                        </div>
                                        <div>
                                            <Form.Label>Customer Details:</Form.Label>
                                            <Form.Check type={'checkbox'} onChange={checkField} checked={code.fields.includes('customer-details:firstname')} value={'customer-details:firstname'} label={'Firstname'} />
                                            <Form.Check type={'checkbox'} onChange={checkField} checked={code.fields.includes('customer-details:lastname')} value={'customer-details:lastname'} label={'Lastname'} />
                                            <Form.Check type={'checkbox'} onChange={checkField} checked={code.fields.includes('customer-details:email')} value={'customer-details:email'} label={'Email'} />
                                            <Form.Check type={'checkbox'} onChange={checkField} checked={code.fields.includes('customer-details:address')} value={'customer-details:address'} label={'Address'} />
                                            <Form.Check type={'checkbox'} onChange={checkField} checked={code.fields.includes('customer-details:postal')} value={'customer-details:postal'} label={'Postal'} />
                                            <Form.Check type={'checkbox'} onChange={checkField} checked={code.fields.includes('customer-details:city')} value={'customer-details:city'} label={'City'} />
                                        </div>
                                    </div>
                                    {zeroFields &&
                                        <span className="dialog-data-field" style={{ color: 'red' }}>
                                            Please choose at least one field affected by the code!
                                        </span>
                                    }
                                </div>
                            }
                            <div className="dialog-data-field" style={{ alignItems: 'center' }}>
                                <span style={{ alignSelf: 'flex-start' }}>Code:</span>
                                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}>
                                    <div style={{ display: 'flex', columnGap: '3px', justifyContent: 'flex-end' }}>
                                        <Button style={{ opacity: (singleCode ? '1' : '0.5') }} onClick={() => {
                                            if (singleCode) return;
                                            setCode({ ...code, code: '' });
                                            setSingleCode(true);
                                        }}
                                        >
                                            Single
                                        </Button>
                                        <Button style={{ opacity: (!singleCode ? '1' : '0.5') }} onClick={() => {
                                            if (!singleCode) return;
                                            setCode({ ...code, code: '' });
                                            setSingleCode(false);
                                        }}
                                        >
                                            Multiple
                                        </Button>
                                    </div>
                                    {singleCode ?
                                        <input placeholder="Write code here..." value={code.code} required
                                            onChange={(item) => {
                                                setCode({
                                                    ...code,
                                                    code: item.target.value.toLowerCase(),
                                                    regex: item.target.value.toLowerCase().startsWith('regex:')
                                                });
                                            }}
                                        /> :
                                        <textarea placeholder="One code per line" value={code.code} required
                                            style={{ minWidth: '300px', height: '200px' }}
                                            onChange={(item) => setCode({ ...code, code: item.target.value.toLowerCase() })}
                                        />
                                    }
                                </div>
                            </div>
                            {singleCode && <span><em>To use regex, start with 'regex:' no spaces. (Ex: regex:/.*@student.aau.dk$/)</em></span>}
                            {validRegex === false && <span style={{ color: "red" }}><br />Regex is invalid</span>}
                            {uniqueCode === false && <span style={{ color: "red" }}><br />Code must be unique for this coupon!</span>}
                            <div className="dialog-data-field">
                                <span>Enabled:</span>
                                <Switch checked={code.enabled} onChange={(item) => setCode({ ...code, enabled: item.target.checked })} />
                            </div>
                            <div className="text-end-container">
                                <EnabledLabel enabled={code.enabled} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => handleClose()}>
                                Close
                            </Button>
                            <Button variant="primary" type='submit'>Create Code</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            }
        </>
    );
}





