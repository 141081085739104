import { useContext, useEffect, useState } from 'react';
import { Container, Table, Row, Col } from 'react-bootstrap'
import { Order, isLocal, LicenseType, Env, getWebshopFetchPath } from '../../collector';
import { AuthContext } from '../../context/AuthContext';
import { ErrorContext } from '../../context/ErrorContext';
import PaginationPartial from '../../components/pagination/pagination';
import EnvironmentTabs from '../../components/EnvironmentTabs';
import DateRange from '../../components/DateRange';
import ActionBar from '../../components/ActionBar/ActionBar';
import LoadingPlaceholder from '../../components/LoadingPlaceholder';
import React from 'react';


export default function OrdersPage() {

    const { token } = useContext(AuthContext);
    const { showErrorModal } = useContext(ErrorContext);
    const [selectedEnvTab, setSelectedEnvTab] = useState<Env>(isLocal() ? Env.LOCAL : Env.TEST);
    const [expandedRow, setExpandedRow] = useState<number | null>(null);
    const [orders, setOrders] = useState<Order[]>([]);
    const [orderElements, setOrderElements] = useState<JSX.Element[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [maxPages, setMaxPages] = useState<number>(0);
    const [ordersPerPage, setOrdersPerPage] = useState<number>(20);
    const [totalOrders, setTotalOrders] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dateInterval, setDateInterval] = useState<{ startDate: Date | null, endDate: Date | null }>({ startDate: null, endDate: null });


    const handleRowToggle = (rowId: number) => setExpandedRow(expandedRow === rowId ? null : rowId);

    useEffect(() => {
        fetchOrders();
    }, [selectedEnvTab, currentPage, JSON.stringify(dateInterval)]);

    useEffect(() => {
        setMaxPages(Math.ceil(totalOrders / ordersPerPage));
    }, [totalOrders, ordersPerPage]);

    useEffect(() => {
        createOrderElements();
    }, [JSON.stringify(orders), expandedRow]);

    const fetchOrders = async () => {
        setIsLoading(true);
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    currentPage: currentPage + 1,
                    perPage: ordersPerPage,
                    startDate: dateInterval.startDate ? dateInterval.startDate.getTime() : null,
                    endDate: dateInterval.endDate ? dateInterval.endDate.getTime() : null
                })
            }
            await fetch(getWebshopFetchPath(selectedEnvTab) + 'getOrders', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        showErrorModal("Failed to fetch orders from " + selectedEnvTab);
                        return;
                    }
                    return res.json();
                })
                .then(({ orders }: { orders: { data: Order[], total: number } }) => {
                    if (orders.data.length === 0) {
                        setOrders([]);
                    }
                    else {
                        setOrders([...orders.data]);
                    }
                    setTotalOrders(orders.total);
                    setIsLoading(false);
                })
        } catch (e: any) {
            showErrorModal("Failed to fetch orders from " + selectedEnvTab + " - Server is unavailable at the moment");
            setOrders([]);
            setTotalOrders(0);
            setIsLoading(false);
        }
    }
    const getProductName = (productId: number | undefined) => {
        switch (productId) {
            case LicenseType.Pro:
                return "DOTE Pro";
            case LicenseType.ProCommunity:
                return "DOTE Pro Community";
            case LicenseType.ProCommunityUpgrade:
                return "DOTE Pro Community Upgrade";
            case LicenseType.AAU:
                return "DOTE AAU";
            case LicenseType.SiteLicense:
                return "DOTE Site-License";
            default:
                return "undefined";
        }
    }
    const handleRateType = (rateType: string | null) => {
        if (rateType === 'fixed') {
            return 'DKK'
        } else return rateType;
    }

    const createOrderElements = () => {
        if (orders.length > 0) {
            let elements = orders.map((order) => {
                const totalCost = () => {
                    if (order.savingPerItem && order.quantity && order.originalUnitPrice) {
                        return (parseFloat(order.originalUnitPrice.replace(",", ".")) * order.quantity) - (order.savingPerItem * order.quantity);
                    }
                    else if (order.quantity && order.originalUnitPrice) {
                        return parseFloat(order.originalUnitPrice.replace(",", ".")) * order.quantity;
                    }
                    return 0;
                }
                const discount = () => {
                    if (order.rateType && order.rateValue) {
                        return order.rateValue + ' ' + handleRateType(order.rateType);
                    }
                    return 'None';
                }
                return (
                    <React.Fragment key={order.id}>
                        <tr className='pointer' onClick={() => handleRowToggle(order.id)}>
                            <td>{order.id}</td>
                            <td>{order.createdAt?.toLocaleString()}</td>
                            <td>{order.orderPlacedAt?.toLocaleString()}</td>
                            <td>{order.status}</td>
                            <td>{order.quantity}x {getProductName(order.product)}</td>
                            <td>{discount()}</td>
                            <td>{totalCost() * 1.25} DKK</td>
                            <td></td>
                        </tr>
                        {expandedRow === order.id && (
                            <tr>
                                <td colSpan={9}>
                                    <Row>
                                        <Col xs={12} sm={6} md={4}>
                                            <div>
                                                <strong>Customer:</strong><br></br>
                                                Firstname: {order.customer.firstname}<br></br>
                                                Lastname: {order.customer.lastname}<br></br>
                                                Email: {order.customer.email}<br></br>
                                                Address: {order.customer.address}<br></br>
                                                {order.customer.address2 && (<>Address 2: {order.customer.address2}<br></br></>)}
                                                Postal: {order.customer.postal}<br></br>
                                                City: {order.customer.city}<br></br>
                                                Country: {order.customer.country}<br></br>
                                                {order.customer.company && (<>Company: {order.customer.company}<br></br></>)}
                                                {order.customer.department && (<>Department: {order.customer.department}<br></br></>)}
                                                {order.customer.vat_number && (<>VAT number: {order.customer.vat_number}<br></br></>)}
                                                <br></br>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={6} md={4}>
                                            <div>
                                                <strong>Pricing Details:</strong><br></br>
                                                {order.quantity && order.product && (<>Items Bought: {order.quantity}x {getProductName(order.product)}<br></br></>)}
                                                {order.originalUnitPrice && order.quantity &&
                                                    (<>
                                                        Price per Item: {order.originalUnitPrice} DKK<br></br>
                                                        Price per Item (with Tax): {parseFloat(order.originalUnitPrice.replace(",", ".")) * 1.25} DKK<br></br>
                                                        Total Price: {parseFloat(order.originalUnitPrice.replace(",", ".")) * order.quantity} DKK<br></br>
                                                        Total Price (with Tax): {parseFloat(order.originalUnitPrice.replace(",", ".")) * order.quantity * 1.25} DKK<br></br>

                                                    </>)
                                                }
                                                {order.originalUnitPrice && order.quantity && order.codeUsed && order.couponUsed && order.savingPerItem &&
                                                    (<>
                                                        Price per Item after Discount: {parseFloat(order.originalUnitPrice.replace(",", ".")) - order.savingPerItem} DKK<br></br>
                                                        Price per Item after Discount (with Tax): {(parseFloat(order.originalUnitPrice.replace(",", ".")) - order.savingPerItem) * 1.25} DKK<br></br>
                                                        Total Price after Discount: {totalCost()} DKK<br></br>
                                                        Total Price after Discount (with Tax): {(totalCost() * 1.25)} DKK<br></br>
                                                    </>)
                                                }
                                                <br></br>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={4}>
                                            <div>
                                                <strong>Coupon Details:</strong><br></br>
                                                {order.couponUsed && (<>Coupon Used: {order.couponUsed}<br></br></>)}
                                                {order.codeUsed && (<>Code Used: {order.codeUsed}<br></br></>)}
                                                {order.rateValue && order.rateType && (<>Discount per Item: {discount()}<br></br></>)}
                                                <br></br>
                                            </div>
                                            <div>
                                                <strong>Payment Details:</strong><br></br>
                                                {order.paymentType && (<>Payment Type: {order.paymentType}<br></br></>)}
                                                {order.createdAt && (<>Order Created At: {order.createdAt.toLocaleString()}<br></br></>)}
                                                {order.orderPlacedAt && (<>Order Placed At: {order.orderPlacedAt.toLocaleString()}<br></br></>)}
                                                <br></br>
                                            </div>
                                        </Col>
                                    </Row>
                                </td>
                            </tr>
                        )}
                    </React.Fragment>
                )
            });
            setOrderElements(elements);
        }
        else {
            setOrderElements([]);
        }
    }

    return (
        <Container>
            <EnvironmentTabs
                selectedTab={selectedEnvTab}
                changedTab={(newTab: Env) => setSelectedEnvTab(newTab)}
            />
            <ActionBar
                title='Orders'
                isLoading={isLoading}
                reloadCallback={() => fetchOrders()}
            >
                <DateRange
                    dateRangeHasBeenSet={([startDate, endDate]) => setDateInterval({ startDate, endDate })}
                    label='Date Range:'
                />
            </ActionBar>
            <Table bordered>
                <thead>
                    <tr>
                        <th>ID:</th>
                        <th>Order Created:</th>
                        <th>Order Placed:</th>
                        <th>Status:</th>
                        <th>Product(s):</th>
                        <th>Discount per Item:</th>
                        <th title='Total cost including discount and tax'>Payed Amount:</th>
                        <th>Total: {totalOrders}</th>
                    </tr>
                </thead>
                <tbody>
                    {!isLoading && orderElements}
                </tbody>
            </Table>
            {isLoading && <LoadingPlaceholder />}
            <PaginationPartial
                currentPage={currentPage}
                maxPages={maxPages}
                viewAmount={ordersPerPage}
                changePage={(newPage: number) => setCurrentPage(newPage)}
                changeViewAmount={(newAmount: number) => {
                    setOrdersPerPage(newAmount);
                    setMaxPages(Math.ceil(totalOrders / newAmount));
                    setCurrentPage(0);
                }}
                viewAmountPossible={[10, 20, 50, 100]}
            />
        </Container>
    )
}
