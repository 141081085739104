import { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { AuthContext } from "../../../context/AuthContext";
import { ErrorContext } from "../../../context/ErrorContext";
import { BulkCoupon, LicenseType } from "../../../collector";
import Switch from "../../../components/Switch/Switch";
import EnabledLabel from "../../../components/EnabledLabel";

interface EditBulkRangeProps {
    environment: string;
    bulkRangeId: number | null;
    editedBulkRange: () => void;
    close: () => void;
}

export default function EditBulkRangeModal(props: EditBulkRangeProps) {

    const [bulkRange, setBulkRange] = useState<BulkCoupon | null>(null);
    const [show, setShow] = useState<boolean>(false);
    const { showErrorModal } = useContext(ErrorContext);
    const { token, invalidWSToken } = useContext(AuthContext);

    useEffect(() => {
        if (props.bulkRangeId) {
            fetchBulkRange();
            handleShow();
        }
    }, [props.bulkRangeId]);

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        props.close();
    };

    const fetchBulkRange = async () => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    'id': props.bulkRangeId
                })
            }
            await fetch(props.environment + 'getBulkRange', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Failed to fetch bulk range");
                    }
                    return res.json();
                })
                .then(({ bulkRange }) => {
                    setBulkRange(bulkRange);
                });
        } catch (error: any) {
            showErrorModal(error ?? "Failed to fetch bulk range - Server is unavailable at the moment");
        }
    }

    const editBulkRange = async () => {
        try {
            if (!bulkRange) throw Error("Bulk range was null...");
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    'id': bulkRange.id,
                    'delimiter': bulkRange.delimiter,
                    'value': bulkRange.discount,
                    'product_id': bulkRange.productId,
                    'enabled': bulkRange.enabled
                })
            }
            await fetch(props.environment + 'editBulkRange', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("Failed to edit bulk range");
                    }
                    return res.json();
                })
                .then(() => {
                    props.editedBulkRange();
                    handleClose();
                });
        } catch (error: any) {
            showErrorModal(error ?? "Failed to edit bulk range - Server is unavailable at the moment");
        }
    }

    return (
        <>
            {show && bulkRange && !invalidWSToken(props.environment) &&
                <Modal
                    show={show}
                    backdrop="static"
                    onHide={() => handleClose()}
                    keyboard={false}
                >
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        editBulkRange();
                    }}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Bulk Range</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="dialog-data-field">
                                <span>Product:</span>
                                <select value={bulkRange.productId} required
                                    onChange={(item) => setBulkRange({ ...bulkRange, productId: Number(item.target.value) })}
                                >
                                    <option value={LicenseType.Pro}>DOTE Pro</option>
                                    <option value={LicenseType.ProCommunity}>DOTE Pro Community</option>
                                    <option value={LicenseType.SiteLicense}>Site-License</option>
                                </select>
                            </div>
                            <div className="dialog-data-field">
                                <span>Delimiter:</span>
                                <input type="number" min={2} value={bulkRange.delimiter} required
                                    onChange={(item) => setBulkRange({ ...bulkRange, delimiter: Number(item.target.value) })} />
                            </div>
                            <div className="dialog-data-field">
                                <span>Discount Value (%):</span>
                                <input min={0} max={99} type='number' value={bulkRange.discount} required
                                    onChange={(item) => setBulkRange({ ...bulkRange, discount: Number(item.target.value) })}
                                />
                            </div>
                            <div className="dialog-data-field">
                                <span>Enabled:</span>
                                <Switch checked={bulkRange.enabled} onChange={(item) => setBulkRange({ ...bulkRange, enabled: item.target.checked })} />
                            </div>
                            <div className="text-end-container">
                                <EnabledLabel enabled={bulkRange.enabled} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => handleClose()}>
                                Close
                            </Button>
                            <Button variant="primary" type='submit'>Save</Button>
                        </Modal.Footer>
                    </form>
                </Modal>}
        </>
    );
}
