import { Button, Col, Collapse, Container, Row, Spinner } from 'react-bootstrap';
import { RefreshIcon, ChevronDownIcon, ChevronUpIcon } from '@fluentui/react-icons-mdl2';
import { useCallback, useState } from 'react';

interface ActionBarProps {
    reloadCallback: () => void;
    isLoading?: boolean;
    title?: string;
    children?: JSX.Element | JSX.Element[];
    collapsable?: boolean;
    onCollapse?: (collapsed: boolean) => void;
}

export default function ActionBar(props: ActionBarProps) {

    const [collapsed, setCollapsed] = useState(false);

    const handleCollapseToggle = useCallback(() => {
        if (!props.collapsable) return;
        setCollapsed(!collapsed);
        if (props.onCollapse) {
            props.onCollapse(!collapsed);
        }
    }, [collapsed, props.onCollapse]);

    return (
        <Container style={{ marginTop: '30px', marginBottom: '20px' }}>
            {props.title && (
                <Row className="justify-content-center align-items-center" style={{ cursor: (props.collapsable ? 'pointer' : 'default') }} onClick={handleCollapseToggle}>
                    <Col xs="auto" className="text-center">
                        <h2>{props.title}</h2>
                    </Col>
                    {props.collapsable && (
                        <Col xs="auto" className="text-end">
                            {collapsed ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        </Col>
                    )}
                </Row>
            )}
            <Collapse in={!collapsed}>
                <Row xs='auto' sm='auto' md='auto' lg='auto' xl='auto' xxl='auto' className='flex-nowrap'>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11} className='d-flex flex-wrap gap-3 flex-grow-1 p-0 align-items-center'>
                        {props.children}
                    </Col>
                    <Col xs={1} className='p-0'>
                        {props.isLoading !== undefined &&
                            <Button variant='outline-dark' disabled={props.isLoading} onClick={props.reloadCallback}>
                                {props.isLoading ? <Spinner animation='border' size='sm' /> : <RefreshIcon />}
                            </Button>
                        }
                    </Col>
                </Row>
            </Collapse>
        </Container>
    );
}