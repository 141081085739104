import { useCallback, useContext, useEffect, useState } from "react";
import { Button, Collapse, Table } from "react-bootstrap";
import ActionBar from "../../components/ActionBar/ActionBar";
import DateRange from "../../components/DateRange";
import LoadingPlaceholder from "../../components/LoadingPlaceholder";
import PaginationPartial from "../../components/pagination/pagination";
import { Env, SiteLicenseOrder, getWebshopFetchPath } from "../../collector";
import React from "react";
import { AuthContext } from "../../context/AuthContext";
import { ErrorContext } from "../../context/ErrorContext";
import { DeleteIcon, MailRepeatIcon, SwitchIcon } from "@fluentui/react-icons-mdl2";

interface Props {
    selectedEnvTab: Env;
    isPendingList: boolean;
    fetchOrders: (currentPage: number, perPage: number, notPaid: boolean, dateInterval: any) => Promise<{ data: SiteLicenseOrder[], total: number }>;
}

export default function SiteLicenseOrdersTable(props: Props) {

    const { token } = useContext(AuthContext);
    const { showErrorModal } = useContext(ErrorContext);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dateInterval, setDateInterval] = useState<{ startDate: Date | null, endDate: Date | null }>({ startDate: null, endDate: null });
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [maxPages, setMaxPages] = useState<number>(0);
    const [ordersPerPage, setOrdersPerPage] = useState<number>(20);
    const [totalOrders, setTotalOrders] = useState<number>(0);
    const [orders, setOrders] = useState<SiteLicenseOrder[]>([]);
    const [orderElements, setOrderElements] = useState<JSX.Element[]>([]);
    const [collapsed, setCollapsed] = useState<boolean>(false);

    useEffect(() => {
        setMaxPages(Math.ceil(totalOrders / ordersPerPage));
    }, [totalOrders, ordersPerPage]);

    const fetchOrders = useCallback(() => {
        setIsLoading(() => true);
        props.fetchOrders(currentPage, ordersPerPage, props.isPendingList, dateInterval).then(({ data, total }) => {
            setOrders(() => [...data]);
            setTotalOrders(() => total);
            setIsLoading(() => false);
        }).catch(() => {
            setIsLoading(() => false);
        });
    }, [currentPage, ordersPerPage, dateInterval, props.fetchOrders]);

    useEffect(() => {
        fetchOrders();
    }, [props.selectedEnvTab, currentPage, JSON.stringify(dateInterval)]);

    useEffect(() => {
        createOrderElements();
    }, [JSON.stringify(orders)/*, expandedRow*/]);

    const moveOrderStatus = useCallback(async (licenseOrderId: number) => {
        try {
            setIsLoading(() => true);
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    toPaid: props.isPendingList,
                    id: licenseOrderId
                })
            }
            await fetch(getWebshopFetchPath(props.selectedEnvTab) + 'moveSiteLicensePaidStatus', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        showErrorModal("Failed to move site license order from " + props.selectedEnvTab);
                        return;
                    }
                    return res.json();
                })
                .then(() => {
                    setIsLoading(() => false);
                    fetchOrders();
                })
        } catch (e: any) {
            showErrorModal("Failed to move site license order from " + props.selectedEnvTab + " - Server is unavailable at the moment");
            setIsLoading(() => false);
        }
    }, [props.selectedEnvTab, props.isPendingList, token, showErrorModal]);

    const resendInvoiceInfo = useCallback(async (licenseOrderId: number) => {
        try {
            setIsLoading(() => true);
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    id: licenseOrderId,
                })
            }
            await fetch(getWebshopFetchPath(props.selectedEnvTab) + 'sendInvoiceInfo', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        showErrorModal("Failed to send invoice info from " + props.selectedEnvTab);
                        return;
                    }
                    return res.json();
                })
                .then(() => {
                    setIsLoading(() => false);
                })
        } catch (e: any) {
            showErrorModal("Failed to send invoice info from " + props.selectedEnvTab + " - Server is unavailable at the moment");
            setIsLoading(() => false);
        }
    }, [props.selectedEnvTab, token, showErrorModal]);

    const sendSiteLicenseRedeemCodes = useCallback(async (licenseOrderId: number) => {
        try {
            setIsLoading(() => true);
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    id: licenseOrderId,
                })
            }
            await fetch(getWebshopFetchPath(props.selectedEnvTab) + 'sendSiteLicenses', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        showErrorModal("Failed to send site license redeem codes from " + props.selectedEnvTab);
                        return;
                    }
                    return res.json();
                })
                .then(() => {
                    setIsLoading(() => false);
                })
        } catch (e: any) {
            showErrorModal("Failed to send site license redeem codes from " + props.selectedEnvTab + " - Server is unavailable at the moment");
            setIsLoading(() => false);
        }
    }, [props.selectedEnvTab, token, showErrorModal]);

    const deleteSiteLicenseInvoiceRequest = useCallback(async (licenseOrderId: number) => {
        try {
            setIsLoading(() => true);
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token(),
                },
                body: JSON.stringify({
                    id: licenseOrderId,
                })
            }
            await fetch(getWebshopFetchPath(props.selectedEnvTab) + 'deleteSiteLicenseInvoiceRequest', requestOptions)
                .then((res) => {
                    if (!res.ok) {
                        showErrorModal("Failed to delete site license invoice request " + props.selectedEnvTab);
                        return;
                    }
                    return res.json();
                })
                .then(() => {
                    setIsLoading(() => false);
                    fetchOrders();
                })
        } catch (e: any) {
            showErrorModal("Failed to delete site license invoice request " + props.selectedEnvTab + " - Server is unavailable at the moment");
            setIsLoading(() => false);
        }
    }, [props.selectedEnvTab, token, showErrorModal])

    const createOrderElements = () => {
        if (orders.length > 0) {
            let elements = orders.map((order) => {
                // const totalCost = () => {
                //     if (order.savingPerItem && order.buyAmount && order.originalUnitPrice) {
                //         return (parseFloat(order.originalUnitPrice.replace(",", ".")) * order.quantity) - (order.savingPerItem * order.quantity);
                //     }
                //     else if (order.buyAmount && order.originalUnitPrice) {
                //         return parseFloat(order.originalUnitPrice.replace(",", ".")) * order.quantity;
                //     }
                //     return 0;
                // }
                // const discount = () => {
                //     if (order.rateType && order.rateValue) {
                //         return order.rateValue + ' ' + handleRateType(order.rateType);
                //     }
                //     return 'None';
                // }
                return (
                    <React.Fragment key={order.licenseOrderId}>
                        <tr className='pointer' onClick={() => { }/*handleRowToggle(order.id)*/}>
                            <td>{order.licenseOrderId}</td>
                            <td>{order.createdAt?.toLocaleString()}</td>
                            <td>{order.status}</td>
                            <td>{order.buyAmount}</td>
                            <td>Price placeholder</td>
                            <td>
                                <div style={{ display: 'flex' }}>
                                    <Button variant='outline-dark' size='sm' style={{ marginRight: '3px' }} onClick={() => moveOrderStatus(order.licenseOrderId)}>
                                        <SwitchIcon /> Move to {props.isPendingList ? 'paid' : 'pending'}
                                    </Button>
                                    {props.isPendingList &&
                                        <>
                                            <Button variant='outline-dark' size='sm' style={{ marginRight: '3px' }} onClick={() => resendInvoiceInfo(order.licenseOrderId)}>
                                                <MailRepeatIcon /> Resend Invoice Info
                                            </Button>
                                            <Button variant='outline-danger' size='sm' style={{ marginRight: '3px' }} onClick={() => deleteSiteLicenseInvoiceRequest(order.licenseOrderId)}>
                                                <DeleteIcon />
                                            </Button>
                                        </>
                                    }
                                    {!props.isPendingList &&
                                        <Button variant='outline-dark' size='sm' style={{ marginRight: '3px' }} onClick={() => sendSiteLicenseRedeemCodes(order.licenseOrderId)}>
                                            <MailRepeatIcon /> Resend Site License Redeem Codes
                                        </Button>
                                    }
                                </div>
                            </td>
                        </tr>
                        {/* {expandedRow === order.id && (
                            <tr>
                                <td colSpan={9}>
                                    <Row>
                                        <Col xs={12} sm={6} md={4}>
                                            <div>
                                                <strong>Customer:</strong><br></br>
                                                Firstname: {order.customer.firstname}<br></br>
                                                Lastname: {order.customer.lastname}<br></br>
                                                Email: {order.customer.email}<br></br>
                                                Address: {order.customer.address}<br></br>
                                                {order.customer.address2 && (<>Address 2: {order.customer.address2}<br></br></>)}
                                                Postal: {order.customer.postal}<br></br>
                                                City: {order.customer.city}<br></br>
                                                Country: {order.customer.country}<br></br>
                                                {order.customer.company && (<>Company: {order.customer.company}<br></br></>)}
                                                {order.customer.department && (<>Department: {order.customer.department}<br></br></>)}
                                                {order.customer.vat_number && (<>VAT number: {order.customer.vat_number}<br></br></>)}
                                                <br></br>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={6} md={4}>
                                            <div>
                                                <strong>Pricing Details:</strong><br></br>
                                                {order.quantity && order.product && (<>Items Bought: {order.quantity}x {getProductName(order.product)}<br></br></>)}
                                                {order.originalUnitPrice && order.quantity &&
                                                    (<>
                                                        Price per Item: {order.originalUnitPrice} DKK<br></br>
                                                        Price per Item (with Tax): {parseFloat(order.originalUnitPrice.replace(",", ".")) * 1.25} DKK<br></br>
                                                        Total Price: {parseFloat(order.originalUnitPrice.replace(",", ".")) * order.quantity} DKK<br></br>
                                                        Total Price (with Tax): {parseFloat(order.originalUnitPrice.replace(",", ".")) * order.quantity * 1.25} DKK<br></br>

                                                    </>)
                                                }
                                                {order.originalUnitPrice && order.quantity && order.codeUsed && order.couponUsed && order.savingPerItem &&
                                                    (<>
                                                        Price per Item after Discount: {parseFloat(order.originalUnitPrice.replace(",", ".")) - order.savingPerItem} DKK<br></br>
                                                        Price per Item after Discount (with Tax): {(parseFloat(order.originalUnitPrice.replace(",", ".")) - order.savingPerItem) * 1.25} DKK<br></br>
                                                        Total Price after Discount: {totalCost()} DKK<br></br>
                                                        Total Price after Discount (with Tax): {(totalCost() * 1.25)} DKK<br></br>
                                                    </>)
                                                }
                                                <br></br>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={4}>
                                            <div>
                                                <strong>Coupon Details:</strong><br></br>
                                                {order.couponUsed && (<>Coupon Used: {order.couponUsed}<br></br></>)}
                                                {order.codeUsed && (<>Code Used: {order.codeUsed}<br></br></>)}
                                                {order.rateValue && order.rateType && (<>Discount per Item: {discount()}<br></br></>)}
                                                <br></br>
                                            </div>
                                            <div>
                                                <strong>Payment Details:</strong><br></br>
                                                {order.paymentType && (<>Payment Type: {order.paymentType}<br></br></>)}
                                                {order.createdAt && (<>Order Created At: {order.createdAt.toLocaleString()}<br></br></>)}
                                                {order.orderPlacedAt && (<>Order Placed At: {order.orderPlacedAt.toLocaleString()}<br></br></>)}
                                                <br></br>
                                            </div>
                                        </Col>
                                    </Row>
                                </td>
                            </tr>
                        )} */}
                    </React.Fragment>
                )
            });
            setOrderElements(elements);
        }
        else {
            setOrderElements([]);
        }
    }

    return (
        <div className="flex-g-1">
            <ActionBar
                title={`${props.isPendingList ? 'Pending' : 'Paid'} Site License Orders:`}
                isLoading={isLoading}
                reloadCallback={fetchOrders}
                collapsable={true}
                onCollapse={(collapsed: boolean) => setCollapsed(collapsed)}
            >
                <DateRange
                    dateRangeHasBeenSet={([startDate, endDate]) => setDateInterval({ startDate, endDate })}
                    label='Date Range:'
                />
            </ActionBar>
            <Collapse in={!collapsed}>
                <div>
                    <Table bordered>
                        <thead>
                            <tr>
                                <th>ID:</th>
                                <th>Order Placed:</th>
                                <th>Status:</th>
                                <th># of Licenses {props.isPendingList ? 'requested' : 'bought'}:</th>
                                <th>Total Price:</th>
                                <th>Count: {totalOrders}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isLoading && orderElements}
                        </tbody>
                    </Table>
                    {isLoading && <LoadingPlaceholder />}
                    <PaginationPartial
                        currentPage={currentPage}
                        maxPages={maxPages}
                        viewAmount={ordersPerPage}
                        changePage={(newPage: number) => setCurrentPage(newPage)}
                        changeViewAmount={(newAmount: number) => {
                            setOrdersPerPage(newAmount);
                            setMaxPages(Math.ceil(totalOrders / newAmount));
                            setCurrentPage(0);
                        }}
                        viewAmountPossible={[10, 20, 50, 100]}
                    />
                </div>
            </Collapse>
        </div>
    )
}
